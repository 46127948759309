<template>
  <div class="row">
    <div class="col-lg-12 mb-1">
      <div class="float-right d-flex">
        <div>
          <WButton info
            customClass="ml-2"
            v-if="isVisibleAddUserWithDemoDataButton"
            @click="openaddUserWithDemoData"
          >
            {{ $t('users.addUserWithDemoData') }}
          </WButton>
        </div>
        <div class="ml-1">
          <WButton info
            v-if="!isDisable && !isCurrentUserMarketer"
            @click="openUserModal(true)"
          >
            {{ $t('users.addUser') }}
          </WButton>
        </div>
      </div>
    </div>
    <div class="col-lg-12">
      <div class="row mt-1">
        <div class="col-lg-3 col-md-12">
          <div class="input-group input-group-sm mb-1">
            <span class="input-group-addon">
              <i class="fa fa-search"></i>
            </span>
            <input
              id="search"
              name="search"
              :placeholder="$t('general.search')"
              class="form-control"
              ref="search"
              v-model.trim="searchValue"
              @input="refreshUsersList"
            />
            <div class="hack-input-off-autocomplete">
              <!--              этот элемент нужен чтобы в хроме не было автокомплита, почему то без этого заполняет автоматом предидушие поле именем юезар-->
              <input :placeholder="$t('general.search')" class="hack-input" />
            </div>

            <!--            <span v-show="searchValue !== ''" class="clear-input text-muted">-->
            <!--          <i class="fa fa-remove" @click="() => (this.searchValue = '')"></i>-->
          </div>
        </div>
<!--        <div class="col-lg-4 col-md-12 mb-1">-->
<!--          <div class="input-group input-group-sm filters__input">-->
<!--            <span class="input-group-addon filters__field-icon">-->
<!--              <i-->
<!--                class="fa fa-building-o"-->
<!--                :class="{ 'text-primary': filterBycompanyName.company !== 'All companys' }"-->
<!--              ></i>-->
<!--            </span>-->
<!--            <select-->
<!--              @change="refreshUsersList"-->
<!--              class="form-control form-control-sm filters__field"-->
<!--              ref="locationsSelect"-->
<!--              v-model="filterBycompanyName"-->
<!--            >-->
<!--              <option :selected="true" :value="'All companys'">{{ $t('general.allCompanys') }}</option>-->
<!--              <option v-for="company in companys" :value="company" :key="company.name">-->
<!--                {{ company.name }}-->
<!--              </option>-->
<!--            </select>-->
<!--          </div>-->
<!--        </div>-->
                <div class="col-lg-3 col-md-12 mb-1" v-if="areLocationsVisible">
          <div class="input-group input-group-sm filters__input">
            <span class="input-group-addon filters__field-icon">
              <i
                class="fa fa-building-o"
                :class="{ 'text-primary': filterBycompanyName.company !== 'All companys' }"
              ></i>
            </span>
            <select
              :disabled="isSelectedDefaultLocationForRequests"
              @change="changeCompany"
              class="form-control form-control-sm filters__field"
              ref="locationsSelect"
              v-model="filterBycompanyName"
            >
              <option :selected="true" :value="'All companys'">{{ $t('general.allCompanys') }}</option>
              <option v-for="company in companys" :value="company" :key="company.name">
                {{ company.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="col-lg-3 col-md-12 d-flex " v-if="areLocationsVisible">
          <div class="input-group input-group-sm input-group-for-location-Users--filters mr-0"
               v-if="filterBycompanyName === 'All companys'">


<!--            <span class="input-group-addon filters__field-icon">-->
<!--              <i class="fa fa-map-marker"></i>-->
<!--            </span>-->
<!--            <select-->
<!--              :title="-->
<!--filterBycompanyName !== 'All companys'-->
<!--                  ? $t('aps.filterByCompanyDisableSortingByLocationMsg')-->
<!--                  : ''-->
<!--              "-->
<!--              class="form-control form-control-sm filters__field"-->
<!--              v-model="filterByLocation.location"-->
<!--              @change="changeLocation"-->
<!--              :disabled=" filterBycompanyName !== 'All companys'"-->
<!--            >-->
<!--              <option :selected="true" :value="'All locations'">{{ $t('general.allLocations') }}-->
<!--              </option>-->
<!--              <option v-for="location in locationsList" :value="location.id" :key="location.id">-->
<!--                {{ location.name }}-->
<!--              </option>-->
<!--            </select>-->

              <span class="input-group-addon filters__field-icon">
                  <i class="fa fa-map-marker"></i>
                </span>
                  <SelectComponentV3ForUseInLocationSelectors
                    :show-search-field-into-items-list="!isSelectedDefaultLocationForRequests || (isSelectedDefaultLocationForRequests && $store.getters.getDefaultWithChildsForRequests())"
                    v-if="showLocationFilter"
                    no-wrap-selected-option
                    class="select-location-in-Users--filters"
                    enable-max-option-width
                    small-size
                    :no-clear-selected-option-when-click-on-it="isSelectedDefaultLocationForRequests"
                   :show-delete-button="!isSelectedDefaultLocationForRequests"
                    v-model="locationInFiltersData"
                    track-by="name"
                    option-id-name="id"
                    :no-options-found="$t('general.noResultForSearch')"
                    :async-function="requestLocations.bind(this)"
                    :placeholder="$t('portalStats.selectLocation')"
                    @select="selectLocationInFilters"
                  >
                    <template #dropdown-start>
                      <div class="ml-h mt-1">
                        <Switch-component
                          v-if="!isSelectedDefaultLocationForRequests || (isSelectedDefaultLocationForRequests && $store.getters.getDefaultWithChildsForRequests())"
                          @input="changeLocation"
                          :label="$t('general.withChild')"
                          v-model="filterByLocation.with_childs"
                          class=""
                        />
                      </div>
                    </template>
                  </SelectComponentV3ForUseInLocationSelectors>


          </div>
          <div class="input-group input-group-sm filters__location mr-0" v-else>
            <span class="input-group-addon filters__field-icon">
              <i class="fa fa-map-marker"></i>
            </span>
            <select
              :title="
               filterBycompanyName !== 'All companys'
                  ? $t('aps.filterByCompanyDisableSortingByLocationMsg')
                  : ''
              "
              class="form-control form-control-sm filters__field"
              v-model="filterByLocation.location"
              @change="changeLocation"
            >
              <option
                v-for="location in locationsObjbyIds(filterBycompanyName.locations)"
                :value="location.id"
                :key="location.id"
              >
                {{ location.name }}
              </option>
            </select>
          </div>
<!--          <div class="ml-h d-flex switcher-with-childs-in-stat-cpes-wrapper align-items-start">-->
<!--            <div-->
<!--              style="position: relative; top: 2px"-->
<!--              :title="-->
<!--                filterBycompanyName !== 'All companys'-->
<!--                  ? $t('aps.filterByCompanyDisableSortingByLocationMsg')-->
<!--                  : ''-->
<!--              "-->
<!--              class="d-flex align-items-end justify-content-start"-->
<!--            >-->
<!--              <Switch-component-->
<!--                :disabled="filterByLocation.location === 'All locations'"-->
<!--                v-model="filterByLocation.with_childs"-->
<!--                class="switcher-with-childs-in-stat-cpes"-->
<!--                @change="changeLocation"-->
<!--              />-->
<!--            </div>-->
<!--            <div-->
<!--              class="input-group input-group-sm filters__location switcher-with-childs-in-stat-cpes-caption">-->
<!--              <small class="with-child">{{ $t('general.withChild') }}</small>-->
<!--            </div>-->
<!--          </div>-->
        </div>
                <div class="col-lg-3 col-md-12 mb-1">
        <div class="input-group flex-column">
<!--          <label for="filter" class="mb-0 sorting-filter-caption-in-users">-->
<!--            <small>{{ $t('general.filtersSort') }}</small>-->
<!--          </label>-->
          <div class="input-group input-group-sm">
            <span class="input-group-addon">
              <i
                class="text-primary fa"
                :class="[sortOrder === 'asc' ? 'fa-sort-amount-asc' : 'fa-sort-amount-desc']"
                @click="toggleSortOrder"
              />
            </span>
            <select
              id="filter"
              class="form-control form-control-sm"
              ref="sort_by"
              v-model="sortBy"
            >
              <option v-for="option in sortByOptions" :key="option.value" :value="option.value">
                {{ option.label }}
              </option>
            </select>
          </div>
        </div>
      </div>

        <div class="col-lg-3 col-md-12 mb-1">
          <div class="input-group input-group-sm filters__input">
            <span class="input-group-addon filters__field-icon">
              <i class="fa fa-ban" :class="{ 'text-primary': filterBycompanyName.company !== 'All companys' }"></i>
            </span>
            <select
              @change="refreshUsersList"
              class="form-control form-control-sm filters__field"
              ref="locationsSelect"
              v-model="filterByUserStatus"
            >
              <option :selected="true" :value="'All statuses'">{{ $t('general.allStatuses') }}</option>
              <option :selected="true" :value="'Blocked'">{{ $t('users.blockedUsers') }}</option>
              <option :selected="true" :value="'NoBlocked'">{{ $t('users.noBlockedUsers') }}</option>
            </select>
          </div>
        </div>
                <div class="col-lg-3 col-md-12 mb-1">
          <div class="input-group input-group-sm filters__input">
            <span class="input-group-addon filters__field-icon">
              <i class="fa fa-user"></i>
            </span>
<!--            <select-->
<!--              @change="refreshUsersList"-->
<!--              class="form-control form-control-sm filters__field"-->
<!--              ref="locationsSelect"-->
<!--              v-model="filterByUserRole"-->
<!--            >-->
<!--              <option :selected="true" :value="'All roles'">{{ capitalizeFirstLetter($t(`general.allRoles`)) }}</option>-->
<!--              <option v-for="role of userRoles" :selected="true" :value="role">{{ capitalizeFirstLetter($t(`general.role.${role}`)) }}</option>-->
<!--            </select>-->
            <select
              @change="refreshUsersList"
              class="form-control form-control-sm filters__field"
              ref="locationsSelect"
              v-model="filterByUserRole"
            >
              <option :selected="true" :value="'All roles'">{{ capitalizeFirstLetter($t(`general.allRoles`)) }}</option>
              <option v-for="role of listOfRolesBasedOnBuildType" :selected="true" :value="role">{{ capitalizeFirstLetter($t(`general.role.${role}`)) }}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-12">
          <WButton secondary outline sm
            @click="resetAllFilters"
          >
            <i class="fa fa-close"></i>
            {{ $t('general.resetAllFilters') }}
          </WButton>
        </div>
      </div>
    </div>

    <div class="col-lg-12">
      <div class="users animated fadeIn" :class="{ 'edit-visible': editVisible }">
        <!--        <div class="loader loader-backdrop" v-if="isUsersLoading"></div>-->
        <div v-if="isUsersLoading"><Loader-spinner /></div>
        <div class="users-list">
          <div class="row">
            <div class="col-lg-12">
              <div class="card">
                <div class="card-header d-flex align-items-center">
                  <i class="fa fa-align-justify"></i>
                  {{ $t('users.usersList') }}
                  <TableDataDownloader
                    :data-for-excel="convertToNewExcelExportModuleFormat(formatToCSV(filteredUsersList), $t('downloadFilesNames.users'))"
                    v-if="usersList && !editVisible"
                    class="ml-auto"
                    :data="formatToCSV(filteredUsersList)"
                    :filename="getFileNameForDownloadFiles($t('downloadFilesNames.users'))"
                    :availableReports="{
                      csv: true,
                      xlsx: {
                        enabled: true,
                        autoColumnsWidth: true
                      },
                      pdf: {
                        enabled: true,
                        columnsWidth: this.isCurrentUserAdmin ? pdfTableColumnsWidthsTenColumns : pdfTableColumnsWidths
                      },
                    }"
                  />
                </div>
                <div class="card-block card-block__table">
                  <!-- <h5 class="mb-1"></h5> -->
                  <table
                    v-if="isCurrentUserMarketer === true"
                    class="table table-hover users-table fix-overflowed-table"
                  >
                    <thead>
                      <tr>
                        <th>{{ $t('users.username') }}</th>
                        <th>{{ $t('users.Role') }}</th>
                        <th v-if="areLocationsVisible">{{ $t('general.location') }}</th>
                        <th v-if="!editVisible">{{ $t('users.createdBy') }}</th>
                        <th v-if="!editVisible">{{ $t('users.creationDate') }}</th>
                        <th v-if="!editVisible">{{ $t('users.lastLogin') }}</th>
                        <th v-if="!editVisible">{{ $t('users.phone') }}</th>
                        <th v-if="!editVisible">{{ $t('users.company') }}</th>
                        <th v-if="!editVisible">{{ $t('users.description') }}</th>
                        <th v-if="!isDisable"></th>
                        <th v-if="!editVisible && !isDisable"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="user in usersList"
                        :key="user.id"
                        v-if="user.id === $store.state.userData.created_by"
                        :data-index="user.id"
                        :class="{ 'row-read-only': isDisable }"
                        class="admin-row creater-row"
                        @click="toggleEdit"
                      >
                        <td>
                          <div>
                            <span>{{ user.username }}</span>
                          </div>
                        </td>
                        <td>
                          <span>{{ $t(`general.role.${user.role}`) }}</span>
                        </td>
                        <td v-if="areLocationsVisible" class="responsive-row">
                          <span>{{ locationsItemsFromStore(user.base_location) }}</span>
                        </td>
                        <td>
                          <span v-if="!editVisible">{{ userNameById(user.created_by) }}</span>
                        </td>
                        <td v-if="!editVisible">
                          <small>{{ user.created_at | moment }}</small>
                        </td>
                        <td v-if="!editVisible">
                          <small v-if="user.last_login == '0'">{{ $t('users.noLogin') }}</small>
                          <small v-else>{{ user.last_login | moment }}</small>
                        </td>
                        <td v-if="!editVisible">
                          <small>{{ user.phone }}</small>
                        </td>
                        <td v-if="!editVisible">
                          <small>{{ user.company }}</small>
                        </td>
                        <td v-if="!editVisible">
                          <small>{{ user.description }}</small>
                        </td>
                        <td class="action-row" v-if="!editVisible"></td>
                      </tr>
                      <tr
                        v-for="user in usersList"
                        :key="user.id"
                        v-if="user.id == $store.state.userData.id"
                        :data-index="user.id"
                        @click="toggleEdit"
                        :class="{ 'row-read-only': isDisable }"
                        class="admin-row"
                      >
                        <td>
                          <div>
                            <span class="text-primary">{{ user.username }}</span>
                          </div>
                        </td>
                        <td>
                          <span class="text-primary">{{ $t(`general.role.${user.role}`) }}</span>
                        </td>
                        <td v-if="areLocationsVisible" class="responsive-row">
                          <span class="text-primary">{{ locationsItemsFromStore(user.base_location) }}</span>
                        </td>
                        <td>
                          <span class="text-primary" v-if="!editVisible">{{ userNameById(user.created_by) }}</span>
                        </td>
                        <td class="text-primary" v-if="!editVisible">
                          <small>{{ user.created_at | moment }}</small>
                        </td>
                        <td class="text-primary" v-if="!editVisible">
                          <small v-if="user.last_login == '0'">{{ $t('users.noLogin') }}</small>
                          <small v-else>{{ user.last_login | moment }}</small>
                        </td>
                        <td v-if="!editVisible">
                          <small>{{ user.phone }}</small>
                        </td>
                        <td v-if="!editVisible">
                          <small>{{ user.company }}</small>
                        </td>
                        <td v-if="!editVisible">
                          <small>{{ user.description }}</small>
                        </td>
                        <td class="action-row" v-if="!editVisible"></td>
                      </tr>

                      <tr
                        v-for="user in filteredUsersList"
                        :key="user.id"
                        v-if="user.id != $store.state.userData.id && user.id !== $store.state.userData.created_by"
                        :data-index="user.id"
                        @click="toggleEdit"
                        :class="{ 'row-read-only': isDisable, selected: isUserSelected(user) }"
                      >
                        <td>{{ user.username }}</td>
                        <td>{{ $t(`general.role.${user.role}`) }}</td>
                        <td v-if="areLocationsVisible" class="responsive-row">{{ locationsItemsFromStore(user.base_location) }}</td>
                        <td v-if="!editVisible">{{ userNameById(user.created_by) }}</td>
                        <td v-if="!editVisible">
                          <small>{{ user.created_at | moment }}</small>
                        </td>
                        <td v-if="!editVisible">
                          <small v-if="user.last_login == '0'">{{ $t('users.noLogin') }}</small>
                          <small v-else>{{ user.last_login | moment }}</small>
                        </td>
                        <td v-if="!editVisible">
                          <small>{{ user.phone }}</small>
                        </td>
                        <td class="users-word-break" v-if="!editVisible">
                          <small>{{ user.company }}</small>
                        </td>
                        <td class="users-word-break" v-if="!editVisible">
                          <small>{{ user.description }}</small>
                        </td>
                        <td class="action-row" v-if="!isDisable && !isCurrentUserMarketer">
                          <i
                            class="fa fa-pencil fa-lg mr-q text-info user-action"
                            data-action="edit"
                            :title="$t('users.editUserCaption')"
                          ></i>
                          <i
                            class="fa fa-remove fa-lg mr-q text-danger user-action"
                            data-action="delete"
                            :title="$t('users.deleteUserCaption')"
                          ></i>
                          <i
                            v-if="!user.block === true"
                            :class="{ 'user-action-disabled': isUserBlockOrUnblockRequestExecute }"
                            :title="$t('users.blockUserCaption')"
                            @click.stop="blockUser(user)"
                            class="fa fa-ban fa-lg mr-q text-warning user-action"
                            data-action="block"
                          ></i>
                          <i
                            v-else
                            :class="{ 'user-action-disabled': isUserBlockOrUnblockRequestExecute }"
                            :title="$t('users.unblockUserCaption')"
                            @click.stop="unblockUser(user)"
                            class="fa fa fa-key fa-lg mr-q text-success user-action"
                            data-action="unblock"
                          ></i>
                        </td>
                        <td
                          v-if="
                            !editVisible &&
                            !isDisable &&
                            !isSubordinateLogin &&
                            isCurrentUserAdmin &&
                            !user.block === true
                          "
                        >
                          <WButton
                            @click="loginAsSubordinate"
                            :data-name="user.username"
                            :data-id="user.id"
                          >
                            {{ $t('users.loginAsSubordinateForButton') }}
                          </WButton>
                        </td>
                      </tr>
                      <tr v-if="!filteredUsersList.length">
                        <td colspan="20" class="no-data-row">
                          <span class="text-muted pt-1">{{ $t('general.noDataToDisplay') }}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table v-else class="table table-hover users-table fix-overflowed-table">
                    <thead>
                      <tr>
                        <th v-if="isBeelineBranding || isNmsTest"></th>
                        <th>{{ $t('users.username') }}</th>
                        <th>{{ $t('users.Role') }}</th>
                        <th v-if="areLocationsVisible" >{{ $t('general.location') }}</th>
                        <th v-if="!editVisible">{{ $t('users.createdBy') }}</th>
                        <th v-if="!editVisible">{{ $t('users.creationDate') }}</th>
                        <th v-if="!editVisible">{{ $t('users.lastLogin') }}</th>
                        <th v-if="!editVisible">{{ $t('users.phone') }}</th>
                        <th v-if="!editVisible">{{ $t('users.company') }}</th>
                        <th v-if="!editVisible">{{ $t('users.description') }}</th>
                        <th v-if="!isDisable"></th>
                        <th v-if="!editVisible && !isDisable"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="user in usersList"
                        :key="user.id"
                        v-if="user.id == $store.state.userData.id"
                        :data-index="user.id"
                        @click="toggleEdit"
                        :class="{ 'row-read-only': isDisable }"
                        class="admin-row"
                      >
                        <td v-if="isBeelineBranding || isNmsTest">
                          <div v-if="!isBusinessIdExistForUserWithCTN(user)">
                            <i
                              class="fa fa-warning no-bis-id"
                              v-tooltip.top-center="{ content: `${$t('users.userHasCTNButHasNOtBisIDTooltip')}` }"
                            ></i>
                          </div>
                          <div v-else></div>
                        </td>
                        <td>
                          <div>
                            <span class="text-primary">{{ user.username }}</span>
                          </div>
                        </td>
                        <td>
                          <span class="text-primary">{{ $t(`general.role.${user.role}`) }}</span>
                        </td>
                        <td v-if="areLocationsVisible" class="responsive-row">
                          <span class="text-primary">{{ locationsItemsFromStore(user.base_location) }}</span>
                        </td>
                        <td>
                          <span class="text-primary" v-if="!editVisible">{{ userNameById(user.created_by) }}</span>
                        </td>
                        <td class="text-primary" v-if="!editVisible">
                          <small>{{ user.created_at | moment }}</small>
                        </td>
                        <td class="text-primary" v-if="!editVisible">
                          <small v-if="user.last_login == '0'">{{ $t('users.noLogin') }}</small>
                          <small v-else>{{ user.last_login | moment }}</small>
                        </td>
                        <td v-if="!editVisible">
                          <small>{{ user.phone }}</small>
                        </td>
                        <td v-if="!editVisible">
                          <small>{{ user.company }}</small>
                        </td>
                        <td v-if="!editVisible">
                          <small>{{ user.description }}</small>
                        </td>
                        <td class="action-row" v-if="!editVisible"></td>
                      </tr>

                      <tr
                        v-for="user in filteredUsersList"
                        :key="user.id"
                        v-if="user.id != $store.state.userData.id"
                        :data-index="user.id"
                        @click="toggleEdit"
                        :class="{ 'row-read-only': isDisable, selected: isUserSelected(user) }"
                      >
                        <td v-if="isBeelineBranding || isNmsTest">
                          <div v-if="!isBusinessIdExistForUserWithCTN(user)">
                            <i
                              class="fa fa-warning no-bis-id"
                              v-tooltip.top-center="{ content: `${$t('users.userHasCTNButHasNOtBisIDTooltip')}` }"
                            ></i>
                          </div>
                          <div v-else></div>
                        </td>
                        <td>{{ user.username }}</td>
                        <td>{{ $t(`general.role.${user.role}`) }}</td>
                        <td v-if="areLocationsVisible" class="responsive-row">{{ locationsItemsFromStore(user.base_location) }}</td>
                        <td v-if="!editVisible">{{ userNameById(user.created_by) }}</td>
                        <td v-if="!editVisible">
                          <small>{{ user.created_at | moment }}</small>
                        </td>
                        <td v-if="!editVisible">
                          <small v-if="user.last_login == '0'">{{ $t('users.noLogin') }}</small>
                          <small v-else>{{ user.last_login | moment }}</small>
                        </td>
                        <td v-if="!editVisible">
                          <small>{{ user.phone }}</small>
                        </td>
                        <td class="users-word-break" v-if="!editVisible">
                          <small>{{ user.company }}</small>
                        </td>
                        <td class="users-word-break" v-if="!editVisible">
                          <small>{{ user.description }}</small>
                        </td>
                        <td class="action-row" v-if="!isDisable">
                          <i
                            class="fa fa-pencil fa-lg mr-q text-info user-action"
                            data-action="edit"
                            :title="$t('users.editUserCaption')"
                          ></i>
                          <i
                            class="fa fa-remove fa-lg mr-q text-danger user-action"
                            data-action="delete"
                            :title="$t('users.deleteUserCaption')"
                          ></i>
                          <i
                            v-if="!user.block === true"
                            :class="{ 'user-action-disabled': isUserBlockOrUnblockRequestExecute }"
                            :title="$t('users.blockUserCaption')"
                            @click.stop="blockUser(user)"
                            class="fa fa-ban fa-lg mr-q text-warning user-action"
                            data-action="block"
                          ></i>
                          <i
                            v-else
                            :class="{ 'user-action-disabled': isUserBlockOrUnblockRequestExecute }"
                            :title="$t('users.unblockUserCaption')"
                            @click.stop="unblockUser(user)"
                            class="fa fa fa-key fa-lg mr-q text-success user-action"
                            data-action="unblock"
                          ></i>
                        </td>
                        <td
                          v-if="
                            !editVisible &&
                            !isDisable &&
                            !isSubordinateLogin &&
                            isCurrentUserAdmin &&
                            !user.block === true
                          "
                        >
                          <WButton
                            @click="loginAsSubordinate"
                            :data-name="user.username"
                            :data-id="user.id"
                          >
                            {{ $t('users.loginAsSubordinateForButton') }}
                          </WButton>
                        </td>
                      </tr>
                      <tr v-if="!filteredUsersList.length">
                        <td colspan="20" class="no-data-row">
                          <span class="text-muted pt-1">{{ $t('general.noDataToDisplay') }}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="user-edit" v-if="editVisible">
          <div class="row">
            <div class="col-lg-12">
              <div class="card">
                <div class="card-block">
                  <div class="h4 m-0 d-flex justify-content-between">
                    {{ userEditCurrent.username }}
                    <WButton secondary outline sm
                      @click="editVisible = false"
                    >
                      <i class="fa fa-close"></i>
                    </WButton>
                  </div>
                  <small class="text-primary">ID:</small>
                  <small class="text-muted">{{ updatedUser.id }}</small>
                  <br />
                  <div v-if="updatedUser.first_login != '0'">
                    <small class="text-primary">{{ $t('users.firstLoginForUserEditPanel') }}:</small>
                    <small class="text-muted">{{ updatedUser.first_login | moment }}</small>
                  </div>
                  <div v-if="updatedUser.last_login">
                    <small class="text-primary">{{ $t('users.lastLoginForUserEditPanel') }}:</small>
                    <small class="text-muted">{{ updatedUser.last_login | moment }}</small>
                  </div>
                  <div v-if="updatedUser.created_by">
                    <small class="text-primary">{{ $t('users.createdByForUserEditPanel') }}:</small>
                    <small class="text-muted">{{ userNameById(updatedUser.created_by) }}</small>
                  </div>
                  <div v-if="(isBeelineBranding || isNmsTest) && updatedUser.hasOwnProperty('bee_pro_bis_id')">
                    <small class="text-primary">{{ $t('users.bee_pro_bis_id') }}</small>
                    <small class="text-muted">{{ updatedUser.bee_pro_bis_id }}</small>
                  </div>
                  <small class="text-muted" v-if="!isDisable">{{ $t('general.editingMode') }}</small>

                  <form action="">
                    <div class="form-group mt-1">
                      <label for="user_name" class="required-mark">{{ $t('users.username') }}</label>
                      &nbsp;
                      <info :content="$t('users.updUsernameInfoMsg')"></info>
                      <input
                        type="text"
                        name="user_name"
                        :data-vv-as="$t('users.username')"
                        v-validate="'required|max:64|min:6'"
                        data-vv-validate-on="input"
                        :class="{ input: true, 'is-danger': errors.has('user_name') }"
                        id="user_name"
                        :placeholder="$t('users.enterUsernamePlaceHolder')"
                        class="form-control"
                        v-model="updatedUser.username"
                      />
                      <span v-show="errors.has('user_name')" class="help is-danger location-danger">
                        {{ errors.first('user_name') }}
                      </span>
                    </div>
                    <!--                    bee_pro_ctn-->
                    <div class="form-group" v-if="isBeelineBranding || isNmsTest">
                      <label for="bee_pro_ctn-in-edit-panel" class="">{{ $t('users.bee_pro_ctn') }}</label>
                      &nbsp;
                      <input
                        @input="handleCTNInEditPanelFieldInput"
                        id="bee_pro_ctn-in-edit-panel"
                        type="text"
                        name="bee_pro_ctn-in-edit-panel"
                        :data-vv-as="$t('users.bee_pro_ctn')"
                        data-vv-validate-on="change"
                        v-validate="'ctn'"
                        :class="{ input: true, 'is-danger': errors.has('bee_pro_ctn-in-edit-panel') }"
                        :placeholder="$t('users.bee_pro_ctn')"
                        class="form-control"
                        v-model="updatedUser.bee_pro_ctn"
                      />
                      <span v-show="errors.has('bee_pro_ctn-in-edit-panel')" class="help is-danger location-danger">
                        {{ errors.first('bee_pro_ctn-in-edit-panel') }}
                      </span>
                    </div>

                    <div class="form-group">
                      <label for="email">Email</label>
                      <input
                        type="text"
                        data-vv-as="Email"
                        name="email"
                        v-validate="'email'"
                        data-vv-validate-on="change"
                        :class="{ input: true, 'is-danger': errors.has('email') }"
                        id="email"
                        autocomplete="off"
                        :placeholder="$t('users.enterEmailPlaceHolder')"
                        class="form-control"
                        v-model="updatedUser.email"
                      />
                      <span v-show="errors.has('email')" class="help is-danger">
                        {{ errors.first('email') }}
                      </span>
                    </div>

                    <div v-if="isRootAdmin" class="form-group">
                      <label>{{ $t('users.setActiveUntillForUser') }}</label>
                      <div class="input-group">
                        <Date-picker
                          v-model="updatedUser.active_until"
                          class="w-100"
                          type="datetime"
                          value-type="timestamp"
                          inputClass="mx-input text-left"
                          :range="false"
                        />
                      </div>
                    </div>

                    <div class="row">
                      <div class="form-group col-sm-12">
                        <label for="role">{{ $t('users.Role') }}</label>
<!--                        <select id="role" class="form-control" name="role" v-model="updatedUser.role">-->
<!--                          <option value="admin" :selected="updatedUser.role === 'admin'">-->
<!--                            {{ $t('general.role.admin') }}-->
<!--                          </option>-->
<!--                          <option value="operator" :selected="updatedUser.role === 'operator'">-->
<!--                            {{ $t('general.role.operator') }}-->
<!--                          </option>-->
<!--                          <option value="marketer" :selected="updatedUser.role === 'marketer'">-->
<!--                            {{ $t('general.role.marketer') }}-->
<!--                          </option>-->
<!--                          <option value="hotel_employee" :selected="updatedUser.role === 'hotel_employee'">-->
<!--                            {{ $t('general.role.hotel_employee') }}-->
<!--                          </option>-->
<!--                          <option value="advertising" :selected="updatedUser.role === 'advertising'">-->
<!--                            {{ $t('general.role.advertising') }}-->
<!--                          </option>-->
<!--                        </select>-->
                        <select id="role" class="form-control" name="role" v-model="updatedUser.role">
                          <option v-if="!isUpdatedUserRoleInRolesForThisBuild(updatedUser.role)"  :value="updatedUser.role" :selected="updatedUser.role">
                            {{ $t(`general.role.${updatedUser.role}`) }}
                          </option>
                          <option v-for="role of listOfRolesBasedOnBuildType" :value="role" :selected="updatedUser.role === role">
                            {{ $t(`general.role.${role}`) }}
                          </option>
                        </select>
                      </div>
                    </div>

                    <div v-if="areLocationsVisible" class="form-group">
                      <label for="location">{{ $t('general.location') }}</label>
                      <select
                        @change="copyCompanyNameFromLocation"
                        v-model="updatedUser.base_location"
                        name="location"
                        class="form-control"
                      >
                        <option v-for="location in locationsItemsSorted" :value="location.id">
                          {{ location.name }}
                        </option>
                      </select>
                    </div>

                    <div class="form-group edit-password">
                      <label for="password">{{ $t('users.password') }}</label>
                      <div class="input-group">
                        <input
                          :data-vv-as="$t('users.password')"
                          ref="userEditPassword"
                          type="password"
                          name="user_password"
                          id="user_password"
                          :placeholder="$t('users.enterForPasswordChangePlaceHolder')"
                          autocomplete="new-password"
                          class="form-control"
                          v-validate="mixRulesForUserPasswordCheck()"
                          v-model="updatedUser.password"
                          data-vv-validate-on="input"
                          :class="{ input: true, 'is-danger': errors.has('user_password') }"
                        />
                        <span class="input-group-addon" @click="showPassword">
                          <i ref="userEditPasswordIcon" class="fa fa-eye"></i>
                        </span>
                        <Password-generator
                          @passwordGenerated="passwordGeneratedForEditedUser"
                          class="ml-1"
                        ></Password-generator>
                      </div>
                      <span v-show="errors.has('user_password')" class="help is-danger location-danger">
                        {{ errors.first('user_password') }}
                      </span>
                    </div>
                    <!-- PHONE MASK -->
                    <div class="form-group">
                      <label for="phone">{{ $t('users.phone') }}</label>
                      <the-mask
                        :mask="['# (###) ###-##-##', '## (###) ###-##-##', '### (###) ###-##-##']"
                        v-model="updatedUser.phone"
                        type="tel"
                        id="phone"
                        name="phone"
                        class="form-control input"
                      />
                    </div>

                    <div class="form-group">
                      <label for="company">{{ $t('users.company') }}</label>
                      <textarea
                        :data-vv-as="$t('users.company')"
                        v-model="updatedUser.company"
                        type="text"
                        id="company"
                        name="company"
                        class="form-control input"
                        v-validate="'max: 200'"
                        data-vv-validate-on="input"
                      />
                      <span
                        v-tooltip.top-center="{ content: errors.first('company') }"
                        v-show="errors.has('company')"
                        class="help is-danger error_tooltip"
                      >
                        <i class="fa fa-warning text-error" />
                      </span>
                      <span v-show="errors.has('company')" class="help is-danger location-danger">
                        {{ errors.first('company') }}
                      </span>
                      <div v-if="areLocationsVisible">
                        <WButton secondary outline sm
                          @click="copyCompanyNameFromLocation"
                        >
                          {{ $t('users.copyFromLocation') }}
                        </WButton>
                      </div>
                    </div>

                    <div class="form-group">
                      <label for="description">{{ $t('users.description') }}</label>
                      <textarea
                        :data-vv-as="$t('users.description')"
                        v-model="updatedUser.description"
                        type="text"
                        id="description"
                        name="description"
                        class="form-control input"
                        v-validate="'max: 100'"
                        data-vv-validate-on="input"
                      />
                      <span
                        v-tooltip.top-center="{ content: errors.first('description') }"
                        v-show="errors.has('description')"
                        class="help is-danger error_tooltip"
                      >
                        <i class="fa fa-warning text-error" />
                      </span>
                      <span v-show="errors.has('description')" class="help is-danger location-danger">
                        {{ errors.first('description') }}
                      </span>
                    </div>
                    <div class="form-group">
                      <Switch-component v-model="updatedUser.mac_mask" :label="$t('users.mac_mask')" id="mac-mask" />
                    </div>
                    <div class="form-group">
                      <Switch-component
                        v-model="updatedUser.block"
                        :label="$t('users.blockUserCaption')"
                        id="user-block"
                      />
                    </div>
                  </form>
                </div>

                <div class="actions-buttons" :class="{ 'actions-buttons__operator': isDisable }">
                  <div>
                    <WButton success outline
                      v-if="!isDisable && !isCurrentUserMarketer"
                      @click="updateUser"
                      :disabled="errors.any()"
                    >
                      {{ $t('general.save') }}
                    </WButton>
                    <!--                                   <button v-if="updatedUser.block === true"-->
                    <!--                      v-if="!isDisable && !isCurrentUserMarketer"-->
                    <!--                      type="button"-->
                    <!--                      class="btn btn-outline-warning btn-md "-->
                    <!--                      @click="blockUser(updatedUser.id)"-->
                    <!--                    >-->
                    <!--                      <span>{{ $t('users.blockUserCaption') }}</span>-->
                    <!--                    </button>-->
                    <!--                    <button v-else-->
                    <!--                      v-if="!isDisable && !isCurrentUserMarketer"-->
                    <!--                      type="button"-->
                    <!--                      class="btn btn-outline-warning btn-md "-->
                    <!--                      @click="blockUser(updatedUser)"-->
                    <!--                    >-->
                    <!--                      <span>{{ $t('users.blockUserCaption') }}</span>-->
                    <!--                    </button>-->
                    <WButton danger outline
                      customClass="ml-1"
                      v-if="!isDisable && !isCurrentUserMarketer"
                      @click="openDeletingDialog(updatedUser.id)"
                    >
                      {{ $t('general.delete') }}
                    </WButton>
                  </div>
                  <div>
                    <WButton secondary outline
                      @click="editVisible = false"
                    >
                      {{ $t('general.close') }}
                    </WButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Modal
      title="Add user"
      v-model="isUserModalOpen"
      @ok="isUserModalOpen = false"
      @cancel="isCloseModalOpen = true"
      effect="fade/zoom"
      class="modal-success"
      xlarge
      :backdrop="false"
    >
      <h4 slot="modal-title" class="modal-title">{{ $t('users.addUser') }}</h4>
      <div class="modal-body p-0">
        <div class="form-group">
          <label for="username" class="required-mark">{{ $t('users.username') }}</label>
          &nbsp;
          <info :content="$t('users.newUsernameInfoMsg')" />
          <input
            type="text"
            name="newuser-username"
            :placeholder="$t('users.enterUsernamePlaceHolder')"
            id="username"
            autocomplete="off"
            class="form-control"
            v-validate="'required|max:64|min:6'"
            data-vv-validate-on="input"
            :data-vv-as="$t('users.username')"
            :class="{ input: true, 'is-danger': errors.has('newuser-username') }"
            v-model="newUserData.username"
          />
          <span v-show="errors.has('newuser-username')" class="help is-danger">
            {{ errors.first('newuser-username') }}
          </span>
        </div>

        <div class="form-group" v-if="isBeelineBranding || isNmsTest">
          <label for="bee_pro_ctn-in-modal">{{ $t('users.bee_pro_ctn') }}</label>
          <input
            @input="handleCTNInModalFieldInput"
            type="text"
            name="bee_pro_ctn-in-modal"
            :placeholder="$t('users.bee_pro_ctn')"
            autocomplete="off"
            data-vv-validate-on="change"
            :data-vv-as="$t('users.bee_pro_ctn')"
            id="bee_pro_ctn-in-modal"
            v-validate="'ctn'"
            class="form-control"
            :class="{ input: true, 'is-danger': errors.has('bee_pro_ctn-in-modal') }"
            v-model="newUserData.bee_pro_ctn"
          />
          <span v-show="errors.has('bee_pro_ctn-in-modal')" class="help is-danger">
            {{ errors.first('bee_pro_ctn-in-modal') }}
          </span>
        </div>

        <div class="form-group">
          <label for="email">Email</label>
          <input
            type="text"
            name="newuser-email"
            :placeholder="$t('users.enterEmailPlaceHolder')"
            autocomplete="off"
            id="email"
            class="form-control"
            v-validate="'email'"
            data-vv-validate-on="change"
            data-vv-as="Email"
            :class="{ input: true, 'is-danger': errors.has('newuser-email') }"
            v-model="newUserData.email"
          />
          <span v-show="errors.has('newuser-email')" class="help is-danger">
            {{ errors.first('newuser-email') }}
          </span>
        </div>

        <div class="form-group adduser-password">
          <label for="password" class="required-mark">{{ $t('users.password') }}</label>
          <div class="input-group">
            <input
              ref="newUserPassword"
              type="password"
              name="newuser-password"
              :placeholder="$t('users.enterPasswordPlaceHolder')"
              autocomplete="new-password"
              id="password"
              class="form-control"
              v-validate="mixRulesForUserPasswordCheck('required')"
              :data-vv-as="$t('users.password')"
              data-vv-validate-on="input"
              :class="{ input: true, 'is-danger': errors.has('newuser-password') }"
              v-model="newUserData.password"
            />
            <span class="input-group-addon" @click="showPassword">
              <i ref="newUserPasswordIcon" class="fa fa-eye"></i>
            </span>
            <Password-generator  @passwordGenerated="passwordGeneratedForNewUserModal" class="ml-1"></Password-generator>
          </div>
          <span v-show="errors.has('newuser-password')" class="help is-danger">
            {{ errors.first('newuser-password') }}
          </span>
        </div>

<!--        <div class="form-group">-->
<!--          <label for="role">{{ $t('users.Role') }}</label>-->
<!--          <select name="newuser-role" id="role" class="form-control" v-model="newUserData.role">-->
<!--            <option value="operator" selected>{{ $t('general.role.operator') }}</option>-->
<!--            <option value="admin">{{ $t('general.role.admin') }}</option>-->
<!--            <option value="marketer">{{ $t('general.role.marketer') }}</option>-->
<!--            <option value="hotel_employee">{{ $t('general.role.hotel_employee') }}</option>-->
<!--            <option value="advertising">{{ $t('general.role.advertising') }}</option>-->
<!--          </select>-->
<!--        </div>-->
        <div class="form-group">
          <label for="role">{{ $t('users.Role') }}</label>
          <select name="newuser-role" id="role" class="form-control" v-model="newUserData.role">
            <option v-for="role of listOfRolesBasedOnBuildType" :value="role" >{{ $t(`general.role.${role}`) }}</option>
          </select>
        </div>

        <div v-if="areLocationsVisible" class="form-group">
          <label for="newuser-location">{{ $t('general.location') }}</label>
          <select
            @change="copyCompanyNameFromLocationForNewUser"
            v-model="newUserData.base_location"
            id="newuser-location"
            name="newuser-location"
            class="form-control"
            :class="{ input: true, 'is-danger': errors.has('newuser-location') }"
          >
            <option
              v-for="location in locationsItemsSorted"
              :value="location.id"
              :selected="newUserData.base_location === location.id"
            >
              {{ location.name }}
            </option>
          </select>
        </div>

        <div class="form-group">
          <label for="newuser-phone">{{ $t('users.phone') }}</label>
          <the-mask
            :mask="['# (###) ###-##-##', '## (###) ###-##-##', '### (###) ###-##-##']"
            v-model="newUserData.phone"
            type="text"
            id="newuser-phone"
            name="newuser-phone"
            class="form-control input"
          />
        </div>

        <div  class="form-group">
          <label for="newuser-company">{{ $t('users.company') }}</label>
          <textarea
            v-model="newUserData.company"
            v-if="isShowNewUserDataCompany"
            type="text"
            id="newuser-company"
            name="newuser-company"
            class="form-control input"
            v-validate="'max: 200'"
            :data-vv-as="$t('users.company')"
            data-vv-validate-on="input"
          />
          <span
            v-tooltip.top-center="{ content: errors.first('newuser-company') }"
            v-show="errors.has('newuser-company')"
            class="help is-danger error_tooltip"
          >
            <i class="fa fa-warning text-error" />
          </span>
          <span v-show="errors.has('newuser-company')" class="help is-danger">
            {{ errors.first('newuser-company') }}
          </span>
          <div v-if="areLocationsVisible">
            <WButton secondary outline sm
              @click="copyCompanyNameFromLocationForNewUser"
            >
              {{ $t('users.copyFromLocation') }}
            </WButton>
          </div>
        </div>

        <div class="form-group">
          <label for="newuser-description">{{ $t('users.description') }}</label>
          <textarea
            v-model="newUserData.description"
            type="text"
            id="newuser-description"
            name="newuser-description"
            class="form-control input"
            v-validate="'max: 100'"
            :data-vv-as="$t('users.description')"
            data-vv-validate-on="input"
          />
          <span
            v-tooltip.top-center="{ content: errors.first('newuser-description') }"
            v-show="errors.has('newuser-description')"
            class="help is-danger error_tooltip"
          >
            <i class="fa fa-warning text-error" />
          </span>
          <span v-show="errors.has('newuser-description')" class="help is-danger">
            {{ errors.first('newuser-description') }}
          </span>
        </div>
        <div class="form-group">
          <Switch-component v-model="newUserData.mac_mask" :label="$t('users.mac_mask')" id="newuser-mac-mask" />
        </div>
      </div>
      <div slot="modal-footer" class="">
        <div class="actions-buttons" :class="{ 'actions-buttons__operator': isDisable }">
          <div>
            <WButton secondary outline
              @click="isCloseModalOpen = true"
            >
              {{ $t('general.close') }}
            </WButton>
          </div>
          <div>
            <WButton success outline
              v-if="!isDisable"
              @click="createNewUser"
              :disabled="errors.any()"
            >
              {{ $t('general.add') }}
            </WButton>
          </div>
        </div>
      </div>
    </Modal>
    <!-- DELETE MODAL -->
    <Modal
      title="Delete User"
      class="modal-danger"
      v-model="deletingUserModal"
      okText="Confirm"
      cancelText="Cancel"
      @ok="deleteUser"
      @cancel="cancelDeleting"
      effect="fade/zoom"
    >
      <h4 slot="modal-title" class="modal-title">{{$t('users.deleteUserHeader')}}</h4>
      {{$t('general.confirmDeleting')}}
      <strong>{{ userToDelete }}</strong>
      ?
      <div slot="modal-footer" class="modal-footer">
        <WButton secondary
          @click="cancelDeleting"
        >
          {{ $t('general.cancel') }}
        </WButton>
        <WButton danger outline
          @click="deleteUser"
        >
          {{ $t('general.delete') }}
        </WButton>
      </div>
    </Modal>
    <!-- CLOSE MODAL -->

    <Modal title="Delete User" class="modal-warning" v-model="isCloseModalOpen" effect="fade/zoom">
      <h4 slot="modal-title" class="modal-title">{{ $t('general.closeModal') }}</h4>
      {{ $t('general.closeModalConfirm') }}
      <div slot="modal-footer" class="modal-footer">
        <WButton secondary
          @click="isCloseModalOpen = false"
        >
          {{ $t('general.cancel') }}
        </WButton>
        <WButton danger outline
          @click="() => {
              isUserModalOpen = false;
              isCloseModalOpen = false;
            }
          "
        >
          {{ $t('general.close') }}
        </WButton>
      </div>
    </Modal>
  </div>
</template>

<script>
import Vue from 'vue';
import { createNamespacedHelpers } from 'vuex';
import VueNotifications from 'vue-notifications';
import VeeValidate from 'vee-validate';
import moment from 'moment';
// NOTE: masked input from here https://github.com/vuejs-tips/vue-the-mask
import { TheMask } from 'vue-the-mask';
import { AuthTokenMaster } from '@/utils';
import DatePicker from '../components/date-picker.vue';
import Modal from '../components/Modal.vue';
import usersService from '../services/usersService';
import commonService from '../services/commonService';
import helpers from '../helpers';
import SwitchComponent from '../components/Universal/Switch-component.vue';
import PasswordGenerator from '../components/PasswordGenerator.vue';
import Info from '../components/Universal/info-icon.vue';
import locationService from "../services/locationService";
import SelectComponentV2 from '../components/Universal/select/select-componentV2.vue';
import SelectComponentV3ForUseInLocationSelectors from '../components/Universal/select/select-componentV3ForUseInLocationSelectors.vue';

import { userPasswordValidationRulesMixin } from '@/mixins';

const { mapGetters: WLCProductMapGetters } = createNamespacedHelpers('WLCProduct');

const watcher = new helpers.ChangesWatcher();

Vue.use(require('vue-moment'));

const { mapGetters: brandingMapGetters } = createNamespacedHelpers('branding');
const { mapGetters: featureFlagsMapGetters } = createNamespacedHelpers('featureFlags');


const USER_ROLES = ['admin', 'operator', 'marketer', 'hotel_employee', 'advertising' ]

export default {
  name: 'Users',
  mixins: [userPasswordValidationRulesMixin],
  components: {
    Modal,
    TheMask,
    SwitchComponent,
    PasswordGenerator,
    DatePicker,
    Info,
    SelectComponentV2,
    SelectComponentV3ForUseInLocationSelectors
  },
  data() {
    return {
      showLocationFilter: true,
      locationInFiltersData: [],
      pdfTableColumnsWidths: ['11%', '11%', '11%', '11%', '11%', '11%', '11%', '11%', '11%'],
      pdfTableColumnsWidthsTenColumns: ['10%', '10%', '10%', '10%', '10%', '10%', '10%', '10%', '10%', '10%'],
      isShowNewUserDataCompany: true,
      updatedUser: false,
      newUserData: {
        mac_mask: false,
        username: '',
        email: '',
        password: '',
        role: 'admin',
        base_location: this.$store.state.userData.base_location,
        phone: '',
        company: '',
        description: '',
        active_until: 0
      },
      searchValue: '',
      filterBycompanyName: 'All companys',
      filterByUserStatus: 'All statuses',
      filterByUserRole: 'All roles',
      sortBy: 'username',
      sortOrder: 'asc',
      sortByOptions: [
        { value: 'username', label: this.$t('users.username') },
        { value: 'role', label: this.$t('users.Role') },
        { value: 'created_at', label: this.$t('users.creationDate') },
        { value: 'last_login', label: this.$t('users.lastLogin') },
        { value: 'block', label: this.$t('users.isBlocked') }
      ],
      editVisible: false,
      isUserModalOpen: false,
      isNewUser: false,
      deletingUserModal: false,
      isCloseModalOpen: false,
      filterByLocation: {
        location: 'All locations',
        with_childs: true,
      }

    };
  },
  computed: {
    ...WLCProductMapGetters(['isWLCProduct']),
    ...brandingMapGetters(['isBeelineBranding']),
    ...featureFlagsMapGetters(['isContentPortalEnabled', 'isPortalOnlyModeEnabled', 'isContentAnalyticsEnabled']),
    isVisibleAddUserWithDemoDataButton() {
      return !this.isDisable && !this.isCurrentUserMarketer && this.areLocationsVisible && this.isWLCProduct;
    },
    isSelectedDefaultLocationForRequests() {
      if (this.$store.getters.getDefaultLocationForRequests(this)) {
        return true;
      } else {
        return false;
      }
    },
    defaultNewUserRoleForBuild() {
      if (this.hasNMS) {
        return 'operator';
      } else {
        if (this.isContentAnalyticsEnabled) {
          return 'marketer';
        }
        if (this.hasPortal) {
          return 'advertising';
        }
        return 'admin'
      }
    },
    listOfRolesBasedOnBuildType() {
      const listOfRoles = new Set();
      if (this.hasNMS) {
        listOfRoles.add('admin');
        listOfRoles.add('operator');
      }
      if (this.isContentAnalyticsEnabled) {
        listOfRoles.add('admin');
        listOfRoles.add('marketer');
      }
      if (this.hasPortal) {
        listOfRoles.add('admin');
        listOfRoles.add('hotel_employee');
        listOfRoles.add('advertising');
      }
      // console.log(listOfRoles)
      return Array.from(listOfRoles);
    },
    hasPortal() {
      if (this.isPortalOnlyModeEnabled || this.isContentPortalEnabled) {
        return true;
      }
      return false;
    },
    hasNMS() {
      if (!this.isPortalOnlyModeEnabled) {
        return true;
      }
      return false;
    },
    locationsList() {
      return this.$store.getters.locationsListSorted;
    },
    userBaseLocation() {
      return this.$store.state.userData.base_location;
    },
    userRoles() {
      return USER_ROLES;
    },
    isNmsTest() {
      return this.$store.getters.isNmsTest;
    },
    companys() {
      return this.$store.getters.getCompanyList;
    },
    newUserDataUsername() {
      return this.newUserData.username;
    },
    newUserDataCompany() {
      return this.newUserData.company;
    },
    newUserDataDescription() {
      return this.newUserData.description;
    },
    companyList() {
      return this.$store.getters.companyListSorted;
    },
    isUserBlockOrUnblockRequestExecute() {
      return this.$store.state.userBlockOrUnblockRequestExecute;
    },
    isCurrentUserMarketer() {
      return this.$store.state.userData.role === 'marketer';
    },
    isCurrentUserAdmin() {
      return this.$store.state.userData.role === 'admin';
    },
    isRootAdmin() {
      return this.$store.getters.isRootAdmin;
    },
    // usersList: {
    //   get() {
    //     return this.$store.state.usersList;
    //   },
    //   set() {}
    // },
    usersList: {
      get() {
        const result = JSON.parse(JSON.stringify(this.$store.state.usersList));
        return helpers.sortUsersList(result);
      },
      set() {}
    },
    // filteredUsersList() {
    //   try {
    //     let filteredUsersList = JSON.parse(JSON.stringify(this.usersList));
    //     if (this.searchValue !== '') {
    //       filteredUsersList = filteredUsersList.filter((user) => {
    //         if (
    //           user.company.toLowerCase().includes(this.searchValue.toLowerCase()) ||
    //           user.username.toLowerCase().includes(this.searchValue.toLowerCase()) ||
    //           user.phone.toLowerCase().includes(this.searchValue.toLowerCase())
    //         ) {
    //           return true;
    //         }
    //         return false;
    //       });
    //     }
    //     // console.log(filteredUsersList)
    //     if (this.filterBycompanyName !== 'All companys') {
    //       let locationForCompany = [];
    //       if (this.filterBycompanyName.hasOwnProperty('locations') && this.filterBycompanyName.locations.length > 0) {
    //         locationForCompany = this.filterBycompanyName.locations;
    //       }
    //       filteredUsersList = filteredUsersList.filter((user) => {
    //         if (locationForCompany.includes(user.base_location)) {
    //           return true;
    //         }
    //         return false;
    //       });
    //     }
    //     if (this.filterByUserStatus !== 'All statuses') {
    //       if (this.filterByUserStatus === 'Blocked') {
    //         filteredUsersList = filteredUsersList.filter((user) => {
    //           if (user.block === true) {
    //             return true;
    //           }
    //           return false;
    //         });
    //       } else if (this.filterByUserStatus === 'NoBlocked') {
    //         filteredUsersList = filteredUsersList.filter((user) => {
    //           if (user.block !== true) {
    //             return true;
    //           }
    //           return false;
    //         });
    //       }
    //     }
    //     return filteredUsersList;
    //   } catch (e) {
    //     console.log(e);
    //     return JSON.parse(JSON.stringify(this.$store.state.usersList));
    //   }
    // },

    filteredUsersList() {
      try {
        let filteredUsersList = JSON.parse(JSON.stringify(this.usersList));
        if (this.searchValue !== '') {
          filteredUsersList = filteredUsersList.filter((user) => {
            if (
              user.company.toLowerCase().includes(this.searchValue.toLowerCase()) ||
              user.username.toLowerCase().includes(this.searchValue.toLowerCase()) ||
              user.phone.toLowerCase().includes(this.searchValue.toLowerCase())
            ) {
              return true;
            }
            return false;
          });
        }
        // console.log(filteredUsersList)
        if (this.filterBycompanyName !== 'All companys') {
          let locationForCompany = [];
          if (this.filterBycompanyName.hasOwnProperty('locations') && this.filterBycompanyName.locations.length > 0) {
            locationForCompany = this.filterBycompanyName.locations;
          }
          filteredUsersList = filteredUsersList.filter((user) => {
            if (locationForCompany.includes(user.base_location)) {
              return true;
            }
            return false;
          });
        }
        //
        // if (this.filterByLocation.location !== 'All locations') {
        //
        //   filteredUsersList = filteredUsersList.filter((user) => {
        //     if (this.filterByLocation.location === user.base_location) {
        //       return true;
        //     }
        //     return false;
        //   });
        // }

        if (this.filterByUserStatus !== 'All statuses') {
          if (this.filterByUserStatus === 'Blocked') {
            filteredUsersList = filteredUsersList.filter((user) => {
              if (user.block === true) {
                return true;
              }
              return false;
            });
          } else if (this.filterByUserStatus === 'NoBlocked') {
            filteredUsersList = filteredUsersList.filter((user) => {
              if (user.block !== true) {
                return true;
              }
              return false;
            });
          }
        }
        if (this.filterByUserRole !== 'All roles') {
          if (this.userRoles.includes(this.filterByUserRole)) {
            filteredUsersList = filteredUsersList.filter((user) => {
              if (user.role === this.filterByUserRole) {
                return true;
              }
              return false;
            });
          }
        }
        // сортировки
        let sortedfilteredUsersList = [];
        let first;
        let second;
        if (this.sortOrder === 'desc') {
          first = -1;
          second = 1;
        } else if (this.sortOrder === 'asc') {
          first = 1;
          second = -1;
        }
        if (this.sortBy === 'username') {
          sortedfilteredUsersList = filteredUsersList.sort((curr, prev) => {
            if (curr.username.toLowerCase() > prev.username.toLowerCase()) {
              return first;
            }
            if (curr.username.toLowerCase() < prev.username.toLowerCase()) {
              return second;
            }
            return 0;
          });
        } else if (this.sortBy === 'last_login') {
          sortedfilteredUsersList = filteredUsersList.sort((curr, prev) => {
            if (curr.last_login > prev.last_login) {
              return first;
            }
            if (curr.last_login < prev.last_login) {
              return second;
            }
            return 0;
          });
        } else if (this.sortBy === 'created_at') {
          sortedfilteredUsersList = filteredUsersList.sort((curr, prev) => {
            if (curr.created_at > prev.created_at) {
              return first;
            }
            if (curr.created_at < prev.created_at) {
              return second;
            }
            return 0;
          });
        } else if (this.sortBy === 'role') {
          sortedfilteredUsersList = filteredUsersList.sort((curr, prev) => {
            if (this.$t(`general.role.${curr.role.toLowerCase()}`) > this.$t(`general.role.${prev.role.toLowerCase()}`)) {
              return first;
            }
            if (this.$t(`general.role.${curr.role.toLowerCase()}`) < this.$t(`general.role.${prev.role.toLowerCase()}`)) {
              return second;
            }
            return 0;
          });
        } else if (this.sortBy === 'block') {
          sortedfilteredUsersList = filteredUsersList.sort((curr, prev) => {
            if (curr.block > prev.block) {
              return first;
            }
            if (curr.block < prev.block) {
              return second;
            }
            return 0;
          });
        }
        return sortedfilteredUsersList.length > 0 ? sortedfilteredUsersList : filteredUsersList;
      } catch (e) {
        console.log(e);
        return JSON.parse(JSON.stringify(this.$store.state.usersList));
      }
    },

    userEditCurrent() {
      if (!this.$store.state.editUserIndex) {
        return this.$store.state.userData;
      }
      // this.updatedUser = JSON.parse(JSON.stringify(commonService.userObjById(this.$store.state.editUserIndex)));
      // return JSON.parse(JSON.stringify(commonService.userObjById(this.$store.state.editUserIndex)));

      const updatedUser = JSON.parse(JSON.stringify(commonService.userObjById(this.$store.state.editUserIndex)));
      // console.log(updatedUser)
      if (
        (this.isBeelineBranding || this.isNmsTest) &&
        (!Object.prototype.hasOwnProperty.call(updatedUser, 'bee_pro_ctn') ||
          updatedUser.bee_pro_ctn === null ||
          updatedUser.bee_pro_ctn === undefined)
      ) {
        updatedUser.bee_pro_ctn = '';
      }
      // console.log(updatedUser)
      this.updatedUser = JSON.parse(JSON.stringify(updatedUser));
      return JSON.parse(JSON.stringify(updatedUser));
    },

    isUsersLoading() {
      return this.$store.state.loadingUsers;
    },
    locationsItems() {
      return this.$store.state.locationsItems;
    },
    locationsItemsSorted() {
      return this.$store.getters.locationsListSorted;
    },
    userToDelete() {
      return commonService.userNameById(this.$store.state.editUserIndex);
    },
    isDisable() {
      return this.$store.state.userData.role === 'operator';
    },
    isSubordinateLogin() {
      return this.$store.state.subordinateLogin;
    },
    userEditCurrentId() {
      return this.$store.state.editUserIndex;
    }
  },
  methods: {
    isUpdatedUserRoleInRolesForThisBuild(role){
      return this.listOfRolesBasedOnBuildType.includes(role);
    },
    changeLocation() {
      // console.log('location changed')
      this.refreshUsersList();
    },
        selectLocationInFilters(location) {
      // console.log(location)
      if (!location) {
        this.filterByLocation.location = 'All locations'
      } else {
        this.filterByLocation.location = location.id;
        // this.addLocationInPreviouslySelectedLocationsInFilter(location)
      }
      this.refreshUsersList();

    },
    async requestLocations(searchQuery) {
      // return locationService.requestLocations(searchQuery);
      // return locationService.requestLocationsWithSort(searchQuery);
      return locationService.requestLocationsWithSortAndFilteringByChildsForLocationSelectInPages(this, searchQuery)
    },
    locationsObjbyIds(ids) {
      return commonService.locationsObjbyIds(ids);
    },
    toggleSortOrder(){
      if (this.sortOrder === 'asc'){
        this.sortOrder = 'desc';
      } else {
        this.sortOrder = 'asc';
      }
    },
    capitalizeFirstLetter(str){
      return helpers.capitalizeFirstLetter(str);
    },
    getFileNameForDownloadFiles(pageName) {
      return helpers.getFileNameForDownloadFiles(pageName)
    },
    convertToNewExcelExportModuleFormat(inputDataForOldExcelModule, nameForExcelList){
      return helpers.convertFromOldExcelModuleFormatToNewExcelModuleFormat(inputDataForOldExcelModule, nameForExcelList)
    },
    isUserSelected(user) {
      // используем чтобы выделять в таблице строчку с юзером, которого редактируем в данный момент
      try {
        if (user.id === this.updatedUser.id && this.editVisible === true) {
          return true;
        }
        return false;
      } catch (e) {
        return false;
      }
    },
    isBusinessIdExistForUserWithCTN(user) {
      // проверяем есть ли businessId у пользователя, у которого есть CTN. Если есть выводим true
      // если нет то false
      try {
        if (user.bee_pro_ctn !== '' && (user.bee_pro_bis_id === 0 || user.bee_pro_bis_id === '')) {
          return false;
        }
        // console.log(user);
        return true;
      } catch (e) {
        return true;
      }
    },
    handleCTNInModalFieldInput() {
      if (Object.prototype.hasOwnProperty.call(this, 'errors')) {
        if (this.errors.has('bee_pro_ctn-in-modal')) {
          this.errors.remove('bee_pro_ctn-in-modal');
        }
      }
    },
    handleCTNInEditPanelFieldInput() {
      if (Object.prototype.hasOwnProperty.call(this, 'errors')) {
        if (this.errors.has('bee_pro_ctn-in-edit-panel')) {
          this.errors.remove('bee_pro_ctn-in-edit-panel');
        }
      }
    },
    redrawFilters(){
      this.showLocationFilter = false;
      this.$nextTick(()=>{
        this.showLocationFilter = true;
      })
    },
    // resetAllFilters() {
    //   this.locationInFiltersData = [];
    //   this.redrawFilters();
    //   this.searchValue = '';
    //   this.filterBycompanyName = 'All companys';
    //   this.filterByUserStatus = 'All statuses';
    //   this.filterByUserRole = 'All roles';
    //   this.sortBy = 'username';
    //   this.sortOrder = 'asc';
    //   this.filterByLocation = {
    //     location: 'All locations',
    //     with_childs: true,
    //   }
    //   this.refreshUsersList();
    // },
    resetAllFilters() {
      // this.locationInFiltersData = [];
      // this.redrawFilters();
      this.searchValue = '';
     //  this.filterBycompanyName = 'All companys';
      // this.filterByUserStatus = 'All statuses';
      this.filterByUserRole = 'All roles';
      this.sortBy = 'username';
      this.sortOrder = 'asc';
      this.filterByLocation = {
        // location: 'All locations',
        // with_childs: true,
      }
      if (!this.isSelectedDefaultLocationForRequests) {
        this.filterByLocation.location = 'All locations';
        this.filterByLocation.with_childs = true;
        this.locationInFiltersData = [];
      } else {
        this.filterByLocation.location = this.$store.getters.getDefaultLocationForRequests(this)
        this.filterByLocation.with_childs = this.$store.getters.getDefaultWithChildsForRequests(this);
        this.locationInFiltersData = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject;
      }
      this.redrawFilters();
      this.refreshUsersList();
    },
    refreshUsersList() {
      // console.log('refreshed');
      this.getUsers();
    },
    changeCompany() {
      // console.log(val)
      let firstLocationIdForCompany = '';
      if (this.filterBycompanyName.hasOwnProperty('locations') && this.filterBycompanyName.locations.length > 0) {
        firstLocationIdForCompany = this.filterBycompanyName.locations[0];
        // console.log(firstLocationIdForCompany);
      } else {
        firstLocationIdForCompany = 'All locations';
        this.locationInFiltersData = []
      }

      this.filterByLocation.location = firstLocationIdForCompany
      this.refreshUsersList();
    },
    clearFilters() {
      this.searchValue = '';
      this.filterBycompanyName = 'All companys';
      this.filterByLocation = {
        location: 'All locations',
        with_childs: true,
      }
    },
    blockUser(user) {
      if (this.isUserBlockOrUnblockRequestExecute) {
        // console.log('executed');
      } else {
        // console.log(`Block`);
        // console.log(user);
        usersService.blockUser(this, user);
      }
    },
    unblockUser(user) {
      if (this.isUserBlockOrUnblockRequestExecute) {
        // console.log('executed');
      } else {
        // console.log(`Unblock`);
        // console.log(user);
        usersService.unblockUser(this, user);
      }
    },
    copyCompanyNameFromLocationForNewUser() {
      this.isShowNewUserDataCompany = false;
      this.newUserData.company = this.findCompanyByLocationId(this.newUserData.base_location);
      this.$nextTick(() => {
        this.isShowNewUserDataCompany = true;
      });
    },
    copyCompanyNameFromLocation() {
      // console.log('copy!')
      this.updatedUser.company = this.findCompanyByLocationId(this.updatedUser.base_location);
    },
    findCompanyByLocationId(locationId) {
      try {
        let companyName = '';
        const locations = JSON.parse(JSON.stringify(this.locationsItems));
        if (locations.hasOwnProperty(locationId)) {
          if (locations[locationId].hasOwnProperty('party')) {
            if (locations[locationId].party.hasOwnProperty('name')) {
              companyName = locations[locationId].party.name;
            }
          }
        }
        return companyName;
      } catch (e) {
        console.log(e);
        return '';
      }
    },
    passwordGeneratedForEditedUser(generatedPassword) {
      const userPassword = this.$refs.userEditPassword;
      const userpasswordIcon = this.$refs.userEditPasswordIcon;
      if (userPassword) {
        if (userPassword.type === 'password') {
          userPassword.type = 'text';
          userpasswordIcon.className = 'fa fa-eye-slash';
        }
      }
      this.updatedUser.password = generatedPassword;
    },
    passwordGeneratedForNewUserModal(generatedPassword) {
      // показываем пароль если был скрыт
      const newuserPassword = this.$refs.newUserPassword;
      const newUserpasswordIcon = this.$refs.newUserPasswordIcon;

      if (newuserPassword) {
        if (newuserPassword.type === 'password') {
          newuserPassword.type = 'text';
          newUserpasswordIcon.className = 'fa fa-eye-slash';
        }
      }
      // console.log(generatedPassword);
      this.newUserData.password = generatedPassword;
    },
    openaddUserWithDemoData() {
      this.$router.push({ name: 'usersDemoWizard' });
    },
    loginAsSubordinate(event) {
      event.stopPropagation();
      // console.log(event);
      // console.log(event.target.dataset.name);
      // console.log(event.target.dataset.id);
      const subordinateName = event.target.dataset.name;
      const subordinateId = event.target.dataset.id;
      const subordinateTokenPromise = usersService.getTokenForLoginAsSubordinate(subordinateName, subordinateId);
      subordinateTokenPromise.then(
        (response) => {
          if (response.data.status === 'success') {
            if (response.data.data.items) {
              // console.log(response.data.data.items.token);
              // вместо /userChange может быть любое имя, нужно чтобы dashboard(/) перезагрузился
              // (чтобы подгрузилось всякое, что грузится при входе в систему),
              // даже если юзер уже находится на dashboard(/),
              this.$router.push('/userchange', () => {
                localStorage.setItem('bossUserData', localStorage.getItem('userData'));

                AuthTokenMaster.setAccessToken(response.data.data.items.token);

                this.axios.defaults.headers.common.Authorization = `Bearer ${response.data.data.items.token}`;
                if (response.data.data.items.photo === '') {
                  response.data.data.items.photo = '/static/img/avatars/user.svg';
                }
                this.$store.commit('setUserData', response.data.data.items);
                // this.$store.commit('setBaseLocation', response.data.data.items.base_location);
                // this.$store.commit('setBaseLocation', '');
                console.warn(`Login as ${response.data.data.items.username}`);
                this.$store.commit('togglesubordinateLogin');
                this.$router.push('/');
              });
            }
          } else if (response.data.status === 'error') {
            VueNotifications.error({ message: response.data.description });
          }
        },
        (err) => {
          VueNotifications.error({ message: err });
        }
      );
    },
    toggleEdit(e, userId) {
      if (this.$store.state.userData.role === 'operator') {
        return;
      }
      const selectedNode = e.target.closest('[data-index]');
      this.$store.commit('setUserEditIndex', selectedNode.dataset.index);
      e.target.dataset.action === 'delete' ?
        this.openDeletingDialog(selectedNode.dataset.index, true) :
        selectedNode.dataset.index == this.$store.state.userData.id ?
          (this.editVisible = false) :
          (this.editVisible = true);
    },
    getUsers() {
      // usersService.getUsers(this);
      usersService.getUsers(this, {}, true);
    },
    createNewUser() {
      // this.resetAllFilters();
      usersService.createNewUser(this);
    },
    updateUser() {
      usersService.updateUser(this);
    },
    openDeletingDialog(id, clickFromTableButton = false) {
      this.wlanIdToDelete = id;
      if (clickFromTableButton === true) {
        const userData = this.usersList.filter((user) => user.id === id);
        // console.log(userData)
        this.updatedUser = userData[0];
      }
      this.deletingUserModal = true;
    },
    deleteUser() {
      usersService.deleteUser(this);
    },
    cancelDeleting() {
      this.deletingUserModal = false;
    },
    closeConfirmModal() {
      this.isCloseModalOpen = false;
      helpers.handleSecondModalHiding();
    },
    handleConfirmClose() {
      this.closeConfirmModal();
      helpers.handleBothModalsHiding();
      // this.cancelEditMode();
    },
    locationsItemsFromStore(location_id) {
      const locationItems = this.$store.state.locationsItems;
      if (locationItems[location_id]) {
        return locationItems[location_id].name;
      }
    },
    userNameById(id) {
      const userName = commonService.userNameById(id);
      return userName;
    },
    showPassword() {
      // позже разобью на две части
      const userPassword = document.querySelector('.adduser-password input');
      const passwordIcon = document.querySelector('.adduser-password i');
      const editPassword = document.querySelector('.edit-password input');
      const editIcon = document.querySelector('.edit-password i');

      if (userPassword) {
        if (userPassword.type === 'password') {
          userPassword.type = 'text';
          passwordIcon.className = 'fa fa-eye-slash';
        } else {
          userPassword.type = 'password';
          passwordIcon.className = 'fa fa-eye';
        }
      }

      if (editPassword) {
        if (editPassword.type === 'password') {
          editPassword.type = 'text';
          editIcon.className = 'fa fa-eye-slash';
        } else {
          editPassword.type = 'password';
          editIcon.className = 'fa fa-eye';
        }
      }
    },
    // formatToCSV(data) {
    //   if (!data) {
    //     return [];
    //   }
    //
    //   const copy = JSON.parse(JSON.stringify(data));
    //
    //   if (this.isCurrentUserAdmin) {
    //     const result = [];
    //     for (const item of data) {
    //       let passwordHash = '';
    //       let passwordHashAsString = '';
    //       if (item.password) {
    //         passwordHash = item.password;
    //         const passwordHashAsArray = Array.from(passwordHash);
    //         // passwordHashAsArray.splice(30, 0, '\n');
    //         passwordHashAsString = passwordHashAsArray.join('');
    //       }
    //       const formattedItem = {
    //         Username: item.username ? item.username : '',
    //         Role: item.role ? item.role : '',
    //         Location: item.base_location ? this.locationsItemsFromStore(item.base_location) : '',
    //         'Created by': item.created_by ? this.userNameById(item.created_by) : '',
    //         'Creation Date': item.created_at ? this.formatDateForCSV(item.created_at) : '',
    //         'First Login': item.first_login ? this.formatDateForCSV(item.first_login) : '',
    //         'Last Login': item.last_login ? this.formatDateForCSV(item.last_login) : '',
    //         Phone: item.phone ? item.phone : '',
    //         Email: item.email ? item.email : '',
    //         Company: item.company ? item.company : '',
    //         Description: item.description ? item.description : '',
    //         'Password hash': passwordHashAsString
    //       };
    //       result.push(formattedItem);
    //     }
    //     return result;
    //   }
    //   return copy.map((item) => ({
    //     Username: item.username ? item.username : '',
    //     Role: item.role ? item.role : '',
    //     Location: item.base_location ? this.locationsItemsFromStore(item.base_location) : '',
    //     'Created by': item.created_by ? this.userNameById(item.created_by) : '',
    //     'Creation Date': item.created_at ? this.formatDateForCSV(item.created_at) : '',
    //     'First Login': item.first_login ? this.formatDateForCSV(item.first_login) : '',
    //     'Last Login': item.last_login ? this.formatDateForCSV(item.last_login) : '',
    //     Phone: item.phone ? item.phone : '',
    //     Email: item.email ? item.email : '',
    //     Company: item.company ? item.company : '',
    //     Description: item.description ? item.description : ''
    //   }));
    // },


    formatToCSV(data) {
      if (!data) {
        return [];
      }

      const copy = JSON.parse(JSON.stringify(data));

      // if (this.isCurrentUserAdmin) {
      //   const result = [];
      //   for (const item of data) {
      //     let passwordHash = '';
      //     let passwordHashAsString = '';
      //     if (item.password) {
      //       passwordHash = item.password;
      //       const passwordHashAsArray = Array.from(passwordHash);
      //       // passwordHashAsArray.splice(30, 0, '\n');
      //       passwordHashAsString = passwordHashAsArray.join('');
      //     }
      //     const formattedItem = {
      //       Username: item.username ? item.username : '',
      //       Role: item.role ? item.role : '',
      //       Location: item.base_location ? this.locationsItemsFromStore(item.base_location) : '',
      //       'Created by': item.created_by ? this.userNameById(item.created_by) : '',
      //       'Creation Date': item.created_at ? this.formatDateForCSV(item.created_at) : '',
      //       'First Login': item.first_login ? this.formatDateForCSV(item.first_login) : '',
      //       'Last Login': item.last_login ? this.formatDateForCSV(item.last_login) : '',
      //       Phone: item.phone ? item.phone : '',
      //       Email: item.email ? item.email : '',
      //       Company: item.company ? item.company : '',
      //       Description: item.description ? item.description : '',
      //       'Password hash': passwordHashAsString
      //     };
      //     result.push(formattedItem);
      //   }
      //   return result;
      // }
      return copy.map((item) => {
        const result = {};
        result[this.$t('users.username')] = item.username ? item.username : '';
        result[this.$t('users.Role')] = item.role ? this.getI18nRoleCaptionForDownloads(item.role) : '';
        // result[this.$t('general.location')] = item.base_location ? this.locationsItemsFromStore(item.base_location) : '';
        if (this.areLocationsVisible) {
          result[this.$t('general.location')] = item.base_location ? this.locationsItemsFromStore(item.base_location) : '';
        }
        result[this.$t('users.createdBy')] = item.created_by ? this.userNameById(item.created_by) : '';
        result[this.$t('users.creationDate')] = item.created_at ? this.formatDateForCSV(item.created_at) : '';
        result[this.$t('users.lastLogin')] = item.last_login ? this.formatDateForCSV(item.last_login) : '';
        result[this.$t('users.phone')] = item.phone ? item.phone : '';
        result[this.$t('users.company') ] = item.company ? item.company : this.$t('general.no').toLowerCase();
        result[this.$t('users.description')] = item.description ? item.description : '';
        if (this.isCurrentUserAdmin){
          result[this.$t('users.isBlocked')] = (item.block === true) ? this.$t('general.yes') : this.$t('general.no');
        }
        return result;
      });
    },

    getI18nRoleCaptionForDownloads(role) {
      return this.$t(`general.role.${role}`)
    },
    formatDateForCSV(value) {
      return moment.unix(value).format('D MMM YYYY, H:mm:ss');
    },
    // openUserModal(isNew, User) {
    //   this.$validator.reset();
    //   this.isUserModalOpen = true;
    //   this.isNewUser = false;
    //   if (isNew) {
    //     this.isNewUser = true;
    //     if (this.isBeelineBranding || this.isNmsTest) {
    //       this.$set(this.newUserData, 'bee_pro_ctn', ''); // добавляем свойство и делаем его реактивным
    //     }
    //     this.updatedUser = JSON.parse(JSON.stringify(this.newUserData));
    //     this.newUserData.mac_mask = true;
    //     this.$set(this.newUserData, 'password', ''); // делаем свойство реактивным
    //   }
    //   watcher.reset();
    // }
    openUserModal(isNew, User) {
      this.$validator.reset();
      this.editVisible = false
      this.$store.commit('setUserEditIndex', false);
      this.isUserModalOpen = true;
      this.isNewUser = false;
      if (isNew) {
        this.isNewUser = true;
        if (this.isBeelineBranding || this.isNmsTest) {
          this.$set(this.newUserData, 'bee_pro_ctn', ''); // добавляем свойство и делаем его реактивным
        }
        this.newUserData.role = this.defaultNewUserRoleForBuild;
        this.updatedUser = JSON.parse(JSON.stringify(this.newUserData));
        this.newUserData.mac_mask = true;
        this.$set(this.newUserData, 'password', ''); // делаем свойство реактивным
      }
      watcher.reset();
    }
  },
  filters: {
    moment(value) {
      if (value) {
        return moment(value * 1000).format(' D MMM YYYY, H:mm:ss ');
      }
    }
  },
  // created() {
  //   usersService.getUsers(this);
  // },
  created() {
    let inHeaderLocationSelectedApplying = true;
    if (this.$store.state.inHeaderLocationSelectModeEnabled &&
      inHeaderLocationSelectedApplying) {
      let withChildsForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.withChilds;
      let locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationID;
      if (!locationForFiltering || locationForFiltering === '') {
        // locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.userBaseLocation
        locationForFiltering = 'All locations';
      }

      if (locationForFiltering === 'All locations') {
        this.locationInFiltersData = [];
      } else {
        this.locationInFiltersData = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject
      }

      this.filterByLocation.location = locationForFiltering;
      this.filterByLocation.with_childs = withChildsForFiltering;
    }

    // usersService.getUsers(this);
    usersService.getUsers(this, {}, true);
  },

  mounted() {
    // console.log('filters reseted')
    // this.resetAllFilters();
  },
  watch: {
    newUserDataUsername(newValue) {
      if (this.newUserDataUsername !== undefined && newValue.length > 64) {
        setTimeout(() => {
          this.newUserData.username = this.newUserData.username.slice(0, 64);
        }, 1000);
      }
    },
    newUserDataCompany(newValue) {
      if (this.newUserDataCompany !== undefined && newValue.length > 200) {
        setTimeout(() => {
          this.newUserData.company = this.newUserData.company.slice(0, 200);
        }, 1000);
      }
    },
    newUserDataDescription(newValue) {
      if (this.newUserDataDescription !== undefined && newValue.length > 100) {
        setTimeout(() => {
          this.newUserData.description = this.newUserData.description.slice(0, 100);
        }, 1000);
      }
    }
  }
};
</script>

<style lang="scss">
        .input-group-for-location-Users--filters {
    // min-height: 27px !important;
    position: relative;
    display: flex;

    align-items: stretch;
    width: 100%;
    max-width: 100%;

  }
      .select-location-in-Users--filters .select__list-container {
    width: 400px;
  }
.users {
  display: flex;
}

/* Users list */
.users-list {
  display: inline-block;
  width: 100%;
}

.users-list,
.user-edit {
  transition: width 0.2s;
}

.users-list .btn {
  margin-right: 0.1rem;
}

.users-table tr {
  cursor: pointer;
}

.users-table .row-read-only {
  transition: background 0.15s;
  cursor: default;
}

.users-table .form-check-label {
  width: 100%;
  text-align: center;
  cursor: pointer;
}
.users-table .form-check-input {
  cursor: pointer;
}

.users-table .admin-row {
  cursor: default;
}

.user-action {
  transition: transform 0.2s;
  font-size: 2em;
  margin-top: 5px;
}
.user-action:hover {
  transform: scale(1.4);
}

/* User edit */
.user-edit {
  display: inline-block;
  width: 0%;
}

.edit-visible .users-list {
  transition: width, margin-right 0.2s;
  margin-right: 10px;
  width: 60%;
}

.edit-visible .user-edit {
  transition: width 0.2s;
  width: 40%;
}

.edit-password span {
  cursor: pointer;
}

/* Add user  */
.adduser-password span {
  cursor: pointer;
}

/* .card-block.card-block__table {
  padding: 0;
  overflow-x: auto;
} */
</style>

<style lang="scss" scoped>
  .sorting-filter-caption-in-users{
    margin-top: -1.4rem;
  }
.no-bis-id {
  color: red;
  font-size: 1.2em;
}
.hack-input-off-autocomplete {
  width: 0px !important;
  overflow: hidden !important;
}
.user-action-disabled {
  cursor: not-allowed;
}
.fix-overflowed-table {
  word-break: break-all;
  word-wrap: break-word;
}
.users-word-break {
  word-wrap: break-word;
  word-break: break-all;
  max-width: 300px;
}
.creater-row {
  color: grey;
  font-style: italic;
}
</style>
