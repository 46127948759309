var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wlan-form-tab-input-group radius-section"},[(_vm.config.label)?_c('h3',{staticClass:"wlan-form-tab-input-group-heading"},[_vm._v(" "+_vm._s(_vm.config.label)+" ")]):_vm._e(),_vm._l((_vm.config.inputs),function(input){return _c('div',{key:input.id,staticClass:"wlan-form-tab-input-group"},[(input.inputType === 'select' && input.name === 'security.aaa.radius_8021x_profile')?[_c('WSelect',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.isRadius_8021x_profileRequired ? 'required' : ''),expression:"isRadius_8021x_profileRequired ? 'required' : ''"}],attrs:{"data-vv-scope":"radius_8021x_profile","withInitialOption":"","options":_vm.radius_8021x_profileSelectOptions,"label":input.label,"name":input.name,"id":input.name,"value":_vm.formState[input.name],"disabled":input.isDisabled,"data-vv-validate-on":"input","data-vv-as":input.label,"errorText":_vm.errors.first(input.name)},on:{"input":function($event){return _vm.handleSelect(input.name, $event)}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('v-popover',{attrs:{"trigger":"hover","placement":"top-center","popoverClass":"wlan-form-popover","popperOptions":{
              modifiers: {
                preventOverflow: {
                  boundariesElement: 'window'
                }
              }
            }}},[_c('i',{staticClass:"fa fa-info-circle wlan-form-info-icon"}),_c('template',{slot:"popover"},[_c('i18n',{attrs:{"path":"wlan.inputInfo.radius_8021x_profile","tag":"span"},scopedSlots:_vm._u([{key:"here",fn:function(){return [_c('router-link',{attrs:{"to":{ name: 'Radius' },"title":"AAA","target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t('general.here'))+" ")])]},proxy:true}],null,true)})],1)],2)]},proxy:true}],null,true)})]:(input.inputType === 'select' && input.name === 'security.aaa.radius_accounting_profile')?[_c('WSelect',{directives:[{name:"validate",rawName:"v-validate",value:(input.validateRules),expression:"input.validateRules"}],attrs:{"withInitialOption":"","options":input.selectOptions,"label":input.label,"name":input.name,"id":input.name,"value":_vm.formState[input.name],"disabled":input.isDisabled,"data-vv-validate-on":"input","data-vv-as":input.label,"errorText":_vm.errors.first(input.name)},on:{"input":function($event){return _vm.handleSelect(input.name, $event)}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('v-popover',{attrs:{"trigger":"hover","placement":"top-center","popoverClass":"wlan-form-popover","popperOptions":{
              modifiers: {
                preventOverflow: {
                  boundariesElement: 'window'
                }
              }
            }}},[_c('i',{staticClass:"fa fa-info-circle wlan-form-info-icon"}),_c('template',{slot:"popover"},[_c('i18n',{attrs:{"path":"wlan.inputInfo.radius_8021x_profile","tag":"span"},scopedSlots:_vm._u([{key:"here",fn:function(){return [_c('router-link',{attrs:{"to":{ name: 'Radius' },"title":"AAA","target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t('general.here'))+" ")])]},proxy:true}],null,true)})],1)],2)]},proxy:true}],null,true)})]:[_c('WInput',{directives:[{name:"validate",rawName:"v-validate",value:(input.validateRules),expression:"input.validateRules"}],key:input.id,attrs:{"type":input.inputType,"label":input.label,"value":_vm.formState[input.name],"id":input.name,"name":input.name,"placeholder":input.placeholder,"disabled":input.isDisabled,"data-vv-validate-on":"input","data-vv-as":input.label,"errorText":_vm.errors.first(input.name),"autocomplete":"one-time-code"},on:{"input":function($event){return _vm.handleInput(input, $event)}}})]],2)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }