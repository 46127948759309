// По умолчанию поле должно быть заполнено минимальным числом, которое ещё не использовалось в качестве идентификатора.
// Минимальное значение можно получить  вызвав метод GET / api / v1 / profiles / wlan, сравнить значения атрибута wlan_id
// и взять наименьшее неиспользуемое число от 1 до 4096.
const allowedRange = [1, 4096];

function getAllowedWlanId(bookedWlanIds) {
  const [startValue, endValue] = allowedRange;

  let resultValue = startValue;

  for (let currentValue = startValue; currentValue < endValue; currentValue++) {
    if (!bookedWlanIds.includes(currentValue)) {
      resultValue = currentValue;
      return resultValue; // Возвращаем первый свободный ID
    }
  }

  // если все заняты, то возвращаем пустую строку
  return '';
}

export {
  getAllowedWlanId
};
