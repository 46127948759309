<template>
  <ul v-if="isEnabled">
    <li v-for="(content, index) in cellContent" :key="index">
      {{ content }}
    </li>
  </ul>
  <span v-else>
    {{ props.formattedRow[props.column.field] ? props.formattedRow[props.column.field] : '-' }}
  </span>
</template>

<script>
/**
 * компонент - ячейка security_6.
 * компонент локальный: используется только в WlanTable.vue
 * @component
 */

/* eslint-disable camelcase */

export default {
  name: 'Security6GhzCell',
  components: {},
  props: {
    props: {
      type: Object,
      required: true
    }
  },
  computed: {
    isEnabled() {
      const is6GhzEnabled = this.props.row.nativeData.status_6ghz_enabled;

      return is6GhzEnabled;
    },
    securityTypeSubstring() {
      let substring = this.$t('wlan.tableCell.security_type.wpa3');

      if (substring) {
        substring = `[${substring}]`;
      }

      return substring;
    },
    encryptionTypeSubstring() {
      let substring = '';

      switch (this.props.row.nativeData.encryption_type) {
        case 'ccmp':
          substring = 'CCMP';
          break;

        case 'ccmp256':
          substring = 'CCMP-256';
          break;

        case 'gcmp256':
          substring = 'GCMP-256';
          break;

        default:
          substring = '';
      }

      if (substring) {
        substring = `[${substring}]`;
      }

      return substring;
    },
    mabRadiusProfileSubstring() {
      let substring = '';

      if (this.props.row.nativeData.mab_radius_profile !== 'not_configured') {
        substring = this.$t('wlan.tableCell.mab_radius_profile');
      }

      if (substring) {
        substring = `[${substring}]`;
      }

      return substring;
    },
    webAuthProfileSubstring() {
      let substring = '';

      if (this.props.row.nativeData.web_auth_profile !== 'not_configured') {
        substring = this.$t('wlan.tableCell.web_auth_profile');
      }

      if (substring) {
        substring = `[${substring}]`;
      }

      return substring;
    },
    supportPmfSubstring() {
      let substring = '';

      switch (this.props.row.nativeData.support_pmf) {
        case 'optional':
        case 'mandatory':
          substring = this.$t('wlan.tableCell.support_pmf');
          break;

        default:
          substring = '';
      }

      if (substring) {
        substring = `[${substring}]`;
      }

      return substring;
    },
    fastRoamingEnabledSubstring() {
      let substring = '';

      if (this.props.row.nativeData.fast_roaming_enabled) {
        substring = this.$t('wlan.tableCell.fast_roaming_enabled');
      }

      if (substring) {
        substring = `[${substring}]`;
      }

      return substring;
    },
    param821x256Substring() {
      let substring = '';

      if (this.props.row.nativeData.param8021x_256_enabled) {
        substring = '802.1x-SHA256';
      }

      if (substring) {
        substring = `[${substring}]`;
      }

      return substring;
    },
    saeEnabledSubstring() {
      let substring = '';

      if (this.props.row.nativeData.sae_enabled) {
        substring = 'SAE';
      }

      if (substring) {
        substring = `[${substring}]`;
      }

      return substring;
    },
    ftSaeSubstring() {
      let substring = '';

      if (this.props.row.nativeData.ft_sae_enabled) {
        substring = 'FT+SAE';
      }

      if (substring) {
        substring = `[${substring}]`;
      }

      return substring;
    },
    ft8021xSubstring() {
      let substring = '';

      if (this.props.row.nativeData.ft_8021x_enabled) {
        substring = 'FT+802.1x';
      }

      if (substring) {
        substring = `[${substring}]`;
      }

      return substring;
    },
    suiteb192EnabledSubstring() {
      let substring = '';

      if (this.props.row.nativeData.suite_b_192_enabled) {
        substring = 'SUITE-B-192';
      }

      if (substring) {
        substring = `[${substring}]`;
      }

      return substring;
    },
    oweSubstring() {
      let substring = '';

      if (this.props.row.nativeData.owe_enabled) {
        substring = 'OWE';
      }

      if (substring) {
        substring = `[${substring}]`;
      }

      return substring;
    },
    cellContent() {
      /*
        [правило для security_type]
        [правило для encryption_type]
        [правило для mab_radius_profile]
        [правило для web_profile]
        [правило для support_pmf]
        [правило для fast_roaming_enabled]
        [правило для 802.1x_256_enabled]
        [правило для sae_enabled]
        [правило для ft_sae_enabled]
        [правило для ft_8021x_enabled]
        [правило для suite_b_192_enabled]
        [правило для owe_enabled]
      */

      const content = [
        this.securityTypeSubstring,
        this.encryptionTypeSubstring,
        this.mabRadiusProfileSubstring,
        this.webAuthProfileSubstring,
        this.supportPmfSubstring,
        this.fastRoamingEnabledSubstring,
        this.param821x256Substring,
        this.saeEnabledSubstring,
        this.ftSaeSubstring,
        this.ft8021xSubstring,
        this.suiteb192EnabledSubstring,
        this.oweSubstring
      ];

      return content;
    }
  },
  methods: {
  }
};
</script>

<style lang="css" scoped>
ul {
  display: flex;
  flex-wrap: wrap;
}
ul, li {
  margin: 0;
  padding: 0;
}
</style>
