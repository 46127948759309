/* eslint-disable camelcase */
/* eslint-disable consistent-return */

import store from '@/store';
import { AXIOS_INSTANCE, GET_WLANS_URL } from '../config';
import {
  getFetcherUrl,
  wlanProfileDataTypesConverterForFrontend
} from '../utils';

/**
 * @typedef {import('../types.js').WlanProfile_FRONTEND} WlanProfile_FRONTEND
 * @typedef {import('../types.js').WlanProfile_BACKEND} WlanProfile_BACKEND
 */

// doc https://wimarksystems.atlassian.net/wiki/spaces/Wimark/pages/276398100/FT23.9.2+Backend
// swagger https://gitlab.wimark.com/backend/grpc-contracts/-/blob/master/gen/go/gcontrollerconfig_service/service/v1/gcontrollerconfig.swagger.json?ref_type=heads
/**
 * @typedef {Object} ResponseData_BACKEND
 * @property {string} total - Общее количество записей.
 * @property {string} limit - Количество записей.
 * @property {string} offset - Номер записи, с которой начинается вывод.
 * @property {WlanProfile_BACKEND[]} wlan_profiles - Массив объектов, содержащий wlans.
 */

/**
 * Функция-фетчер для получения wlans.
 *
 * @param {Object} settings - Настройки запроса.
 * @param {number} settings.limit - Количество записей wlans, которое необходимо запросить.
 * @param {number} settings.offset - номер записи(идентификатор), с которой необходимо запросить кол-во записей.
 * @param {string} settings.filterByName - фильтрация по столбцу name.
 * @param {string} settings.filterById - фильтрация по идентификатору.
 * @param {'asc' | 'desc'} settings.sortOrderBy - как сортировать.
 * @param {'id' | 'name'} settings.sortValueBy - сотритровка по полю.
 * @returns {Promise<ResponseData_BACKEND>} - Обещание с данными ответа.
 * @throws {Error} - Ошибка при выполнении запроса.
 */
const getWlansFetcher = async (settings = {}) => {
  const {
    limit,
    offset,
    filterById,
    filterByName,
    sortOrderBy,
    sortValueBy
  } = settings;

  const url = getFetcherUrl(GET_WLANS_URL, [
    {
      name: 'limit',
      value: limit
    },
    {
      name: 'offset',
      value: offset
    },
    {
      name: 'id',
      value: filterById
    },
    {
      name: 'name',
      value: filterByName
    },
    {
      name: 'order',
      value: sortOrderBy
    },
    {
      name: 'sort',
      value: sortValueBy
    }
  ]);

  try {
    const response = await AXIOS_INSTANCE.get(url);

    const { data } = response;

    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * @typedef {Object} ResponseData_FRONTEND
 * @property {number} total - Общее количество записей.
 * @property {number} limit - Количество записей.
 * @property {number} offset - Номер записи, с которой начинается вывод.
 * @property {WlanProfile_FRONTEND[]} wlan_profiles - Массив объектов, содержащих информацию о wlans.
 */

/**
 * фукнция-обработчик фетчера getWlansFetcher.
 * выполняет побочные действия и обрабатывает тело запроса.
 *
 * @param {Object} settings - Настройки запроса.
 * @param {number} settings.limit - Количество записей о профилях, которое необходимо запросить.
 * @param {number} settings.offset - номер записи(идентификатор), с которой необходимо запросить кол-во записей.
 * @param {number} settings.filterById - фильтрация по идентификатору.
 * @param {string} settings.filterByName - фильтрация по столбцу name.
 * @param {'asc' | 'desc'} settings.sortOrderBy - как сортировать.
 * @param {'id' | 'name'} settings.sortValueBy - сотритровка по полю.
 * @param {Object} [options] - Дополнительные опции запроса.
 * @param {Function} [options.onLoading] - Callback при начале выполнения запроса.
 * @param {Function} [options.onSuccess] - Callback при успешном выполнении запроса.
 * @param {Function} [options.onError] - Callback при ошибке выполнения запроса.
 * @returns {Promise<ResponseData_FRONTEND>} - Обещание с данными ответа.
 * @throws {Error} - Ошибка при выполнении запроса.
 */

const getWlans = async (settings, options = {}) => {
  const {
    onLoading,
    onSuccess,
    onError
  } = options;

  try {
    if (onLoading) {
      onLoading();
    }

    store.commit('setLoading');

    const response = await getWlansFetcher(settings);
    const {
      total,
      limit,
      offset,
      wlan_profiles
    } = response;

    // конвертация типов в соответствии с ResponseData_FRONTEND
    const data = {
      total: Number(total),
      limit: Number(limit),
      offset: Number(offset),
      wlan_profiles: wlan_profiles.map(wlan_profile => wlanProfileDataTypesConverterForFrontend(wlan_profile))
    };

    store.commit('setSuccess');
    onSuccess(data);
    return Promise.resolve(data);
  } catch (error) {
    if (onError) {
      onError(error);
    }
    store.commit('setError', error);
  }
};

export default getWlans;
