<template>
  <WlanForm
    :formConfig="formConfig"
    :forbiddenWlanNames="forbiddenWlanNames"
    :forbiddenWlanIds="forbiddenWlanIds"
    @submit="handleSubmit"
  >
    <template v-slot:control-buttons>
      <slot name="form-control-buttons"></slot>
    </template>
    </WlanForm>
</template>

<script>
/**
 * компонент настроенной формы WlanForm через generateFormConfig для создания нового wlan.
 * ! Компонент локальный, используется только в CreateWlanModal.vue
 * @component
 */

import {
  WlanForm,
  generateFormConfig,
  getAllowedWlanId
} from '../../features';

export default {
  name: 'CreateWlanForm',
  components: {
    WlanForm
  },
  props: {
    forbiddenWlanNames: {
      type: Array,
      default: () => []
    },
    forbiddenWlanIds: {
      type: Array,
      default: () => []
    },
    suggestedMabRadiusProfiles: {
      type: Array,
      default: () => []
    },
    suggestedWebAuthRedirectNames: {
      type: Array,
      default: () => []
    },
    suggestedRadius8021xProfileNames: {
      type: Array,
      default: () => []
    },
    suggestedRadiusAccountingProfileNames: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
    handleSubmit(formValues) {
      this.$emit('submit', formValues);
    }
  },
  created() {
    const mabRadiusProfileSuggestedOptions = this.suggestedMabRadiusProfiles
      .map(name => ({ display: name, data: name }));

    const webAuthRedirectNamesSeggestedOptions = this.suggestedWebAuthRedirectNames
      .map(name => ({ display: name, data: name }));

    const radius8021xProfileSuggestedOptions = this.suggestedRadius8021xProfileNames
      .map(name => ({ display: name, data: name }));

    const radiusAccountingProfileSuggestedOptions = this.suggestedRadiusAccountingProfileNames
      .map(name => ({ display: name, data: name }));

    // задаем дефолтные значения для каждого поля
    const formConfig = generateFormConfig({
      i18nInstance: this.$i18n,
      initialValues: {
        general: {
          wlan_id: {
            defaultValue: getAllowedWlanId(this.forbiddenWlanIds)
          }
        },
        security: {
          layer2: {
            mab_radius_profile: {
              selectOptions: mabRadiusProfileSuggestedOptions
            },
            psk_key: {
              isRequired: true // Параметр обязателен для заполнения при добавлении нового профиля.
            }
          },
          layer3: {
            web_auth_profile: {
              selectOptions: webAuthRedirectNamesSeggestedOptions
            }
          },
          aaa: {
            radius_8021x_profile: {
              selectOptions: radius8021xProfileSuggestedOptions
            },
            radius_accounting_profile: {
              selectOptions: radiusAccountingProfileSuggestedOptions
            }
          }
        }
      }
    });

    this.formConfig = formConfig;
  }
};
</script>

<style lang="css" scoped></style>
