/* eslint-disable consistent-return */

import store from '@/store';
import { AXIOS_INSTANCE, POST_UPDATE_WLAN_PROFILE_URL } from '../config';
import {
  wlanProfileDataTypesConverterForBackend
} from '../utils';

// doc https://wimarksystems.atlassian.net/wiki/spaces/Wimark/pages/276758534/FT23.9.3+Backend
// swagger https://gitlab.wimark.com/backend/grpc-contracts/-/blob/master/gen/go/gcontrollerconfig_service/service/v1/gcontrollerconfig.swagger.json?ref_type=heads

/**
 * @typedef {import('../types.js').UpdateWlanProfileRequestData} UpdateWlanProfileRequestData
 */

/**
 * Функция-фетчер для обновления существующего wlan profile.
 *
 * @param {UpdateWlanProfileRequestData} requestParams - Настройки wlan Profile.
 * @returns {Promise<Object>} - Обещание с данными ответа.
 * @throws {Error} - Ошибка при выполнении запроса.
 */
const postWlanFetcher = async (requestParams) => {
  const payload = {
    wlan_profile: wlanProfileDataTypesConverterForBackend(requestParams)
  };

  try {
    const response = await AXIOS_INSTANCE.post(POST_UPDATE_WLAN_PROFILE_URL, payload);

    const { data } = response;

    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * фукнция-обработчик фетчера postWlanFetcher.
 * выполняет побочные действия и обрабатывает тело запроса.
 *
 * @param {UpdateWlanProfileRequestData} requestParams - Настройки wlan Profile.
 * @param {Object} [options] - Дополнительные опции запроса.
 * @param {Function} [options.onLoading] - Callback при начале выполнения запроса.
 * @param {Function} [options.onSuccess] - Callback при успешном выполнении запроса.
 * @param {Function} [options.onError] - Callback при ошибке выполнения запроса.
 * @param {string} [options.successMessage] - Сообщение об успешном выполнении запроса.
 * @param {string} [options.errorMessage] - Сообщение об ошибке выполнении запроса.
 * @returns {Promise<void>} - Обещание с данными ответа.
 * @throws {Error} - Ошибка при выполнении запроса.
 */
const updateWlan = async (requestParams, options = {}) => {
  const {
    onLoading,
    onSuccess,
    onError,
    successMessage,
    errorMessage
  } = options;

  try {
    if (onLoading) {
      onLoading();
    }

    store.commit('setLoading');

    const response = await postWlanFetcher(requestParams);

    store.commit('setSuccess', successMessage);
    onSuccess();
    return Promise.resolve();
  } catch (error) {
    if (onError) {
      onError(error);
    }
    store.commit('setError', errorMessage);
  }
};

export default updateWlan;
