import { render, staticRenderFns } from "./NasIdSettingsSection.vue?vue&type=template&id=1c7a8190&scoped=true&"
import script from "./NasIdSettingsSection.vue?vue&type=script&lang=js&"
export * from "./NasIdSettingsSection.vue?vue&type=script&lang=js&"
import style0 from "./NasIdSettingsSection.vue?vue&type=style&index=0&id=1c7a8190&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c7a8190",
  null
  
)

export default component.exports