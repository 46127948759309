/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/**
 * конфигурация таблицы WlanTable.vue
*/

/**
 * генератор колонок таблицы
 * https://xaksis.github.io/vue-good-table/guide/configuration/column-options.html#label
*/

import {
  getSelectedRowValue
} from './utils';

function generateTableColumns({ i18nInstance, hiddenColumnRules, additionalRules }) {
  const $t = i18nInstance.t.bind(i18nInstance);

  return [
    {
      label: $t('wlan.tableHeading.id'),
      field: 'id',
      width: 'auto',
      type: 'text',
      tdClass: '',
      hidden: hiddenColumnRules.id
    },
    {
      label: $t('wlan.tableHeading.status_ssid_enabled'),
      field: 'status_ssid_enabled',
      width: 'auto',
      type: 'text',
      tdClass: '',
      hidden: hiddenColumnRules.status_ssid_enabled
    },
    {
      label: $t('wlan.tableHeading.wlanId'),
      field: 'wlan_id',
      width: 'auto',
      type: 'text',
      tdClass: '',
      hidden: hiddenColumnRules.wlan_id
    },
    {
      label: $t('wlan.tableHeading.name'),
      field: 'name',
      width: 'auto',
      type: 'text',
      tdClass: '',
      hidden: hiddenColumnRules.name
    },
    {
      label: $t('wlan.tableHeading.ssid'),
      field: 'ssid',
      width: 'auto',
      type: 'text',
      tdClass: '',
      hidden: hiddenColumnRules.ssid
    },
    {
      label: $t('wlan.tableHeading.24_5Ghz'),
      field: 'security_24_5',
      width: 'auto',
      type: 'text',
      tdClass: '',
      sortable: false,
      hidden: hiddenColumnRules.security_24_5
    },
    {
      label: $t('wlan.tableHeading.6Ghz'),
      field: 'security_6',
      width: 'auto',
      type: 'text',
      sortable: false,
      hidden: hiddenColumnRules.security_6
      // visibilityDropdownSettings: {
      //   hidden: true
      // }
    }
  ];
}

/**
 * генератор строк таблицы
 * https://xaksis.github.io/vue-good-table/guide/configuration/#rows
 * https://xaksis.github.io/vue-good-table/guide/advanced/grouped-table.html#customizing-header-row
*/
function generateTableRows({ wlansData, selectedWlanIds }) {
  return wlansData.map(wlan => {
    const {
      id,
      created_at,
      updated_at,
      name,
      wlan_id,
      ssid,
      broadcast_ssid_enabled,
      status_24ghz_enabled,
      status_5ghz_enabled,
      status_6ghz_enabled,
      status_ssid_enabled,
      security_type,
      encryption_type,
      support_pmf,
      param80211w_query_attempts,
      param80211w_retry_timeout,
      fast_roaming_enabled,
      ft_over_ds_enabled,
      param80211r_reassociation_timeout,
      mab_radius_profile,
      psk_enabled,
      psk_256_enabled,
      param8021x_enabled,
      param8021x_256_enabled,
      sae_enabled,
      ft_sae_enabled,
      ft_8021x_enabled,
      ft_psk_enabled,
      suite_b_192_enabled,
      owe_enabled,
      psk_format,
      psk_type,
      psk_key,
      owe_wlan_id,
      sae_anti_clogging_threshold,
      sae_max_retries,
      sae_password_element,
      web_auth_profile,
      radius_8021x_profile,
      radius_accounting_profile,
      okc_enabled,
      param80211ax_enabled,
      neighbor_report_enabled,
      bss_fast_transition_enabled,
      nas_id_1,
      nas_id_1_custom,
      nas_id_2,
      nas_id_2_custom,
      nas_id_3,
      nas_id_3_custom
    } = wlan;

    return {
      // nativeData - хранит неформатированные данные. Нужны, чтобы удобно эмитить их по событию для дальнейших манипуляций.
      nativeData: wlan,
      id,
      status_ssid_enabled,
      wlan_id,
      name,
      ssid,
      security_24_5: null,
      security_6: null,
      // можно ли выбирать строку чекбоксом
      // vgtDisabled: false,
      // выбрана ли строка в чекбоксе исходя из стейта, который контролирует выбранные строки
      vgtSelected: getSelectedRowValue(wlan_id, selectedWlanIds)
    };
  });
}

export {
  generateTableColumns,
  generateTableRows
};
