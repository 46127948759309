/* eslint-disable no-unused-vars */

/**
 * Локальные валидаторы vee-validate для компонента WlanForm.
 */

import wlanApi from '../../../api';

// Функция для проверки уникальности имени профиля на сервере
// ! работает с дебаунcом через директиву data-vv-delay
const isUniqueProfileNameByApiRequest = async nameToCheck => {
  let result;

  await wlanApi.checkIsUniqueWlanName(nameToCheck, {
    onSuccess: (data) => {
      result = false;
    },
    onError: (error) => {
      result = true;
    }
  });

  return result;
};

// Функция для проверки уникальности wlan_id на сервере
// ! работает с дебаунcом через директиву data-vv-delay
const isUniqueWlanIdByApiRequest = async idToCheck => {
  let result;

  await wlanApi.checkIsUniqueWlanId(idToCheck, {
    onSuccess: (data) => {
      result = false;
    },
    onError: (error) => {
      result = true;
    }
  });

  return result;
};

const getWlanFormValidators = (i18nInstance, validator) => ({
  uniqueProfileName: (forbiddenProfileNames) => ({
    getMessage: field => {
      const message = i18nInstance.t('wlan.inputError.uniqueProfileName');
      return message;
    },
    validate: async value => {
      // 1. сначала проверять из статического списка
      // 2. потом если проверка 1 вернула false - дополнительно проверяем - вызываем проверку через запрос к апи
      // ступенчатая проверка нужна, чтобы уменьшить кол-во запросов к бэку

      // 1. проверка из статического списка
      const isUniqueFromStaticList = forbiddenProfileNames
        .map(value => value.toLocaleLowerCase())
        .includes(value.toLocaleLowerCase());

      if (isUniqueFromStaticList) {
        return false;
      }

      // 2. проверка через запрос к бэку
      const isUniqueByApiRequest = await isUniqueProfileNameByApiRequest(value);

      return isUniqueByApiRequest;
    }
  }),
  profileNameMask: () => ({
    getMessage: field => {
      const message = i18nInstance.t('wlan.inputError.profileNameMask');
      return message;
    },
    validate: value => {
      const customValidationRegex = /^[a-zA-Zа-яА-Я0-9!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]+$/;
      // Проверяем, чтобы не было пробелов в начале и в конце
      if (value.trim() !== value) {
        return false;
      }
      // Проверяем, чтобы строка не содержала несколько слов, разделенных пробелом
      if (/\s/.test(value)) {
        return false;
      }
      // Проверяем соответствие регулярному выражению
      return customValidationRegex.test(value);
    }
  }),
  uniqueWlanId: (forbiddenWlanIds) => ({
    getMessage: field => {
      const message = i18nInstance.t('wlan.inputError.uniqueWlanId');
      return message;
    },
    validate: async value => {
      // 1. сначала проверять из статического списка
      // 2. потом если проверка 1 вернула false - дополнительно проверяем - вызываем проверку через запрос к апи
      // ступенчатая проверка нужна, чтобы уменьшить кол-во запросов к бэку

      // 1. проверка из статического списка
      const isUniqueFromStaticList = forbiddenWlanIds
        .map(value => String(value).toLocaleLowerCase())
        .includes(String(value).toLocaleLowerCase());

      if (isUniqueFromStaticList) {
        return false;
      }

      // 2. проверка через запрос к бэку
      const isUniqueByApiRequest = await isUniqueWlanIdByApiRequest(value);

      return isUniqueByApiRequest;
    }
  }),
  ssidMask: () => ({
    getMessage: field => {
      const message = i18nInstance.t('wlan.inputError.ssidMask');
      return message;
    },
    validate(value) {
      const customValidationRegex = /^[A-Za-z0-9!"#$%&'()*+,\-./:;<=>?@[\]^_`{|}~ ]+$/;

      // Проверяем, чтобы не было пробелов в начале и в конце
      if (value.trim() !== value) {
        return false;
      }
      // Проверяем, чтобы строка может содержать несколько слов, разделенных пробелом
      if (/\s/.test(value)) {
        return true;
      }

      // Проверяем соответствие регулярному выражению
      return customValidationRegex.test(value);
    }
  }),
  nasMask: () => ({
    getMessage: field => {
      const message = i18nInstance.t('wlan.inputError.nasMask');
      return message;
    },
    validate(value) {
      const customValidationRegex = /^[A-Za-z0-9!"#$%&'()*+,\-./:;<=>?@[\]^_`{|}~]+$/;

      // Проверяем, чтобы не было пробелов в начале и в конце
      if (value.trim() !== value) {
        return false;
      }

      // Проверяем соответствие регулярному выражению
      return customValidationRegex.test(value);
    }
  }),
  pskKeyASCIIFormatMask: () => ({
    getMessage: field => {
      const message = i18nInstance.t('wlan.inputError.psk_key.open.ascii');
      return message;
    },
    validate(value) {
      // Проверяем наличие пробелов в начале и в конце
      if (value.trim() !== value) {
        return false;
      }

      // Проверяем длину строки
      if (value.length < 8 || value.length > 63) {
        return false;
      }

      // Проверяем наличие хотя бы одной цифры, строчной и заглавной буквы
      const hasNumber = /[0-9]/.test(value);
      const hasLowerCase = /[a-z]/.test(value);
      const hasUpperCase = /[A-Z]/.test(value);

      return hasNumber && hasLowerCase && hasUpperCase;
    }
  }),
  pskKeyHEXFormatMask: () => ({
    getMessage: field => {
      const message = i18nInstance.t('wlan.inputError.psk_key.open.hex');
      return message;
    },
    validate(value) {
      // Проверяем наличие пробелов в начале и в конце
      if (value.trim() !== value) {
        return false;
      }

      // Проверяем длину строки
      if (value.length !== 64) {
        return false;
      }

      // Проверяем наличие хотя бы одной цифры, строчной и заглавной буквы (в диапазоне a-f и A-F)
      const hasNumber = /[0-9]/.test(value);
      const hasLowerCase = /[a-f]/.test(value);
      const hasUpperCase = /[A-F]/.test(value);

      return hasNumber && hasLowerCase && hasUpperCase;
    }
  }),
  pskKeyEncryptedFormatMask: () => ({
    getMessage: field => {
      const message = i18nInstance.t('wlan.inputError.psk_key.encrypted');
      return message;
    },
    validate(value) {
      // Проверяем, что строка начинается с "$6$"
      return value.startsWith('$6$');
    }
  })
});

export {
  getWlanFormValidators
};
