// Задаем имена строк, которые нужно трансформировать перед отображением
// Информация в этом блоке имеет вид вроде такого:
// container: wlc-backend-golang-1 wlc-backend-golang-1
// group: web web
// level: info info
// module: /wlc-backend-golang-1 /wlc-backend-golang-1
// service_name: wlc-backend-golang-1
// тут задаем например service_name
const stringNamesForEnableTransformation = ['service_name'];

// Задаем каким образом нужно преобразовать строки
// originalValue - значение строки прилетающий с бэка, которую нужно преобразовать
// transformedValue - во что преобразуем, перед отображенем в UI
const transformationRules = [
  { originalValue: 'wlc-gsnmp-service-1', transformedValue: 'gsnmp-service' },
  { originalValue: 'wlc-lbs-daemon-1', transformedValue: 'lbs-daemon' },
  { originalValue: 'wlc-loki-1', transformedValue: 'loki' },
  { originalValue: 'wlc-mongo-arbiter-1', transformedValue: 'mongo-arbiter' },
  { originalValue: 'wlc-mongo-connector-golang-1', transformedValue: 'mongo-connector-golang' },
  { originalValue: 'wlc-mongo-fake-1', transformedValue: 'mongo-fake' },
  { originalValue: 'wlc-mongo-ha-1', transformedValue: 'mongo-ha' },
  { originalValue: 'wlc-nginx-cors-1', transformedValue: 'nginx-cors' },
  { originalValue: 'wlc-nginx-proxy-ssl-1', transformedValue: 'nginx-proxy-ssl' },
  { originalValue: 'wlc-statdaemon-golang-1', transformedValue: 'statdaemon-golang' },
  { originalValue: 'wlc-analytics-mw-1', transformedValue: 'analytics-mw' },
  { originalValue: 'wlc-backend-golang-1', transformedValue: 'backend-golang' },
  { originalValue: 'wlc-configurer-golang-1', transformedValue: 'configurer-golang' },
  { originalValue: 'wlc-gauth-service-1', transformedValue: 'gauth-service' },
  { originalValue: 'wlc-gproxy-service-1', transformedValue: 'gproxy-service' }
];

export { stringNamesForEnableTransformation, transformationRules };
