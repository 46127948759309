<template>
  <div class="wlan-form-tab-input-group web-auth-rule">
    <h3 v-if="config.label" class="wlan-form-tab-input-group-heading">
      {{ config.label }}
    </h3>
    <div v-for="input in config.inputs"
      class="wlan-form-tab-input-group"
      :key="input.id"
    >
      <template v-if="input.inputType === 'checkbox'">
        <WCheckbox
          :label="input.label"
          :name="input.name"
          :id="input.name"
          :checked="formState[input.name]"
          @change="handleCheckbox(input.name, $event)"
          :disabled="input.isDisabled"
          v-validate="input.validateRules"
          data-vv-validate-on="change"
          :data-vv-as="input.label"
          :errorText="errors.first(input.name)"
        />
      </template>
      <template v-else-if="input.inputType === 'select' && input.name === 'security.layer3.web_auth_profile'">
        <WSelect
          v-if="isWebAuthProfileVisible"
          data-vv-scope="web_auth_profile"
          withInitialOption
          :options="input.selectOptions"
          :label="input.label"
          :name="input.name"
          :id="input.name"
          :value="formState[input.name]"
          @input="handleSelect(input.name, $event)"
          :disabled="input.isDisabled"
          v-validate="input.validateRules"
          data-vv-validate-on="input"
          :data-vv-as="input.label"
          :errorText="errors.first(input.name)"
        >
          <template #icon>
            <v-popover
              trigger="hover"
              placement="top-center"
              popoverClass="wlan-form-popover"
              :popperOptions="{
                modifiers: {
                  preventOverflow: {
                    boundariesElement: 'window'
                  }
                }
              }">
              <i class="fa fa-info-circle wlan-form-info-icon" />
              <template slot="popover">
                <i18n path="wlan.inputInfo.web_auth_profile" tag="span">
                  <template #here>
                    <router-link
                      :to="{ name: 'GuestControl' }"
                      title="Web Auth"
                      target="_blank"
                    >
                      {{ $t('general.here') }}
                    </router-link>
                  </template>
                </i18n>
              </template>
            </v-popover>
          </template>
        </WSelect>
      </template>
      <template v-else>
        <WInput
          :key="input.id"
          :type="input.inputType"
          :label="input.label"
          :value="formState[input.name]"
          @input="handleInput(input, $event)"
          :id="input.name"
          :name="input.name"
          :placeholder="input.placeholder"
          :disabled="input.isDisabled"
          v-validate="input.validateRules"
          data-vv-validate-on="input"
          :data-vv-as="input.label"
          :errorText="errors.first(input.name)"
          autocomplete="one-time-code"
        />
      </template>
    </div>
  </div>
</template>

<script>
/**
 *
 * компонент секции таба security - layer3 - WebAuthRule.
 * Содержит разметку, правила рендеринга (в т.ч. рекурсивные), специфических настроек отображения
 * и параметров валидации отдельных секций(групп) инпутов в соответствии с конфигом
 * @component
 *
 */

/* eslint-disable camelcase */

export default {
  name: 'WebAuthRuleSection',
  inject: ['$validator'],
  components: {
  },
  props: {
    config: {
      type: Object,
      required: true
    },
    formState: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
    };
  },
  computed: {
    isWebAuthProfileVisible() {
      return this.formState['security.layer3.isWebAuthProfileEnabled'] === true;
    }
  },
  watch: {
    isWebAuthProfileVisible(newVal) {
      // при отключении web_auth_profile - сброс значений и ошибок валидации в дефолтное значение
      if (newVal === false) {
        this.handleResetWeb_auth_profile();
      }

      // при открытии web_auth_profile ставим значение пустую строку чтобы валидация required сработала, и необходмо было выбрать какое-то значение
      if (newVal === true) {
        this.$emit('update:inputValue', {
          inputName: 'security.layer3.web_auth_profile',
          value: ''
        });
      }
    }
  },
  methods: {
    handleCheckbox(inputName, event) {
      const isChecked = event.target.checked;
      this.$emit('update:inputValue', { inputName, value: isChecked });
    },
    handleSelect(inputName, value) {
      this.$emit('update:inputValue', { inputName, value });
    },
    handleInput(inputObj, value) {
      const { name, inputType } = inputObj;

      let correctFormatValue = value;

      // из-за особенностей архитектуры и невозможности использовать здесь v-modal, форматировать number приходится так
      if (inputType === 'number') {
        correctFormatValue = value === '' ? '' : Number(correctFormatValue);
      }

      this.$emit('update:inputValue', {
        inputName: name,
        value: correctFormatValue
      });
    },
    handleResetWeb_auth_profile() {
      this.$emit('update:inputValue', {
        inputName: 'security.layer3.web_auth_profile',
        value: 'not_configured'
      });

      // сброс состояния валидации с удаленных полей и ошибок в частности
      // https://vee-validate.logaretm.com/v2/examples/scopes.html
      // https://vee-validate.logaretm.com/v2/api/validator.html#methods
      const matcher = {
        scope: 'web_auth_profile',
        vmId: this.$validator.id
      };

      this.$validator.reset(matcher);
    }
  },
  mounted() {
  }
};
</script>

<style lang="css" scoped>
/* .wlan-form-tab-input-group.web-auth-rule{

} */
</style>
