<template>
  <FormWithTabs
    :formConfig="formConfig"
    @submit="handleSubmit"
  >
    <template v-slot:tab-content="{ tabContent, formState, handleInputFunc, currentTabName }">
      <WlanFormTab
        :tabContent="tabContent"
        :formState="formState"
        :currentTabName="currentTabName"
        @update:inputValue="handleInputFunc"
      />
    </template>
    <!-- кнопка сабмит из модалки -->
    <template v-slot:control-buttons>
      <slot name="control-buttons"></slot>
    </template>
  </FormWithTabs>
</template>

<script>
/**
 * локальный компонент формы с табами для форм в странице wlan.
 * Представляет собой обертку-интерфейс с настроенными разметкой, формой, генератором стейта формы и валидацей.
 * по FSD это слой feature https://feature-sliced.design/ru/docs/get-started/overview#layers
 * @component
 */

import {
  WlanFormTab
} from './components';

import {
  getWlanFormValidators
} from './validators';

export default {
  name: 'WlanForm',
  components: {
    WlanFormTab
  },
  props: {
    formConfig: {
      type: Array,
      required: true
    },
    forbiddenWlanNames: {
      type: Array,
      default: () => []
    },
    forbiddenWlanIds: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
    handleSubmit(formValues) {
      this.$emit('submit', formValues);
    },
    setWlanFormValidators() {
      const validator = this.$validator;

      const validators = getWlanFormValidators(this.$i18n, validator);

      const uniqueProfileName = validators.uniqueProfileName(this.forbiddenWlanNames);
      const uniqueWlanId = validators.uniqueWlanId(this.forbiddenWlanIds);
      const profileNameMask = validators.profileNameMask();
      const ssidMask = validators.ssidMask();
      const nasMask = validators.nasMask();
      const pskKeyASCIIFormatMask = validators.pskKeyASCIIFormatMask();
      const pskKeyHEXFormatMask = validators.pskKeyHEXFormatMask();
      const pskKeyEncryptedFormatMask = validators.pskKeyEncryptedFormatMask();

      validator.extend('uniqueProfileName', uniqueProfileName);
      validator.extend('profileNameMask', profileNameMask);
      validator.extend('uniqueWlanId', uniqueWlanId);
      validator.extend('ssidMask', ssidMask);
      validator.extend('nasMask', nasMask);
      validator.extend('pskKeyASCIIFormatMask', pskKeyASCIIFormatMask);
      validator.extend('pskKeyHEXFormatMask', pskKeyHEXFormatMask);
      validator.extend('pskKeyEncryptedFormatMask', pskKeyEncryptedFormatMask);
    }
  },
  created() {
    this.setWlanFormValidators();
  }
};
</script>

<style lang="css" scoped>
</style>
