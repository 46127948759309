<template>
  <i v-if="props.row.status_ssid_enabled" class="fa fa-arrow-circle-up fa-lg connected"></i>
  <i v-else class="fa fa-arrow-circle-down fa-lg disconnected"></i>
</template>

<script>
/**
 * компонент - ячейка status_ssid_enabled.
 * компонент локальный: используется только в WlanTable.vue
 * @component
 */

export default {
  name: 'StatusCell',
  components: {},
  props: {
    props: {
      type: Object,
      required: true
    }
  },
  methods: {
  }
};
</script>

<style lang="css" scoped>
.connected {
  color: var(--brand-success);
}

.disconnected {
  color: var(--brand-danger);
}
</style>
