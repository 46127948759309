/**
 * API-модуль для страницы wlan.
 * @module wlanApi
*/

import getWlans from './methods/getWlans';
import createWlan from './methods/createWlan';
import updateWlan from './methods/updateWlan';
import deleteWlans from './methods/deleteWlans';
import enableWlans from './methods/enableWlans';
import disableWlans from './methods/disableWlans';
import checkIsUniqueWlanName from './methods/checkIsUniqueWlanName';
import checkIsUniqueWlanId from './methods/checkIsUniqueWlanId';
import getRadiusGroups from './methods/getRadiusGroups';
import getRedirectRules from './methods/getRedirectRules';

const wlanApi = {
  getWlans,
  createWlan,
  updateWlan,
  deleteWlans,
  enableWlans,
  disableWlans,
  checkIsUniqueWlanName,
  checkIsUniqueWlanId,
  getRadiusGroups,
  getRedirectRules
};

export default wlanApi;
