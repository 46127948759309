// Модуль используется только в компонентах раздела Logs, для чтого чтобы преобразовывать строки перед выводом в UI

import { stringNamesForEnableTransformation, transformationRules } from './settingsForExpandedBlock';

/**
 * Трансформирует строку согласно правилам переданым в transformRules
 *
 * @param {string} stringForTransform - строка для преобразорвания
 * @param {Array} stringTransformationRules - массив с правилами для преобразования строк
 *
 * @return {string} - трансформированная строка или исходная строка (если не найдено правил для ее трансформирования)
 */
function transformString(stringForTransform, stringTransformationRules) {
  const ruleForString = stringTransformationRules.find((rule) => {
    return rule.originalValue === stringForTransform;
  });
  if (ruleForString) {
    return ruleForString.transformedValue;
  }
  return stringForTransform;
}

/**
 * Возвращает строку для отображения в раскрывающемся блоке в таблице с логами (трансформированную если нужно)
 *
 * @param {string} stringName  - имя этой строки, например 'service_name'
 * @param {string} stringValue - строка
 *
 * @return {string}  строка для отображения в раскрывающемся блоке в таблице с логами (трансформированая если необходимо)
 */
function getStringForRenderingInExpandedBlock(stringName, stringValue) {
  if (stringNamesForEnableTransformation.includes(stringName)) {
    return transformString(stringValue, transformationRules);
  }
  return stringValue;
}

export { getStringForRenderingInExpandedBlock };
