<template>
  <div
    v-if="isEncryptionTypeSectionVisible"
    class="wlan-form-tab-input-group encryption-type"
  >
    <h3 v-if="config.label" class="wlan-form-tab-input-group-heading">
      {{ config.label }}
    </h3>
    <div class="wlan-form-tab-input-group">
      <template v-for="input in config.inputs">
        <template v-if="input.inputType === 'radio'">
          <template v-if="input.radioValue === 'ccmp256'">
            <WRadio
              v-if="isCcmp256RadioVisible"
              :key="input.id"
              data-vv-scope="encryption_type"
              :label="input.label"
              :name="input.name"
              :id="input.id"
              :value="input.radioValue"
              @input="handleRadio(input, $event)"
              :checked="formState[input.name] === input.radioValue"
            />
          </template>
          <WRadio
            v-else
            :key="input.id"
            data-vv-scope="encryption_type"
            :label="input.label"
            :name="input.name"
            :id="input.id"
            :value="input.radioValue"
            @input="handleRadio(input, $event)"
            :checked="formState[input.name] === input.radioValue"
          />
        </template>
        <template v-else>
          <WInput
            :key="input.id"
            :type="input.inputType"
            :label="input.label"
            :value="formState[input.name]"
            @input="handleInput(input, $event)"
            :id="input.name"
            :name="input.name"
            :placeholder="input.placeholder"
            :disabled="input.isDisabled"
            v-validate="input.validateRules"
            data-vv-validate-on="input"
            :data-vv-as="input.label"
            :errorText="errors.first(input.name)"
            autocomplete="one-time-code"
          />
        </template>
      </template>
    </div>

  </div>
</template>

<script>
/**
 *
 * компонент секции таба security - encryption_type.
 * Содержит разметку, правила рендеринга (в т.ч. рекурсивные), специфических настроек отображения
 * и параметров валидации отдельных секций(групп) инпутов в соответствии с конфигом
 * @component
 *
 */

/* eslint-disable camelcase */

export default {
  name: 'EncryptionTypeSection',
  inject: ['$validator'],
  components: {
  },
  props: {
    config: {
      type: Object,
      required: true
    },
    formState: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
    };
  },
  computed: {
    currentSecurityType() {
      return this.formState['security.layer2.security_type'];
    },
    // https://wimarksystems.atlassian.net/wiki/spaces/Wimark/pages/223609094/FT23.9.1+front#%5BinlineExtension%5D%D0%92%D0%BA%D0%BB%D0%B0%D0%B4%D0%BA%D0%B0-%D0%91%D0%B5%D0%B7%D0%BE%D0%BF%D0%B0%D1%81%D0%BD%D0%BE%D1%81%D1%82%D1%8C-(%D1%80%D1%83%D1%81%D1%81%D0%BA%D0%B0%D1%8F-%D0%BB%D0%BE%D0%BA%D0%B0%D0%BB%D1%8C)-%5C-Security-(%D0%B0%D0%BD%D0%B3%D0%BB%D0%B8%D0%B9%D1%81%D0%BA%D0%B0%D1%8F-%D0%BB%D0%BE%D0%BA%D0%B0%D0%BB%D1%8C)-%E2%86%92-%D0%A3%D1%80%D0%BE%D0%B2%D0%B5%D0%BD%D1%8C-2-(%D1%80%D1%83%D1%81%D1%81%D0%BA%D0%B0%D1%8F-%D0%BB%D0%BE%D0%BA%D0%B0%D0%BB%D1%8C)-%5C-Layer-2-(%D0%B0%D0%BD%D0%B3%D0%BB%D0%B8%D0%B9%D1%81%D0%BA%D0%B0%D1%8F-%D0%BB%D0%BE%D0%BA%D0%B0%D0%BB%D1%8C)
    // Секция должна быть недоступна(скрыта), если ранее пользователь выбрал Тип Безопасности - Открытая.
    isEncryptionTypeSectionVisible() {
      return this.currentSecurityType !== 'open';
    },
    // При выборе Тип безопасности - WPA3, переключатель CCMP-256 должен быть скрыт.
    isCcmp256RadioVisible() {
      return this.currentSecurityType !== 'wpa3';
    }
  },
  watch: {
    // при изменении security_type, сброс значений инпутов в дефолтное значение
    currentSecurityType(newVal, oldVal) {
      if (newVal === oldVal) {
        return;
      }

      this.handleResetEncryptionTypeRadio();
    }
  },
  methods: {
    handleRadio(inputObj, value) {
      const { name } = inputObj;

      this.$emit('update:inputValue', { inputName: name, value });
    },
    handleInput(inputObj, value) {
      const { name, inputType } = inputObj;

      let correctFormatValue = value;

      // из-за особенностей архитектуры и невозможности использовать здесь v-modal, форматировать number приходится так
      if (inputType === 'number') {
        correctFormatValue = value === '' ? '' : Number(correctFormatValue);
      }

      this.$emit('update:inputValue', {
        inputName: name,
        value: correctFormatValue
      });
    },
    handleResetEncryptionTypeRadio() {
      this.$emit('update:inputValue', {
        inputName: 'security.layer2.encryption_type',
        value: 'ccmp'
      });

      const matcher = {
        scope: 'encryption_type',
        vmId: this.$validator.id
      };

      this.$validator.reset(matcher);
    }
  },
  mounted() {
  }
};
</script>

<style lang="css" scoped>
.wlan-form-tab-input-group.encryption-type {
  grid-area: encryption;
}
</style>
