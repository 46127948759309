import { API_URL, GAPI_URL } from '@/config';
import { AXIOS_INSTANCE } from '@/api';

const GET_WLANS_URL = `${GAPI_URL}/api/v1/profiles/wlan`;
const POST_CREATE_WLAN_PROFILE_URL = `${GAPI_URL}/api/v1/profiles/wlan`;
const POST_UPDATE_WLAN_PROFILE_URL = `${GAPI_URL}/api/v1/profiles/wlan`;
const POST_DELETE_WLAN_PROFILE_URL = `${GAPI_URL}/api/v1/profiles/wlan/bulk/deletion`;
const POST_ENABLE_WLAN_PROFILES_URL = `${GAPI_URL}/api/v1/profiles/wlan_ssid_enabled`;
const POST_DISABLE_WLAN_PROFILES_URL = `${GAPI_URL}/api/v1/profiles/wlan_ssid_enabled`;
const GET_WLAN_PROFILE_BY_NAME_URL = `${GAPI_URL}/api/v1/profiles/wlan/find-by-name/`;
const GET_WLAN_PROFILE_BY_WLAN_ID_URL = `${GAPI_URL}/api/v1/profiles/wlan/find-by-wlan-id/`;
const GET_RADUIS_GROUPS_URL = `${API_URL}/api/radius`;
const GET_REDIRECT_RULES_URL = `${API_URL}/api/network/redirects`;

export {
  AXIOS_INSTANCE,
  GET_WLANS_URL,
  POST_CREATE_WLAN_PROFILE_URL,
  POST_UPDATE_WLAN_PROFILE_URL,
  POST_DELETE_WLAN_PROFILE_URL,
  POST_ENABLE_WLAN_PROFILES_URL,
  POST_DISABLE_WLAN_PROFILES_URL,
  GET_WLAN_PROFILE_BY_NAME_URL,
  GET_WLAN_PROFILE_BY_WLAN_ID_URL,
  GET_RADUIS_GROUPS_URL,
  GET_REDIRECT_RULES_URL
};
