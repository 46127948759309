<template>
  <WlanForm
    :formConfig="formConfig"
    :forbiddenWlanNames="forbiddenWlanNames"
    :forbiddenWlanIds="forbiddenWlanIds"
    @submit="handleSubmit"
  >
    <template v-slot:control-buttons>
      <slot name="form-control-buttons"></slot>
    </template>
    </WlanForm>
</template>

<script>
/**
 * компонент настроенной формы WlanForm через generateFormConfig для редактирования существующего wlan.
 * ! Компонент локальный, используется только в CreateWlanModal.vue
 * @component
 */

import {
  WlanForm,
  generateFormConfig
} from '../../features';

/* eslint-disable camelcase */

export default {
  name: 'ChangeWlanForm',
  components: {
    WlanForm
  },
  props: {
    wlanForChanging: {
      type: Object,
      required: true
    },
    forbiddenWlanNames: {
      type: Array,
      default: () => []
    },
    forbiddenWlanIds: {
      type: Array,
      default: () => []
    },
    suggestedMabRadiusProfiles: {
      type: Array,
      default: () => []
    },
    suggestedWebAuthRedirectNames: {
      type: Array,
      default: () => []
    },
    suggestedRadius8021xProfileNames: {
      type: Array,
      default: () => []
    },
    suggestedRadiusAccountingProfileNames: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
    handleSubmit(formValues) {
      this.$emit('submit', formValues);
    }
  },
  created() {
    const mabRadiusProfileSuggestedOptions = this.suggestedMabRadiusProfiles
      .map(name => ({ display: name, data: name }));

    const webAuthRedirectNamesSeggestedOptions = this.suggestedWebAuthRedirectNames
      .map(name => ({ display: name, data: name }));

    const radius8021xProfileSuggestedOptions = this.suggestedRadius8021xProfileNames
      .map(name => ({ display: name, data: name }));

    const radiusAccountingProfileSuggestedOptions = this.suggestedRadiusAccountingProfileNames
      .map(name => ({ display: name, data: name }));

    // задаем дефолтные значения для каждого поля
    const {
      id,
      created_at,
      updated_at,
      name,
      wlan_id,
      ssid,
      broadcast_ssid_enabled,
      status_24ghz_enabled,
      status_5ghz_enabled,
      status_6ghz_enabled,
      status_ssid_enabled,
      security_type,
      encryption_type,
      support_pmf,
      param_80211w_query_attempts,
      param_80211w_retry_timeout,
      fast_roaming_enabled,
      ft_over_ds_enabled,
      param_80211r_reassociation_timeout,
      mab_radius_profile,
      psk_enabled,
      psk_256_enabled,
      param_8021x_enabled,
      param_8021x_256_enabled,
      sae_enabled,
      ft_sae_enabled,
      ft_8021x_enabled,
      ft_psk_enabled,
      suite_b_192_enabled,
      owe_enabled,
      psk_format,
      psk_type,
      psk_key,
      owe_wlan_id,
      sae_anti_clogging_threshold,
      sae_max_retries,
      sae_password_element,
      web_auth_profile,
      radius_8021x_profile,
      radius_accounting_profile,
      okc_enabled,
      param_80211ax_enabled,
      neighbor_report_enabled,
      bss_fast_transition_enabled,
      nas_id_1,
      nas_id_1_custom,
      nas_id_2,
      nas_id_2_custom,
      nas_id_3,
      nas_id_3_custom
    } = this.wlanForChanging;

    const formInitialSettings = {
      general: {
        name: {
          isDisabled: true,
          defaultValue: name
        },
        wlan_id: {
          isDisabled: true,
          defaultValue: wlan_id
        },
        ssid: {
          defaultValue: ssid
        },
        broadcast_ssid_enabled: {
          defaultValue: broadcast_ssid_enabled
        },
        status_24ghz_enabled: {
          defaultValue: status_24ghz_enabled
        },
        status_5ghz_enabled: {
          defaultValue: status_5ghz_enabled
        },
        status_6ghz_enabled: {
          defaultValue: status_6ghz_enabled
        },
        status_ssid_enabled: {
          defaultValue: status_ssid_enabled
        }
      },
      security: {
        layer2: {
          security_type: {
            defaultValue: security_type
          },
          encryption_type: {
            defaultValue: encryption_type
          },
          support_pmf: {
            defaultValue: support_pmf
          },
          param_80211w_query_attempts: {
            defaultValue: param_80211w_query_attempts
          },
          param_80211w_retry_timeout: {
            defaultValue: param_80211w_retry_timeout
          },
          fast_roaming_enabled: {
            defaultValue: fast_roaming_enabled
          },
          ft_over_ds_enabled: {
            defaultValue: ft_over_ds_enabled
          },
          param_80211r_reassociation_timeout: {
            defaultValue: param_80211r_reassociation_timeout
          },
          isMabEnabled: {
            defaultValue: mab_radius_profile !== 'not_configured'
          },
          mab_radius_profile: {
            defaultValue: mab_radius_profile,
            selectOptions: mabRadiusProfileSuggestedOptions
          },
          psk_enabled: {
            defaultValue: psk_enabled
          },
          psk_256_enabled: {
            defaultValue: psk_256_enabled
          },
          param_8021x_enabled: {
            defaultValue: param_8021x_enabled
          },
          param_8021x_256_enabled: {
            defaultValue: param_8021x_256_enabled
          },
          sae_enabled: {
            defaultValue: sae_enabled
          },
          ft_sae_enabled: {
            defaultValue: ft_sae_enabled
          },
          ft_8021x_enabled: {
            defaultValue: ft_8021x_enabled
          },
          ft_psk_enabled: {
            defaultValue: ft_psk_enabled
          },
          suite_b_192_enabled: {
            defaultValue: suite_b_192_enabled
          },
          owe_enabled: {
            defaultValue: owe_enabled
          },
          psk_format: {
            defaultValue: psk_format
          },
          psk_type: {
            defaultValue: psk_type
          },
          psk_key: {
            defaultValue: '' // В поле для ввода Параметра Ключ PSK должно быть пустым.
          },
          owe_wlan_id: {
            defaultValue: owe_wlan_id
          },
          sae_anti_clogging_threshold: {
            defaultValue: sae_anti_clogging_threshold
          },
          sae_max_retries: {
            defaultValue: sae_max_retries
          },
          sae_password_element: {
            defaultValue: sae_password_element
          }
        },
        layer3: {
          isWebAuthProfileEnabled: {
            defaultValue: web_auth_profile !== 'not_configured'
          },
          web_auth_profile: {
            defaultValue: web_auth_profile,
            selectOptions: webAuthRedirectNamesSeggestedOptions
          }
        },
        aaa: {
          radius_8021x_profile: {
            defaultValue: radius_8021x_profile,
            selectOptions: radius8021xProfileSuggestedOptions
          },
          radius_accounting_profile: {
            defaultValue: radius_accounting_profile,
            selectOptions: radiusAccountingProfileSuggestedOptions
          }
        }
      },
      advanced: {
        nas_id_1: {
          defaultValue: nas_id_1
        },
        nas_id_1_custom: {
          defaultValue: nas_id_1_custom
        },
        nas_id_2: {
          defaultValue: nas_id_2
        },
        nas_id_2_custom: {
          defaultValue: nas_id_2_custom
        },
        nas_id_3: {
          defaultValue: nas_id_3
        },
        nas_id_3_custom: {
          defaultValue: nas_id_3_custom
        },
        okc_enabled: {
          defaultValue: okc_enabled
        },
        param_80211ax_enabled: {
          defaultValue: param_80211ax_enabled
        },
        neighbor_report_enabled: {
          defaultValue: neighbor_report_enabled
        },
        bss_fast_transition_enabled: {
          defaultValue: bss_fast_transition_enabled
        }
      }
    };

    const formConfig = generateFormConfig({
      i18nInstance: this.$i18n,
      initialValues: formInitialSettings
    });

    this.formConfig = formConfig;
  }
};
</script>

<style lang="css" scoped></style>
