// selected checkbox of a row or not
function getSelectedRowValue(currentWlanId, selectedWlanIdsArray) {
  let value = false;

  selectedWlanIdsArray.forEach(wlanId => {
    if (wlanId === currentWlanId) {
      value = true;
    }
  });

  return value;
}

export {
  getSelectedRowValue
};
