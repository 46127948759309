/* eslint-disable no-unused-vars */

/**
 * Локальные валидаторы vee-validate для компонента AuthenticationKeyManagementSection.
 */

const getAuthenticationKeyManagementSectionValidators = (i18nInstance) => ({
  checkboxForbidden: () => ({
    getMessage: field => {
      const message = i18nInstance.t('wlan.inputError.authKeyManagementCheckbox.combination');
      return message;
    },
    validate: value => {
      return !value;
    }
  })
});

export {
  getAuthenticationKeyManagementSectionValidators
};
