/*
 * миксин чекбоксов 10 (шт) секции Управление ключами аутентификации
 * содержит в себе все, что касается их:
 * 1. правила отображения
 * 2. правила показа ошибок
 * 3. сброс валидации
 * 4. сброс значений стейта в дефолтное значение
 * 5. правила валидации
 * ! этот миксин локальный. Используется только в компоненте AuthenticationKeyManagementSection.vue
*/

/* eslint-disable camelcase */

import { getAuthenticationKeyManagementSectionValidators } from '../validators';

const authKeyManagementCheckboxesMixin = {
  data() {
    return {
      pskCheckboxValidationRules: '',
      psk256CheckboxValidationRules: '',
      param8021xCheckboxValidationRules: '',
      param8021x256CheckboxValidationRules: '',
      saeCheckboxValidationRules: '',
      ftSaeCheckboxValidationRules: '',
      ft8021xCheckboxValidationRules: '',
      ftPskCheckboxValidationRules: '',
      suiteb192CheckboxValidationRules: '',
      oweCheckboxValidationRules: '',
      isCheckboxCombinationsErrorMessageVisible: false,
      checkboxesValidCombinations_wpa2: [
        ['psk_enabled'],
        ['psk_enabled', 'psk_256_enabled'],
        ['psk_enabled', 'ft_psk_enabled'],
        ['psk_256_enabled', 'ft_psk_enabled'],
        ['psk_enabled', 'psk_256_enabled', 'ft_psk_enabled'],
        ['ft_psk_enabled'],
        ['param_8021x_enabled'],
        ['param_8021x_enabled', 'param_8021x_256_enabled'],
        ['param_8021x_enabled', 'param_8021x_256_enabled', 'ft_8021x_enabled'],
        ['param_8021x_enabled', 'ft_8021x_enabled'],
        ['param_8021x_256_enabled', 'ft_8021x_enabled'],
        ['ft_8021x_enabled'],
        ['suite_b_192_enabled']
      ],
      checkboxesValidCombinations_wpa2Wpa3: [
        ['param_8021x_256_enabled'],
        ['param_8021x_enabled', 'param_8021x_256_enabled'],
        ['param_8021x_enabled', 'param_8021x_256_enabled', 'ft_8021x_enabled'],
        ['param_8021x_256_enabled', 'ft_8021x_enabled'],
        ['psk_enabled', 'sae_enabled'],
        ['psk_enabled', 'psk_256_enabled', 'sae_enabled'],
        ['psk_256_enabled', 'sae_enabled'],
        ['psk_enabled', 'ft_psk_enabled', 'sae_enabled'],
        ['psk_enabled', 'psk_256_enabled', 'ft_psk_enabled', 'sae_enabled'],
        ['psk_256_enabled', 'ft_psk_enabled', 'sae_enabled'],
        ['psk_enabled', 'ft_psk_enabled', 'sae_enabled', 'ft_sae_enabled'],
        ['psk_enabled', 'psk_256_enabled', 'ft_psk_enabled', 'sae_enabled', 'ft_sae_enabled'],
        ['psk_256_enabled', 'ft_psk_enabled', 'sae_enabled', 'ft_sae_enabled'],
        ['psk_enabled', 'ft_psk_enabled', 'ft_sae_enabled'],
        ['psk_enabled', 'psk_256_enabled', 'ft_psk_enabled', 'ft_sae_enabled'],
        ['psk_256_enabled', 'ft_psk_enabled', 'ft_sae_enabled'],
        ['ft_psk_enabled', 'sae_enabled'],
        ['ft_psk_enabled', 'ft_sae_enabled'],
        ['ft_psk_enabled', 'sae_enabled', 'ft_sae_enabled'],
        ['suite_b_192_enabled']
      ],
      checkboxesValidCombinations_wpa3: [
        ['sae_enabled'],
        ['owe_enabled'],
        ['param_8021x_256_enabled'],
        ['param_8021x_256_enabled', 'ft_8021x_enabled'],
        ['sae_enabled', 'ft_sae_enabled'],
        ['ft_sae_enabled'],
        ['suite_b_192_enabled']
      ]
    };
  },
  computed: {
    // для отображения ошибки что хотя бы 1 чекбокс должен быть отмечен
    isCheckboxesErrors() {
      const checkboxErrors = [
        this.errors.first('security.layer2.psk_enabled'),
        this.errors.first('security.layer2.psk_256_enabled'),
        this.errors.first('security.layer2.param_8021x_enabled'),
        this.errors.first('security.layer2.param_8021x_256_enabled'),
        this.errors.first('security.layer2.sae_enabled'),
        this.errors.first('security.layer2.ft_sae_enabled'),
        this.errors.first('security.layer2.ft_8021x_enabled'),
        this.errors.first('security.layer2.ft_psk_enabled'),
        this.errors.first('security.layer2.suite_b_192_enabled'),
        this.errors.first('security.layer2.owe_enabled')
      ];

      const condition = Boolean(checkboxErrors.filter(errText => errText).length);
      return condition;
    },
    isAtLeastOneCheckboxSelected() {
      const checkboxValues = [
        this.formState['security.layer2.psk_enabled'],
        this.formState['security.layer2.psk_256_enabled'],
        this.formState['security.layer2.param_8021x_enabled'],
        this.formState['security.layer2.param_8021x_256_enabled'],
        this.formState['security.layer2.sae_enabled'],
        this.formState['security.layer2.ft_sae_enabled'],
        this.formState['security.layer2.ft_8021x_enabled'],
        this.formState['security.layer2.ft_psk_enabled'],
        this.formState['security.layer2.suite_b_192_enabled'],
        this.formState['security.layer2.owe_enabled']
      ];

      const condition = Boolean(checkboxValues.filter(value => !!value).length);
      return condition;
    },
    // для отображения зависимых инпутов от выбранной группы чекбоксов
    arePskCheckboxesChecked() {
      const pskCurrentValue = this.formState['security.layer2.psk_enabled'];
      const psk256CurrentValue = this.formState['security.layer2.psk_256_enabled'];
      const ftPskCurrentValue = this.formState['security.layer2.ft_psk_enabled'];
      const saeCurrentValue = this.formState['security.layer2.sae_enabled'];

      const condition = pskCurrentValue || psk256CurrentValue || ftPskCurrentValue || saeCurrentValue;

      return condition;
    },
    // для отображения зависимых инпутов от выбранной группы чекбоксов
    areSaeCheckboxesChecked() {
      const saeCurrentValue = this.formState['security.layer2.sae_enabled'];
      const ftSaeCurrentValue = this.formState['security.layer2.ft_sae_enabled'];

      const condition = saeCurrentValue || ftSaeCurrentValue;

      return condition;
    },
    currentEncryptionType() {
      // ccmp
      // ccmp256
      // gcmp256
      return this.formState['security.layer2.encryption_type'];
    },
    // правила отображения каждого чекбокса
    isPskCheckboxVisible() {
      if (this.currentSecurityType === 'wpa2' && this.currentEncryptionType === 'ccmp') {
        return true;
      }

      if (this.currentSecurityType === 'wpa2+wpa3' && this.currentEncryptionType === 'ccmp') {
        return true;
      }
      return false;
    },
    isPsk256CheckboxVisible() {
      if (this.currentSecurityType === 'wpa2' && this.currentEncryptionType === 'ccmp') {
        return true;
      }

      if (this.currentSecurityType === 'wpa2+wpa3' && this.currentEncryptionType === 'ccmp') {
        return true;
      }
      return false;
    },
    isParam8021xCheckboxVisible() {
      if (this.currentSecurityType === 'wpa2' && this.currentEncryptionType === 'ccmp') {
        return true;
      }

      if (this.currentSecurityType === 'wpa2+wpa3' && this.currentEncryptionType === 'ccmp') {
        return true;
      }
      return false;
    },
    isParam8021x256CheckboxVisible() {
      if (this.currentSecurityType === 'wpa2' && this.currentEncryptionType === 'ccmp') {
        return true;
      }

      if (this.currentSecurityType === 'wpa2+wpa3' && this.currentEncryptionType === 'ccmp') {
        return true;
      }

      if (this.currentSecurityType === 'wpa3' && this.currentEncryptionType === 'ccmp') {
        return true;
      }
      return false;
    },
    isSaeCheckboxVisible() {
      if (this.currentSecurityType === 'wpa2+wpa3' && this.currentEncryptionType === 'ccmp') {
        return true;
      }

      if (this.currentSecurityType === 'wpa3' && this.currentEncryptionType === 'ccmp') {
        return true;
      }
      return false;
    },
    isFtSaeCheckboxVisible() {
      if (this.currentSecurityType === 'wpa2+wpa3' && this.currentEncryptionType === 'ccmp' && this.isFastRoaming80211rEnabled) {
        return true;
      }

      if (this.currentSecurityType === 'wpa3' && this.currentEncryptionType === 'ccmp' && this.isFastRoaming80211rEnabled) {
        return true;
      }
      return false;
    },
    isFt8021xCheckboxVisible() {
      if (this.currentSecurityType === 'wpa2' && this.currentEncryptionType === 'ccmp' && this.isFastRoaming80211rEnabled) {
        return true;
      }

      if (this.currentSecurityType === 'wpa2+wpa3' && this.currentEncryptionType === 'ccmp' && this.isFastRoaming80211rEnabled) {
        return true;
      }

      if (this.currentSecurityType === 'wpa3' && this.currentEncryptionType === 'ccmp' && this.isFastRoaming80211rEnabled) {
        return true;
      }
      return false;
    },
    isFtPskCheckboxVisible() {
      if (this.currentSecurityType === 'wpa2' && this.currentEncryptionType === 'ccmp' && this.isFastRoaming80211rEnabled) {
        return true;
      }

      if (this.currentSecurityType === 'wpa2+wpa3' && this.currentEncryptionType === 'ccmp' && this.isFastRoaming80211rEnabled) {
        return true;
      }
      return false;
    },
    isSuiteb192CheckboxVisible() {
      if (this.currentSecurityType === 'wpa2'
        && (this.currentEncryptionType === 'ccmp256' || this.currentEncryptionType === 'gcmp256')) {
        return true;
      }

      if (this.currentSecurityType === 'wpa2+wpa3'
        && (this.currentEncryptionType === 'ccmp256' || this.currentEncryptionType === 'gcmp256')) {
        return true;
      }

      if (this.currentSecurityType === 'wpa3'
        && (this.currentEncryptionType === 'ccmp256' || this.currentEncryptionType === 'gcmp256')) {
        return true;
      }
      return false;
    },
    isOweCheckboxVisible() {
      if (this.currentSecurityType === 'wpa3' && this.currentEncryptionType === 'ccmp') {
        return true;
      }
      return false;
    }
  },
  watch: {
    formState: {
      handler(newVal, oldVal) {
        const newSecurityType = newVal['security.layer2.security_type'];
        const oldSecurityType = oldVal['security.layer2.security_type'];

        const new_psk_enabled = newVal['security.layer2.psk_enabled'];
        const old_psk_enabled = oldVal['security.layer2.psk_enabled'];

        const new_psk_256_enabled = newVal['security.layer2.psk_256_enabled'];
        const old_psk_256_enabled = oldVal['security.layer2.psk_256_enabled'];

        const new_param_8021x_enabled = newVal['security.layer2.param_8021x_enabled'];
        const old_param_8021x_enabled = oldVal['security.layer2.param_8021x_enabled'];

        const new_param_8021x_256_enabled = newVal['security.layer2.param_8021x_256_enabled'];
        const old_param_8021x_256_enabled = oldVal['security.layer2.param_8021x_256_enabled'];

        const new_sae_enabled = newVal['security.layer2.sae_enabled'];
        const old_sae_enabled = oldVal['security.layer2.sae_enabled'];

        const new_ft_sae_enabled = newVal['security.layer2.ft_sae_enabled'];
        const old_ft_sae_enabled = oldVal['security.layer2.ft_sae_enabled'];

        const new_ft_8021x_enabled = newVal['security.layer2.ft_8021x_enabled'];
        const old_ft_8021x_enabled = oldVal['security.layer2.ft_8021x_enabled'];

        const new_ft_psk_enabled = newVal['security.layer2.ft_psk_enabled'];
        const old_ft_psk_enabled = oldVal['security.layer2.ft_psk_enabled'];

        const new_suite_b_192_enabled = newVal['security.layer2.suite_b_192_enabled'];
        const old_suite_b_192_enabled = oldVal['security.layer2.suite_b_192_enabled'];

        const new_owe_enabled = newVal['security.layer2.owe_enabled'];
        const old_owe_enabled = oldVal['security.layer2.owe_enabled'];

        const new_encryption_type = newVal['security.layer2.encryption_type'];
        const old_encryption_type = oldVal['security.layer2.encryption_type'];

        const new_fast_roaming_enabled = newVal['security.layer2.fast_roaming_enabled'];
        const old_fast_roaming_enabled = oldVal['security.layer2.fast_roaming_enabled'];

        const isNewSecurityType = newSecurityType !== oldSecurityType;
        const isNewEncryptionType = new_encryption_type !== old_encryption_type;
        const isNewFast_roaming_enabled = new_fast_roaming_enabled !== old_fast_roaming_enabled;

        const isNew_psk_enabled = new_psk_enabled !== old_psk_enabled;
        const isNew_psk_256_enabled = new_psk_256_enabled !== old_psk_256_enabled;
        const isNew_param_8021x_enabled = new_param_8021x_enabled !== old_param_8021x_enabled;
        const isNew_param_8021x_256_enabled = new_param_8021x_256_enabled !== old_param_8021x_256_enabled;
        const isNew_sae_enabled = new_sae_enabled !== old_sae_enabled;
        const isNew_ft_sae_enabled = new_ft_sae_enabled !== old_ft_sae_enabled;
        const isNew_ft_8021x_enabled = new_ft_8021x_enabled !== old_ft_8021x_enabled;
        const isNew_ft_psk_enabled = new_ft_psk_enabled !== old_ft_psk_enabled;
        const isNew_suite_b_192_enabled = new_suite_b_192_enabled !== old_suite_b_192_enabled;
        const isNew_owe_enabled = new_owe_enabled !== old_owe_enabled;

        const isAtLeastOneCheckboxSelected = new_psk_enabled || new_psk_256_enabled || new_param_8021x_enabled || new_param_8021x_256_enabled
          || new_sae_enabled || new_ft_sae_enabled || new_ft_8021x_enabled || new_ft_psk_enabled || new_suite_b_192_enabled || new_owe_enabled;

        if (isNewSecurityType || isNewEncryptionType || isNewFast_roaming_enabled
          || isNew_psk_enabled || isNew_psk_256_enabled || isNew_param_8021x_enabled
          || isNew_param_8021x_256_enabled || isNew_sae_enabled || isNew_ft_sae_enabled
          || isNew_ft_8021x_enabled || isNew_ft_psk_enabled || isNew_suite_b_192_enabled || isNew_owe_enabled
        ) {
          this.forceHandleAllCheckboxes({
            securityType: newSecurityType,
            isNewSecurityType,
            isNewEncryptionType,
            isNewFast_roaming_enabled,
            isAtLeastOneCheckboxSelected,
            isInitialCall: false
          });
        }
      }
    }
  },
  methods: {
    // этап валидации 1: нужно выбрать хотя бы 1 чекбокс - все чекбоксы required
    setAllCheckboxesRequired() {
      this.pskCheckboxValidationRules = 'required';
      this.psk256CheckboxValidationRules = 'required';
      this.param8021xCheckboxValidationRules = 'required';
      this.param8021x256CheckboxValidationRules = 'required';
      this.saeCheckboxValidationRules = 'required';
      this.ftSaeCheckboxValidationRules = 'required';
      this.ft8021xCheckboxValidationRules = 'required';
      this.ftPskCheckboxValidationRules = 'required';
      this.suiteb192CheckboxValidationRules = 'required';
      this.oweCheckboxValidationRules = 'required';
    },
    // этап валидации 1: нужно выбрать хотя бы 1 чекбокс:
    // все чекбоксы required кроме тех, которые нельзя выбирать в одиночку - им checkboxForbidden
    // для wpa2+wpa3
    setAllCheckboxesValidationRules_FirstStage_wpa2Wpa3() {
      this.pskCheckboxValidationRules = 'checkboxForbidden';
      this.psk256CheckboxValidationRules = 'checkboxForbidden';
      this.param8021xCheckboxValidationRules = 'checkboxForbidden';
      this.param8021x256CheckboxValidationRules = 'required';
      this.saeCheckboxValidationRules = 'checkboxForbidden';
      this.ftSaeCheckboxValidationRules = 'checkboxForbidden';
      this.ft8021xCheckboxValidationRules = 'checkboxForbidden';
      this.ftPskCheckboxValidationRules = 'checkboxForbidden';
      this.suiteb192CheckboxValidationRules = 'required';
      this.oweCheckboxValidationRules = 'checkboxForbidden';
    },
    // этап валидации 0: ресет всех правил, чтобы далее установить актуальные
    resetAllCheckboxesValidationRules() {
      this.pskCheckboxValidationRules = '';
      this.psk256CheckboxValidationRules = '';
      this.param8021xCheckboxValidationRules = '';
      this.param8021x256CheckboxValidationRules = '';
      this.saeCheckboxValidationRules = '';
      this.ftSaeCheckboxValidationRules = '';
      this.ft8021xCheckboxValidationRules = '';
      this.ftPskCheckboxValidationRules = '';
      this.suiteb192CheckboxValidationRules = '';
      this.oweCheckboxValidationRules = '';
    },
    setAllCheckboxesForbidden() {
      this.pskCheckboxValidationRules = 'checkboxForbidden';
      this.psk256CheckboxValidationRules = 'checkboxForbidden';
      this.param8021xCheckboxValidationRules = 'checkboxForbidden';
      this.param8021x256CheckboxValidationRules = 'checkboxForbidden';
      this.saeCheckboxValidationRules = 'checkboxForbidden';
      this.ftSaeCheckboxValidationRules = 'checkboxForbidden';
      this.ft8021xCheckboxValidationRules = 'checkboxForbidden';
      this.ftPskCheckboxValidationRules = 'checkboxForbidden';
      this.suiteb192CheckboxValidationRules = 'checkboxForbidden';
      this.oweCheckboxValidationRules = 'checkboxForbidden';
    },
    // управление чекбоксами:
    // правила отображения
    // правила валидации, комбинации чекбоксов, которые можно выбрать в зависимости от режимов
    // сброс значений
    // сброс валидации
    // правила валидации:
    // https://wimarksystems.atlassian.net/wiki/spaces/Wimark/pages/223609094/FT23.9.1+front#[inlineExtension]%D0%92%D0%BA%D0%BB%D0%B0%D0%B4%D0%BA%D0%B0-%D0%91%D0%B5%D0%B7%D0%BE%D0%BF%D0%B0%D1%81%D0%BD%D0%BE%D1%81%D1%82%D1%8C-(%D1%80%D1%83%D1%81%D1%81%D0%BA%D0%B0%D1%8F-%D0%BB%D0%BE%D0%BA%D0%B0%D0%BB%D1%8C)-\-Security-(%D0%B0%D0%BD%D0%B3%D0%BB%D0%B8%D0%B9%D1%81%D0%BA%D0%B0%D1%8F-%D0%BB%D0%BE%D0%BA%D0%B0%D0%BB%D1%8C)-%E2%86%92-%D0%A3%D1%80%D0%BE%D0%B2%D0%B5%D0%BD%D1%8C-2-(%D1%80%D1%83%D1%81%D1%81%D0%BA%D0%B0%D1%8F-%D0%BB%D0%BE%D0%BA%D0%B0%D0%BB%D1%8C)-\-Layer-2-(%D0%B0%D0%BD%D0%B3%D0%BB%D0%B8%D0%B9%D1%81%D0%BA%D0%B0%D1%8F-%D0%BB%D0%BE%D0%BA%D0%B0%D0%BB%D1%8C)
    // 1 должен быть выбран хотя бы 1 чекбокс
    // 2 должны быть выбраты только разрешенные комбинации чекбоксов
    // https://wimarksystems.atlassian.net/wiki/spaces/Wimark/pages/223609094/FT23.9.1+front#%D0%9A%D0%BE%D0%BC%D0%B1%D0%B8%D0%BD%D0%B0%D1%86%D0%B8%D0%B8-%D0%BD%D0%B0%D1%81%D1%82%D1%80%D0%BE%D0%B5%D0%BA-%D0%BD%D0%B0-%D0%B2%D0%BA%D0%BB%D0%B0%D0%B4%D0%BA%D0%B5-%D0%91%D0%B5%D0%B7%D0%BE%D0%BF%D0%B0%D1%81%D0%BD%D0%BE%D1%81%D1%82%D1%8C-%E2%86%92-%D0%A3%D1%80%D0%BE%D0%B2%D0%B5%D0%BD%D1%8C-2%5BinlineExtension%5D
    forceHandleAllCheckboxes({
      securityType,
      isNewSecurityType,
      isNewEncryptionType,
      isNewFast_roaming_enabled,
      isAtLeastOneCheckboxSelected,
      isInitialCall
    }) {
      // idle security_type === 'open' ничего не делаем
      if (securityType === 'open' && isInitialCall) {
        return;
      }

      // idle без предзаполненных чекбоксов
      if (isInitialCall && !isAtLeastOneCheckboxSelected) {
        this.setAllCheckboxesRequired();
        this.isCheckboxCombinationsErrorMessageVisible = false;

        return;
      }
      //  idle с предзаполненными чекбоксами
      if (isInitialCall && isAtLeastOneCheckboxSelected) {
        // переключаем ошибку с 'нужен хотя бы 1' на 'неправильная комбинация'
        this.isCheckboxCombinationsErrorMessageVisible = true;

        // валидируем комбинации чекбоксов для wpa2, wpa2+wpa3, wpa3
        const isValidCheckboxesCombination = this.isCheckboxCombinationValid(securityType);

        if (!isValidCheckboxesCombination) {
          // 1 всем чекбоксам ставим checkboxForbidden
          // 2 все чекбоксы валидируем

          this.setAllCheckboxesForbidden();
        }

        if (isValidCheckboxesCombination) {
          // 1 всем чекбоксам убираем валидацию на ''
          // 2 всем чекбоксам сбрасываем валид стейт
          this.resetAllCheckboxesValidationRules();
          this.resetAllCheckboxesValidationState();
        }

        return;
      }
      // progress: security_type === 'open'
      // нет чекбоксов в open - все ресетится, скрывается, и дальше не идем
      if (securityType === 'open') {
        this.handleResetAllCheckboxes();
        this.resetAllCheckboxesValidationState();
        this.resetAllCheckboxesValidationRules();

        return;
      }

      // если security_type или encryption_type fast_roaming_enabled изменился,
      // надо все чекбоксы сбросить в ресет
      if (isNewSecurityType || isNewEncryptionType || isNewFast_roaming_enabled) {
        this.handleResetAllCheckboxes();
        this.resetAllCheckboxesValidationState();
      }

      // если ни один чекбокс не был выбран (состояние idle),
      // то устанавливаем правила валидации на этап 1 - все чекбоксы required
      // и ресетим валидацию
      if (!isAtLeastOneCheckboxSelected) {
        this.setAllCheckboxesRequired();
        this.isCheckboxCombinationsErrorMessageVisible = false;

        this.$nextTick(() => {
          this.$validator.validate('authKeyManagementCheckboxes.*');
        });
      }


      // если выбран 1 чекбокс или более (состояние progress),
      // то устанавливаем правила валидации на этап 2 - чекбоксам проверяем возможные комбинации.
      // Если чекбокс/ы соответствуют разрешенным комбинациям (для данного конкретного security_type), им правила валидации убираем в ''
      // Всем чекбокс/ы не соответствуют разрешенным правилам (для данного конкретного security_type), им правило валидации - 'checkboxForbidden'
      // таким образом нельзя выбрать несовпадающие комбинации
      if (isAtLeastOneCheckboxSelected) {
        // переключаем ошибку с 'нужен хотя бы 1' на 'неправильная комбинация'
        this.isCheckboxCombinationsErrorMessageVisible = true;

        // валидируем комбинации чекбоксов для wpa2, wpa2+wpa3, wpa3
        const isValidCheckboxesCombination = this.isCheckboxCombinationValid(securityType);

        if (!isValidCheckboxesCombination) {
          // 1 всем чекбоксам ставим checkboxForbidden
          // 2 все чекбоксы валидируем

          this.setAllCheckboxesForbidden();

          // validate all fields within this scope.
          this.$nextTick(() => {
            this.$validator.validate('authKeyManagementCheckboxes.*');
          });
        }

        if (isValidCheckboxesCombination) {
          // 1 всем чекбоксам убираем валидацию на ''
          // 2 всем чекбоксам сбрасываем валид стейт
          this.resetAllCheckboxesValidationRules();
          this.resetAllCheckboxesValidationState();

          this.$nextTick(() => {
            this.$validator.validate('authKeyManagementCheckboxes.*');
          });
        }
      }
    },
    // сброс значений всех чекбоксов в дефолтное состояние
    handleResetAllCheckboxes() {
      const checkboxFormStateNames = [
        'security.layer2.psk_enabled',
        'security.layer2.psk_256_enabled',
        'security.layer2.param_8021x_enabled',
        'security.layer2.param_8021x_256_enabled',
        'security.layer2.sae_enabled',
        'security.layer2.ft_sae_enabled',
        'security.layer2.ft_8021x_enabled',
        'security.layer2.ft_psk_enabled',
        'security.layer2.suite_b_192_enabled',
        'security.layer2.owe_enabled'
      ];

      checkboxFormStateNames.forEach(name => {
        this.$emit('update:inputValue', {
          inputName: name,
          value: false
        });
      });
    },
    resetAllCheckboxesValidationState() {
      // сброс состояния валидации с удаленных полей и ошибок в частности
      // https://vee-validate.logaretm.com/v2/examples/scopes.html
      // https://vee-validate.logaretm.com/v2/api/validator.html#methods
      const matcher = {
        scope: 'authKeyManagementCheckboxes',
        vmId: this.$validator.id
      };

      this.$validator.reset(matcher);
    },
    setAuthenticationKeyManagementSectionValidators() {
      const validator = this.$validator;

      const validators = getAuthenticationKeyManagementSectionValidators(this.$i18n);

      const checkboxForbidden = validators.checkboxForbidden();

      validator.extend('checkboxForbidden', checkboxForbidden);
    },
    // валидатор комбинации чекбоксов
    isCheckboxCombinationValid(dictName) {
      // делаем массив названий выбранных чекбоксов для сопоставления с разрешенными комбинациями
      const selectedCheckboxes = [];

      if (this.formState['security.layer2.psk_enabled']) {
        selectedCheckboxes.push('psk_enabled');
      }

      if (this.formState['security.layer2.psk_256_enabled']) {
        selectedCheckboxes.push('psk_256_enabled');
      }

      if (this.formState['security.layer2.param_8021x_enabled']) {
        selectedCheckboxes.push('param_8021x_enabled');
      }

      if (this.formState['security.layer2.param_8021x_256_enabled']) {
        selectedCheckboxes.push('param_8021x_256_enabled');
      }

      if (this.formState['security.layer2.sae_enabled']) {
        selectedCheckboxes.push('sae_enabled');
      }

      if (this.formState['security.layer2.ft_sae_enabled']) {
        selectedCheckboxes.push('ft_sae_enabled');
      }

      if (this.formState['security.layer2.ft_8021x_enabled']) {
        selectedCheckboxes.push('ft_8021x_enabled');
      }

      if (this.formState['security.layer2.ft_psk_enabled']) {
        selectedCheckboxes.push('ft_psk_enabled');
      }

      if (this.formState['security.layer2.suite_b_192_enabled']) {
        selectedCheckboxes.push('suite_b_192_enabled');
      }

      if (this.formState['security.layer2.owe_enabled']) {
        selectedCheckboxes.push('owe_enabled');
      }

      let checkboxesValidCombinations;

      switch (dictName) {
        case 'wpa2':
          checkboxesValidCombinations = this.checkboxesValidCombinations_wpa2;
          break;
        case 'wpa2+wpa3':
          checkboxesValidCombinations = this.checkboxesValidCombinations_wpa2Wpa3;
          break;
        case 'wpa3':
          checkboxesValidCombinations = this.checkboxesValidCombinations_wpa3;
          break;
        default:
          break;
      }

      const isValidCombination = checkboxesValidCombinations
        .some((combination) => {
        // Проверяем, содержатся ли все элементы из combination в selectedCheckboxes
          const result = combination.length === selectedCheckboxes.length && combination
            .every((checkboxName) => selectedCheckboxes.includes(checkboxName));

          return result;
        });

      return isValidCombination;
    }
  },
  created() {
    // изначальное положение валидации для чекбоксов
    this.forceHandleAllCheckboxes({
      securityType: this.currentSecurityType,
      isAtLeastOneCheckboxSelected: this.isAtLeastOneCheckboxSelected,
      isNewSecurityType: false,
      isNewEncryptionType: false,
      isNewFast_roaming_enabled: false,
      isInitialCall: true
    });

    this.setAuthenticationKeyManagementSectionValidators();
  },
  beforeDestroy() {
  }
};

export default authKeyManagementCheckboxesMixin;
