/* eslint-disable camelcase */
/**
 * @typedef {import('./types.js').WlanProfile_FRONTEND} WlanProfile_FRONTEND
 * @typedef {import('./types.js').BaseProfile_FRONTEND} BaseProfile_FRONTEND
 * @typedef {import('./types.js').WlanProfile_BACKEND} WlanProfile_BACKEND
 * @typedef {import('./types.js').Id} Id
 *
 */

/**
 * Формирует URL с query params на основе массива параметров.
 *
 * @param {string} url - api url.
 * @param {Array<{name: string, value: any}>} urlParams - Массив объектов с именем и значением параметра.
 * @returns {string} URL с query params.
 */
function getFetcherUrl(url, urlParams) {
  // Фильтруем параметры, у которых есть значение
  const queryParams = urlParams
    .filter(param => (param.value !== undefined && param.value !== null))
    .map(param => `${encodeURIComponent(param.name)}=${encodeURIComponent(param.value)}`)
    .join('&');

  const resultUrl = `${url}${queryParams ? '?' : ''}${queryParams}`;

  return resultUrl;
}

/**
 * Конвертер типов wlan_profile с бэковых типов (WlanProfile_BACKEND) в фронтовые (WlanProfile_FRONTEND).
 * Использовать, когда получаем wlan_profile с бэка, чтобы использовать на фронте данные с корректными для фронта типами.
 *
 * @param {WlanProfile_BACKEND} wlan_profile - WlanProfile_BACKEND данные для конвертации типов.
 * @returns {WlanProfile_FRONTEND} - сконвертированные данные с типизацией WlanProfile_FRONTEND.
 */
function wlanProfileDataTypesConverterForFrontend(wlan_profile) {
  const {
    id,
    name,
    wlan_id,
    ssid,
    broadcast_ssid_enabled,
    status_24ghz_enabled,
    status_5ghz_enabled,
    status_6ghz_enabled,
    status_ssid_enabled,
    security_type,
    encryption_type,
    support_pmf,
    fast_roaming_enabled,
    ft_over_ds_enabled,
    mab_radius_profile,
    psk_enabled,
    psk_256_enabled,
    sae_enabled,
    ft_sae_enabled,
    ft_8021x_enabled,
    ft_psk_enabled,
    suite_b_192_enabled,
    owe_enabled,
    psk_format,
    psk_type,
    psk_key,
    owe_wlan_id,
    sae_anti_clogging_threshold,
    sae_max_retries,
    sae_password_element,
    web_auth_profile,
    radius_8021x_profile,
    radius_accounting_profile,
    nas_id_1,
    nas_id_1_custom,
    nas_id_2,
    nas_id_2_custom,
    nas_id_3,
    nas_id_3_custom,
    okc_enabled,
    neighbor_report_enabled,
    bss_fast_transition_enabled,
    created_at,
    updated_at
  } = wlan_profile;

  const param_80211w_query_attempts = wlan_profile['80211w_query_attempts'];
  const param_80211w_retry_timeout = wlan_profile['80211w_retry_timeout'];
  const param_80211r_reassociation_timeout = wlan_profile['80211r_reassociation_timeout'];
  const param_8021x_enabled = wlan_profile['8021x_enabled'];
  const param_8021x_256_enabled = wlan_profile['8021x_256_enabled'];
  const param_80211ax_enabled = wlan_profile['80211ax_enabled'];

  const convertedTypesToFrontendFormat = {
    id: String(id),
    name: String(name),
    wlan_id: Number(wlan_id),
    ssid: String(ssid),
    broadcast_ssid_enabled: String(broadcast_ssid_enabled) === 'True',
    status_24ghz_enabled: String(status_24ghz_enabled) === 'True',
    status_5ghz_enabled: String(status_5ghz_enabled) === 'True',
    status_6ghz_enabled: String(status_6ghz_enabled) === 'True',
    status_ssid_enabled: String(status_ssid_enabled) === 'True',
    security_type: String(security_type),
    encryption_type: String(encryption_type),
    support_pmf: String(support_pmf),
    param_80211w_query_attempts: Number(param_80211w_query_attempts),
    param_80211w_retry_timeout: Number(param_80211w_retry_timeout),
    fast_roaming_enabled: String(fast_roaming_enabled) === 'True',
    ft_over_ds_enabled: String(ft_over_ds_enabled) === 'True',
    param_80211r_reassociation_timeout: Number(param_80211r_reassociation_timeout),
    mab_radius_profile: String(mab_radius_profile),
    psk_enabled: String(psk_enabled) === 'True',
    psk_256_enabled: String(psk_256_enabled) === 'True',
    param_8021x_enabled: String(param_8021x_enabled) === 'True',
    param_8021x_256_enabled: String(param_8021x_256_enabled) === 'True',
    sae_enabled: String(sae_enabled) === 'True',
    ft_sae_enabled: String(ft_sae_enabled) === 'True',
    ft_8021x_enabled: String(ft_8021x_enabled) === 'True',
    ft_psk_enabled: String(ft_psk_enabled) === 'True',
    suite_b_192_enabled: String(suite_b_192_enabled) === 'True',
    owe_enabled: String(owe_enabled) === 'True',
    psk_format: String(psk_format),
    psk_type: String(psk_type),
    psk_key: String(psk_key),
    owe_wlan_id: Number(owe_wlan_id),
    sae_anti_clogging_threshold: Number(sae_anti_clogging_threshold),
    sae_max_retries: Number(sae_max_retries),
    sae_password_element: String(sae_password_element),
    web_auth_profile: String(web_auth_profile),
    radius_8021x_profile: String(radius_8021x_profile),
    radius_accounting_profile: String(radius_accounting_profile),
    nas_id_1: String(nas_id_1),
    nas_id_1_custom: String(nas_id_1_custom),
    nas_id_2: String(nas_id_2),
    nas_id_2_custom: String(nas_id_2_custom),
    nas_id_3: String(nas_id_3),
    nas_id_3_custom: String(nas_id_3_custom),
    okc_enabled: String(okc_enabled) === 'True',
    param_80211ax_enabled: String(param_80211ax_enabled) === 'True',
    neighbor_report_enabled: String(neighbor_report_enabled) === 'True',
    bss_fast_transition_enabled: String(bss_fast_transition_enabled) === 'True'
  };

  return convertedTypesToFrontendFormat;
}

/**
 * Конвертер типов ap_join_profile с фронтовых типов (WlanProfile_FRONTEND) в бэковые (WlanProfile_BACKEND).
 * Использовать, когда возвращаем wlan_profile с фронта на бэк.
 *
 * ! поля id могут быть, могут не быть в зависимости от запроса.
 *
 * @param {BaseProfile_FRONTEND & Partial<Id>} wlan_profile - WlanProfile_FRONTEND данные для конвертации типов.
 * @returns {WlanProfile_BACKEND>} - сконвертированные данные с типизацией WlanProfile_BACKEND.
 */
function wlanProfileDataTypesConverterForBackend(wlan_profile) {
  const {
    id, // может быть, может не быть. При создании сущности его не будет.
    name,
    wlan_id,
    ssid,
    broadcast_ssid_enabled,
    status_24ghz_enabled,
    status_5ghz_enabled,
    status_6ghz_enabled,
    status_ssid_enabled,
    security_type,
    encryption_type,
    support_pmf,
    param_80211w_query_attempts,
    param_80211w_retry_timeout,
    fast_roaming_enabled,
    ft_over_ds_enabled,
    param_80211r_reassociation_timeout,
    mab_radius_profile,
    psk_enabled,
    psk_256_enabled,
    param_8021x_enabled,
    param_8021x_256_enabled,
    sae_enabled,
    ft_sae_enabled,
    ft_8021x_enabled,
    ft_psk_enabled,
    suite_b_192_enabled,
    owe_enabled,
    psk_format,
    psk_type,
    psk_key,
    owe_wlan_id,
    sae_anti_clogging_threshold,
    sae_max_retries,
    sae_password_element,
    web_auth_profile,
    radius_8021x_profile,
    radius_accounting_profile,
    nas_id_1,
    nas_id_1_custom,
    nas_id_2,
    nas_id_2_custom,
    nas_id_3,
    nas_id_3_custom,
    okc_enabled,
    param_80211ax_enabled,
    neighbor_report_enabled,
    bss_fast_transition_enabled
  } = wlan_profile;

  const convertedTypesToBackendFormat = {
    name: String(name),
    wlan_id: String(wlan_id),
    ssid: String(ssid),
    broadcast_ssid_enabled: broadcast_ssid_enabled ? 'True' : 'False',
    status_24ghz_enabled: status_24ghz_enabled ? 'True' : 'False',
    status_5ghz_enabled: status_5ghz_enabled ? 'True' : 'False',
    status_6ghz_enabled: status_6ghz_enabled ? 'True' : 'False',
    status_ssid_enabled: status_ssid_enabled ? 'True' : 'False',
    security_type: String(security_type),
    encryption_type: String(encryption_type),
    support_pmf: String(support_pmf),
    '80211w_query_attempts': String(param_80211w_query_attempts),
    '80211w_retry_timeout': String(param_80211w_retry_timeout),
    fast_roaming_enabled: fast_roaming_enabled ? 'True' : 'False',
    ft_over_ds_enabled: ft_over_ds_enabled ? 'True' : 'False',
    '80211r_reassociation_timeout': String(param_80211r_reassociation_timeout),
    mab_radius_profile: String(mab_radius_profile),
    psk_enabled: psk_enabled ? 'True' : 'False',
    psk_256_enabled: psk_256_enabled ? 'True' : 'False',
    '8021x_enabled': param_8021x_enabled ? 'True' : 'False',
    '8021x_256_enabled': param_8021x_256_enabled ? 'True' : 'False',
    sae_enabled: sae_enabled ? 'True' : 'False',
    ft_sae_enabled: ft_sae_enabled ? 'True' : 'False',
    ft_8021x_enabled: ft_8021x_enabled ? 'True' : 'False',
    ft_psk_enabled: ft_psk_enabled ? 'True' : 'False',
    suite_b_192_enabled: suite_b_192_enabled ? 'True' : 'False',
    owe_enabled: owe_enabled ? 'True' : 'False',
    psk_format: String(psk_format),
    psk_type: String(psk_type),
    psk_key: String(psk_key),
    owe_wlan_id: String(owe_wlan_id),
    sae_anti_clogging_threshold: String(sae_anti_clogging_threshold),
    sae_max_retries: String(sae_max_retries),
    sae_password_element: String(sae_password_element),
    web_auth_profile: String(web_auth_profile),
    radius_8021x_profile: String(radius_8021x_profile),
    radius_accounting_profile: String(radius_accounting_profile),
    nas_id_1: String(nas_id_1),
    nas_id_1_custom: String(nas_id_1_custom),
    nas_id_2: String(nas_id_2),
    nas_id_2_custom: String(nas_id_2_custom),
    nas_id_3: String(nas_id_3),
    nas_id_3_custom: String(nas_id_3_custom),
    okc_enabled: okc_enabled ? 'True' : 'False',
    '80211ax_enabled': param_80211ax_enabled ? 'True' : 'False',
    neighbor_report_enabled: neighbor_report_enabled ? 'True' : 'False',
    bss_fast_transition_enabled: bss_fast_transition_enabled ? 'True' : 'False'
  };

  // может быть, может не быть. При создании сущности его не будет.
  if (id) {
    convertedTypesToBackendFormat.id = String(id);
  }

  return convertedTypesToBackendFormat;
}

export {
  getFetcherUrl,
  wlanProfileDataTypesConverterForFrontend,
  wlanProfileDataTypesConverterForBackend
};
