<template>
  <div class="wlan-form-tab-input-group radius-section">
    <h3 v-if="config.label" class="wlan-form-tab-input-group-heading">
      {{ config.label }}
    </h3>
    <div v-for="input in config.inputs"
      class="wlan-form-tab-input-group"
      :key="input.id"
    >
      <template v-if="input.inputType === 'select' && input.name === 'security.aaa.radius_8021x_profile'">
        <WSelect
          data-vv-scope="radius_8021x_profile"
          withInitialOption
          :options="radius_8021x_profileSelectOptions"
          :label="input.label"
          :name="input.name"
          :id="input.name"
          :value="formState[input.name]"
          @input="handleSelect(input.name, $event)"
          :disabled="input.isDisabled"
          v-validate="isRadius_8021x_profileRequired ? 'required' : ''"
          data-vv-validate-on="input"
          :data-vv-as="input.label"
          :errorText="errors.first(input.name)"
        >
          <template #icon>
            <v-popover
              trigger="hover"
              placement="top-center"
              popoverClass="wlan-form-popover"
              :popperOptions="{
                modifiers: {
                  preventOverflow: {
                    boundariesElement: 'window'
                  }
                }
              }">
              <i class="fa fa-info-circle wlan-form-info-icon" />
              <template slot="popover">
                <i18n path="wlan.inputInfo.radius_8021x_profile" tag="span">
                  <template #here>
                    <router-link
                      :to="{ name: 'Radius' }"
                      title="AAA"
                      target="_blank"
                    >
                      {{ $t('general.here') }}
                    </router-link>
                  </template>
                </i18n>
              </template>
            </v-popover>
          </template>
        </WSelect>
      </template>
      <template v-else-if="input.inputType === 'select' && input.name === 'security.aaa.radius_accounting_profile'">
        <WSelect
          withInitialOption
          :options="input.selectOptions"
          :label="input.label"
          :name="input.name"
          :id="input.name"
          :value="formState[input.name]"
          @input="handleSelect(input.name, $event)"
          :disabled="input.isDisabled"
          v-validate="input.validateRules"
          data-vv-validate-on="input"
          :data-vv-as="input.label"
          :errorText="errors.first(input.name)"
        >
          <template #icon>
            <v-popover
              trigger="hover"
              placement="top-center"
              popoverClass="wlan-form-popover"
              :popperOptions="{
                modifiers: {
                  preventOverflow: {
                    boundariesElement: 'window'
                  }
                }
              }">
              <i class="fa fa-info-circle wlan-form-info-icon" />
              <template slot="popover">
                <i18n path="wlan.inputInfo.radius_8021x_profile" tag="span">
                  <template #here>
                    <router-link
                      :to="{ name: 'Radius' }"
                      title="AAA"
                      target="_blank"
                    >
                      {{ $t('general.here') }}
                    </router-link>
                  </template>
                </i18n>
              </template>
            </v-popover>
          </template>
        </WSelect>
      </template>
      <template v-else>
        <WInput
          :key="input.id"
          :type="input.inputType"
          :label="input.label"
          :value="formState[input.name]"
          @input="handleInput(input, $event)"
          :id="input.name"
          :name="input.name"
          :placeholder="input.placeholder"
          :disabled="input.isDisabled"
          v-validate="input.validateRules"
          data-vv-validate-on="input"
          :data-vv-as="input.label"
          :errorText="errors.first(input.name)"
          autocomplete="one-time-code"
        />
      </template>
    </div>
  </div>
</template>

<script>
/**
 *
 * компонент секции таба security - aaa - radius.
 * Содержит разметку, правила рендеринга (в т.ч. рекурсивные), специфических настроек отображения
 * и параметров валидации отдельных секций(групп) инпутов в соответствии с конфигом
 * @component
 *
 */

/* eslint-disable camelcase */

export default {
  name: 'RadiusSection',
  inject: ['$validator'],
  components: {
  },
  props: {
    config: {
      type: Object,
      required: true
    },
    formState: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      radius_8021x_profileSelectOptions: []
    };
  },
  computed: {
    radius_8021x_profileConfig() {
      return this.config.inputs.find(item => item.name === 'security.aaa.radius_8021x_profile');
    },
    // Если на вкладке Безопасность → Уровень 2 в секции Управление ключами авторизации были включены параметры:
    // 802.1x или 802.1X - SHA256 или FT + 802.1x, то выбор Radius профиль 802.1x аутентификации обязателен.
    isRadius_8021x_profileRequired() {
      const condition = this.formState['security.layer2.param_8021x_enabled']
        || this.formState['security.layer2.param_8021x_256_enabled']
        || this.formState['security.layer2.ft_8021x_enabled'];

      return condition;
    }
  },
  watch: {
    isRadius_8021x_profileRequired(newVal) {
      const radius8021xProfileCurrentValue = this.formState['security.aaa.radius_8021x_profile'];
      // при отключении web_auth_profile - сброс значений и ошибок валидации в дефолтное значение
      if (newVal === false) {
        this.handleResetRadius_8021x_profileSelectOptions();
      }

      if (newVal === false && radius8021xProfileCurrentValue === '') {
        this.handleResetRadius_8021x_profile();
      }

      // при открытии radius_8021x_profile ставим значение пустую строку чтобы валидация required сработала, и необходмо было выбрать какое-то значение
      if (newVal === true) {
        this.radius_8021x_profileSelectOptions = this.radius_8021x_profileConfig
          .selectOptions
          .filter(option => option.data !== 'not_configured');
      }
      if (newVal === true && radius8021xProfileCurrentValue === 'not_configured') {
        this.$emit('update:inputValue', {
          inputName: 'security.aaa.radius_8021x_profile',
          value: ''
        });
      }
    }
  },
  methods: {
    handleSelect(inputName, value) {
      this.$emit('update:inputValue', { inputName, value });
    },
    handleInput(inputObj, value) {
      const { name, inputType } = inputObj;

      let correctFormatValue = value;

      // из-за особенностей архитектуры и невозможности использовать здесь v-modal, форматировать number приходится так
      if (inputType === 'number') {
        correctFormatValue = value === '' ? '' : Number(correctFormatValue);
      }

      this.$emit('update:inputValue', {
        inputName: name,
        value: correctFormatValue
      });
    },
    handleResetRadius_8021x_profile() {
      this.$emit('update:inputValue', {
        inputName: 'security.aaa.radius_8021x_profile',
        value: 'not_configured'
      });

      // сброс состояния валидации с удаленных полей и ошибок в частности
      // https://vee-validate.logaretm.com/v2/examples/scopes.html
      // https://vee-validate.logaretm.com/v2/api/validator.html#methods
      const matcher = {
        scope: 'radius_8021x_profile',
        vmId: this.$validator.id
      };

      this.$validator.reset(matcher);
    },
    handleResetRadius_8021x_profileSelectOptions() {
      this.radius_8021x_profileSelectOptions = this.radius_8021x_profileConfig.selectOptions;
    }
  },
  created() {
    this.handleResetRadius_8021x_profileSelectOptions();
  }
};
</script>

<style lang="css" scoped>
/* .wlan-form-tab-input-group.radius-section{

} */
</style>
