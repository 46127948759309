<template>
  <div class="row">
    <div class="col-lg-12 d-flex justify-content-end mb-h" v-if="isNotWizard">
      <div class="pl-1">
        <WButton info
          v-if="!isDisable"
          @click="openTemplateWizard"
        >
          {{ $t('template.addTemplate') }}
        </WButton>
      </div>
    </div>
    <div class="col-lg-12" v-if="isNotWizard">
      <div class="filter-block p-0">
        <div class="filters w-100">
          <div class="filters__search">
<!--            <div class="col-lg-3 input-group input-group-sm filters__input p-0 pr-1">-->
<!--              <span class="input-group-addon">-->
<!--                <i class="fa fa-search"></i>-->
<!--              </span>-->
<!--              <input-->
<!--                id="search"-->
<!--                name="search"-->
<!--                :placeholder="$t('aps.search')"-->
<!--                class="form-control filters__input"-->
<!--                v-model="filters.query"-->
<!--                @input="disableEditMode"-->
<!--              />-->
<!--              <span class="clear-input text-muted" v-if="filters.query != ''" @click="filters.query = ''">-->
<!--                <i class="fa fa-remove"></i>-->
<!--              </span>-->
<!--            </div>-->


            <div class="col-lg-3 p-0 pr-1">
              <div class=" input-group input-group-sm filters__input ">
              <span class="input-group-addon">
                <i class="fa fa-search"></i>
              </span>
                <input
                  id="search"
                  name="search"
                  :placeholder="$t('aps.search')"
                  class="form-control filters__input"
                  v-model="filters.query"
                  @input="disableEditMode"
                />
                <span class="clear-input text-muted" v-if="filters.query != ''"
                      @click="filters.query = ''">
                <i class="fa fa-remove"></i>
              </span>
              </div>
            </div>


<!--            <div class="input-group input-group-sm filters__input ml-1">-->
<!--              <span class="input-group-addon filters__field-icon">-->
<!--                <i class="fa fa-map-marker"></i>-->
<!--              </span>-->
<!--              <select @change="selectLocation"  class="form-control form-control-sm filters__field" v-model="filters.location">-->
<!--                <option :selected="true" :value="'All locations'">-->
<!--                  {{ $t('general.allLocations') }}-->
<!--                </option>-->
<!--                <option v-for="location in locationsList" :value="location.id" :key="location.id">-->
<!--                  {{ location.name }}-->
<!--                </option>-->
<!--              </select>-->
<!--            </div>-->
<!--            <div-->
<!--                class=" d-flex  align-items-center switcher-with-childs-for-use-in-filters-wrapper">-->
<!--                <div-->
<!--                  class="  d-flex align-items-end justify-content-start"-->
<!--                >-->
<!--                  <Switch-component-->
<!--                    :disabled="this.filters.location === 'All locations'"-->
<!--                    v-model="filters.with_childs"-->
<!--                    class="switcher-with-childs-for-use-in-filters"-->
<!--                    @change="selectLocation"-->
<!--                  />-->
<!--                </div>-->
<!--                <div-->
<!--                  class="input-group input-group-sm filters__location switcher-with-childs-for-use-in-filters-caption ">-->
<!--                  <small class="with-child">{{ $t('general.withChild') }}</small>-->
<!--                </div>-->
<!--              </div>-->
                            <div
                  class=" col-lg-3 input-group-for-location-select-in-templates--filters  p-0 pr-1  " v-if="areLocationsVisible">
                                <span class="input-group-addon filters__field-icon">
                  <i class="fa fa-map-marker"
                     :class="{ 'text-primary': filters.location !== 'All locations' }"></i>
                </span>
                  <SelectComponentV3ForUseInLocationSelectors

                    v-if="showLocationFilter"
                    no-wrap-selected-option
                    class="select-location-in-templates--filters"
                    enable-max-option-width
                    small-size
                    :show-search-field-into-items-list="!isSelectedDefaultLocationForRequests || (isSelectedDefaultLocationForRequests && $store.getters.getDefaultWithChildsForRequests())"
                    :no-clear-selected-option-when-click-on-it="isSelectedDefaultLocationForRequests"
                   :show-delete-button="!isSelectedDefaultLocationForRequests"
                    v-model="locationInFiltersData"
                    track-by="name"
                    option-id-name="id"
                    :no-options-found="$t('general.noResultForSearch')"
                    :async-function="requestLocations.bind(this)"
                    :placeholder="$t('portalStats.selectLocation')"
                    @select="selectLocationInFilters"
                  >
                    <template #dropdown-start>
                      <div class="ml-h mt-1">
                        <Switch-component
                          v-if="!isSelectedDefaultLocationForRequests || (isSelectedDefaultLocationForRequests && $store.getters.getDefaultWithChildsForRequests())"
                          @input="selectLocation"
                          :label="$t('general.withChild')"
                          v-model="filters.with_childs"
                          class=""
                        />
                      </div>
                    </template>
                  </SelectComponentV3ForUseInLocationSelectors>
                </div>

          </div>

          <div class="filters__multiple">
            <div class="btn-group btn-group-sm float-left" data-toggle="button">
              <label class="btn btn btn-outline-primary" :class="{ active: filters.autoFilter === '1' }">
                <input type="radio" name="autoFilter" @click="setFilter('autoFilter', '1')" />
                {{ $t('template.autoConfig') }}
              </label>
              <label class="btn btn btn-outline-primary" :class="{ active: filters.autoFilter === '0' }">
                <input type="radio" name="autoFilter" @click="setFilter('autoFilter', '0')" />
                {{ $t('template.manualConfig') }}
              </label>
            </div>
          </div>
          <div v-if="isFiltersActive" class="mb-1">
            <WButton secondary outline sm
              @click="resetAllFilters"
            >
              <i class="fa fa-close"></i>
              {{ $t('general.resetAllFilters') }}
            </WButton>
          </div>

        </div>
<!--        <div class="pl-1">-->
<!--          <button class="btn btn-info btn-md" @click="openTemplateWizard" v-if="!isDisable">-->
<!--            {{ $t('template.addTemplate') }}-->
<!--          </button>-->
<!--        </div>-->
      </div>
    </div>

    <div class="col-lg-12" v-if="isNotWizard">
      <section class="templates" :class="{ 'edit-visible': editVisible }">
        <section class="templates-list animated fadeIn">
          <div class="row sticky-top">
            <div class="col-lg-12">
              <div class="card">
                <!--                <div class="loader loader-backdrop loader-backdrop-templates" v-if="isCpeTemplatesLoading"></div>-->
                <div v-if="isCpeTemplatesLoading || isCompactWlansWithoutClientsLoading"><Loader-spinner /></div>
                <div class="card-header">
                  {{ $t('template.CPEsTemplates') }}
                </div>
                <div class="card-block">
                  <table class="table">
                    <thead>
                      <tr>
                        <th>{{ $t('general.name') }}</th>
                        <th>{{ $t('general.model') }}</th>
                        <th>{{ $t('template.tableCPEsUUID') }}</th>
                        <th v-if="!editVisible">{{ $t('general.Auto') }}</th>
                        <th v-if="!editVisible">{{ $t('general.Always') }}</th>
                        <th v-if="areLocationsVisible">{{ $t('general.baseLocation') }}</th>
                        <th v-if="!editVisible && !isDisable"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(template, index) in filterItems(cpeTemplatesList)"
                        :data-index="index"
                        :data-id="template.id"
                        class="table-row"
                        @click="toggleEdit"
                        :class="{ selected: template.id === editVisible }"
                      >
                        <td>{{ template.name }}</td>
                        <td>
                          <span v-if="template.model_description">{{ template.model_description }}</span>
                          <span class="text-muted" v-else>{{ $t('general.noModel') }}</span>
                        </td>
                        <td>
                          <span
                            v-if="template.cpes.length"
                            class="badge badge-light badge-success"
                            v-tooltip.top-center="{ content: formatUuids(template.cpes) }"
                          >
                            <img class="img img-responsive cpe-badge" src="/static/img/router-icon-white.svg" alt="" />
                            {{ template.cpes.length }}
                          </span>
                          <span v-else class="badge mr-q badge-light badge-default">
                            <img class="img img-responsive cpe-badge" src="/static/img/router-icon-white.svg" alt="" />
                            0
                          </span>
                        </td>
                        <td v-if="!editVisible">
                          <span class="badge mr-q badge-light badge-success" v-if="template.is_auto">
                            {{ $t('template.tableOn') }}
                          </span>
                          <span class="badge mr-q badge-light badge-default" v-else>{{ $t('template.tableOff') }}</span>
                        </td>
                        <td v-if="!editVisible">
                          <span class="badge mr-q badge-light badge-success" v-if="template.is_always">
                            {{ $t('template.tableOn') }}
                          </span>
                          <span class="badge mr-q badge-light badge-default" v-else>{{ $t('template.tableOff') }}</span>
                        </td>
                        <td v-if="areLocationsVisible" class="responsive-row">{{ locationName(template.base_location) }}</td>
                        <td class="action-row" v-if="!editVisible && !isDisable">
                          <i
                            class="fa fa-remove fa-lg mr-q text-danger user-action"
                            data-action="delete"
                            @click="openDeletingDialog(template.id)"
                          ></i>
                        </td>
                      </tr>
                      <tr v-if="!filterItems(cpeTemplatesList).length">
                        <td colspan="6" class="no-data-row">
                          <span class="text-muted">{{ $t('aps.noDataToDisplay') }}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="template-edit" v-if="editVisible && templateEditCurrent">
          <div class="row sticky-top">
            <div class="col-lg-12">
              <div class="card">
                <div class="card-header pb-0">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="edit-header">
                        <div class="edit-header__info">
                          <div class="edit-header__name">
                            <span class="h4">{{ templateEditCurrent.name }}</span>
                          </div>
                          <div class="edit-header__tags"></div>
                          <WButton secondary outline sm
                            @click="disableEditMode"
                          >
                            <i class="fa fa-close"></i>
                          </WButton>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-block pt-0">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="">
                        <span class="" v-if="modelObj(updatedTemplate.model)">
                          {{ modelObj(updatedTemplate.model).description }}
                        </span>
                        <span class="text-muted" v-else>{{ $t('general.noModel') }}</span>
                      </div>
                      <div class="row">
                        <div class="col-lg-12">
                          <small class="">ID:</small>
                          <small class="text-muted">{{ updatedTemplate.id }}</small>
                          <br />
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-lg-12">
                          <div class="form-group">
                            <div class="h5 mt-1 mb-1">
                              <!--Template-->
                            </div>
                            <label for="name">{{ $t('aps.name') }}</label>
                            <input
                              type="text"
                              id="name"
                              :disabled="isDisable"
                              placeholder="Enter cpe name"
                              name="name"
                              v-validate="'max:30'"
                              class="form-control"
                              :class="{ input: true, 'is-danger': errors.has('name') }"
                              v-model="updatedTemplate.name"
                            />
                            <span v-show="errors.has('name')" class="help is-danger">{{ errors.first('name') }}</span>
                          </div>

                          <div class="form-group mt-1">
                            <label for="description">{{ $t('aps.description') }}</label>
                            <input
                              type="text"
                              id="description"
                              :placeholder="$t('general.description')"
                              name="description"
                              :disabled="isDisable"
                              v-validate="'max:200'"
                              class="form-control"
                              :class="{ input: true, 'is-danger': errors.has('description') }"
                              v-model="updatedTemplate.description"
                            />
                            <span v-show="errors.has('description')" class="help is-danger">
                              {{ errors.first('description') }}
                            </span>
                          </div>

                          <div class="form-group" v-if="areLocationsVisible">
                            <label for="base_location">{{ $t('aps.location') }}</label>
                            <select
                              @change="changeLocationForTemplateHandler"
                              v-model="updatedTemplate.base_location"
                              name="location"
                              class="form-control"
                              id="base_location"
                              :class="{ 'select-disabled': isDisable }"
                              :disabled="isDisable"
                            >
                              <option v-for="location in locationsList" :key="location.id" :value="location.id">
                                {{ location.name }}
                              </option>
                            </select>
                            <span class="small text-muted">{{$t('template.locationChangeHint')}}</span>
                          </div>

                          <label for="name">UUID</label>
                          <div class="input-group">
                            <input
                              type="text"
                              name="uuid"
                              class="form-control input-tag"
                              :placeholder="$t('template.templateAddUUIDPlaceHolder')"
                              data-vv-validate-on="input"
                              v-validate="'uuid'"
                              v-model="newUuid"
                              :disabled="isDisable"
                              @keyup.13="addUUIID"
                              :class="{ input: true, 'is-danger': errors.has('uuid') }"
                            />
                            <div class="input-group-btn">
                              <WButton success
                                @click="addUUIID"
                                :disabled="this.newUuid == '' || isDisable || errors.has('uuid')"
                              >
                                {{ $t('general.add') }}
                              </WButton>
                            </div>
                            <div class="input-group-btn fileUpload ml-1" v-tooltip.top-center="{ content: getCsvTip }">
                              <WButton
                                :info="!(fileTypeError || invalidUuid)"
                                :danger="fileTypeError || invalidUuid"
                                :disabled="isDisable"
                              >
                                <i class="fa fa-file-o"></i>
                              </WButton>
                              <input
                                id="csv"
                                @change="previewUuids"
                                name="csv"
                                :disabled="isDisable"
                                type="file"
                                class="upload btn btn-info mb-0"
                              />
                            </div>
                          </div>
                          <span v-if="fileTypeError" class="help is-danger">
                            {{ $t('template.fieldMustBeCsvFile') }}
                          </span>
                          <span v-if="invalidUuid" class="help is-danger">
                            {{ $t('template.wrongDataInFile') }}
                          </span>

                          <div class="mt-1">
                            <p
                              class="m-0 uuid-block"
                              v-if="updatedTemplate.cpes.length"
                              :data-index="index"
                              @click.prevent="removeUuid"
                              v-for="(uuid, index) in updatedTemplate.cpes"
                            >
                              <i class="fa fa-remove text-danger" v-if="!isDisable"></i>
                              <span class="uuid">{{ uuid }}</span>
                            </p>
                          </div>
                          <div v-if="!updatedTemplate.cpes.length">
                            <small class="text-muted">
                              {{ $t('template.allUUIDs') }}
                            </small>
                          </div>

                          <div class="form-group mt-2">
                            <label for="ipaddr">{{$t('general.ipField')}}</label>
                            <input
                              type="text"
                              id="ipaddr"
                              v-validate="'ipv4WithSpecialOctetsRanges'"
                              placeholder="X.X.X.X"
                              name="ipaddr"
                              :disabled="isDisable"
                              class="form-control"
                              :class="{ input: true, 'is-danger': errors.has('ipaddr') }"
                              v-model="updatedTemplate.subnet.ipaddr"
                            />
                            <small>{{ $t('general.ipFieldHint') }}</small>
                            <span v-show="errors.has('ipaddr')" class="help is-danger">
                              {{ errors.first('ipaddr') }}
                            </span>
                          </div>

                          <div class="form-group">
                            <label for="ipaddr">{{ $t('template.networkMask') }}</label>
                            <input
                              type="text"
                              id="netmask"
                              v-validate="'subnetMask'"
                              placeholder="X.X.X.X"
                              name="netmask"
                              :disabled="isDisable"
                              class="form-control"
                              :class="{ input: true, 'is-danger': errors.has('netmask') }"
                              v-model="updatedTemplate.subnet.netmask"
                            />
                            <small>{{ $t('template.networkMaskHint') }}</small>
                            <span v-show="errors.has('netmask')" class="help is-danger">
                              {{ errors.first('netmask') }}
                            </span>
                          </div>

                          <div class="form-group">
                            <label for="mac_prefix">{{ $t('template.macPrefix') }}</label>
                            <input
                              type="text"
                              id="mac_prefix"
                              placeholder="XX:XX:XX:XX:XX:XX"
                              v-validate="'macPrefix'"
                              name="mac_prefix"
                              :disabled="isDisable"
                              class="form-control"
                              :class="{ input: true, 'is-danger': errors.has('mac_prefix') }"
                              v-model="updatedTemplate.mac_prefix"
                            />
                            <small>{{ $t('template.macPrefixHint') }}</small>
                            <span v-show="errors.has('mac_prefix')" class="help is-danger">
                              {{ errors.first('mac_prefix') }}
                            </span>
                          </div>

                          <div class="form-group mb-2 mt-2">
                            <Switch-component
                              v-model="updatedTemplate.is_auto"
                              :disabled="isDisable"
                              :label="$t('template.autoConfiguration')"
                              @change="checkAuto"
                              id="templates-is-auto"
                              key="templates-is-auto"
                            />
                          </div>

                          <div class="form-group mb-2 mt-2">
                            <Switch-component
                              v-model="updatedTemplate.is_always"
                              :disabled="!updatedTemplate.is_auto || isDisable"
                              :label="$t('template.alwaysApply')"
                              @change="checkAuto"
                              id="templates-always-apply"
                              key="templates-always-apply"
                            />
                          </div>

                          <hr />

                          <div class="h5 mt-2 mb-1">
                            {{ $t('template.configGeneral') }}
                          </div>

                          <div
                            class="form-group mt-1"
                            v-if="
                              isBeelineBranding &&
                              updatedTemplate.template.cpe_config_template &&
                              updatedTemplate.template.cpe_config_template.beeline_config
                            "
                          >
                            <label for="beeline-nas-ip">{{ $t('aps.nasIp') }}</label>
                            <input
                              type="text"
                              id="beeline-nas-ip"
                              placeholder="0.0.0.0"
                              name="beeline-nas-ip"
                              v-validate="'ipv4'"
                              class="form-control"
                              :class="{ input: true, 'is-danger': errors.has('beeline-nas-ip') }"
                              v-model="updatedTemplate.template.cpe_config_template.beeline_config.nas_ip"
                            />
                            <span v-show="errors.has('beeline-nas-ip')" class="help is-danger">
                              {{ errors.first('beeline-nas-ip') }}
                            </span>
                          </div>

                          <div class="form-group mb-2" v-if="areLocationsVisible">
                            <label for="location">{{ $t('general.location') }}</label>
                            <select
                               @change="changeLocationForTemplateCpeHandler"
                              v-model="updatedTemplate.template.location"
                              name="location"
                              class="form-control"
                              id="location"
                              :class="{ 'select-disabled': isDisable }"
                              :disabled="isDisable"
                            >
                              >
                              <option v-for="location in locationsList" :value="location.id" :key="location.id">
                                {{ location.name }}
                              </option>
                            </select>
                            <span class="small text-muted">{{$t('template.locationChangeHint')}}</span>
                          </div>

                          <section v-if="cpeModelData">
                            <span
                              v-if="
                                updatedTemplate.template.cpe_config_template.wifi &&
                                updatedTemplate.template.cpe_config_template.wifi.length
                              "
                              class="h6 setting-title"
                            >
                              {{ $t('template.configRadio') }}
                            </span>
                            <!---->
                            <div
                              class="form-group interface-block mb-1 mt-2"
                              v-for="cpeInterface in updatedTemplate.template.cpe_config_template.wifi"
                              :data-id="cpeInterface.id"
                            >
                              <p class="mb-0 h6" @click.prevent="showId">
                                <img class="interface-block-icon" src="/static/img/radio.svg" />
                                <strong>{{ cpeInterface.id }}</strong>
                                <span class="badge badge-success ml-1 frequency-title">
                                  <span
                                    v-if="
                                      cpeInterface &&
                                      (cpeInterface.frequency === '2.4' || cpeInterface.frequency === '5')
                                    "
                                  >
                                    {{ cpeInterface.frequency }} {{ $t('aps.ghz') }}
                                  </span>
                                </span>
                                <WButton link
                                         custom-class="float-right"
                                  v-if="!interfaceSettings[cpeInterface.id]"
                                >
                                  <i class="icon-arrow-left mr-1"></i>
                                  <i class="icon-settings"></i>
                                </WButton>
                                <WButton link
                                         custom-class="float-right"
                                  v-if="interfaceSettings[cpeInterface.id]"
                                >
                                  <i class="icon-arrow-down mr-1"></i>
                                  <i class="icon-settings"></i>
                                </WButton>
                              </p>
                              <div class="mb-1">
                                <small class="text-xs">
                                  {{ $t('aps.mode') }}:
                                  <span class="radio-data">
                                    {{ interfaceCapabilities[cpeInterface.id].selected.bandmode }},
                                  </span>
                                  {{ $t('aps.width') }}:
                                  <span class="radio-data">
                                    {{ interfaceCapabilities[cpeInterface.id].selected.bandwidth }},
                                  </span>
                                  {{ $t('aps.channel') }}:
                                  <span class="radio-data">
                                    <span
                                      v-if="interfaceCapabilities[cpeInterface.id].selected.channels.length"
                                      v-for="channel in interfaceCapabilities[cpeInterface.id].selected.channels"
                                    >
                                      {{ channel }},
                                    </span>
                                    <span v-if="!interfaceCapabilities[cpeInterface.id].selected.channels.length">
                                      Auto,
                                    </span>
                                  </span>
                                  {{ $t('aps.txPower') }}:
                                  <span
                                    class="radio-data"
                                    v-if="interfaceCapabilities[cpeInterface.id].selected.power.auto"
                                  >
                                    {{ $t('aps.auto') }}
                                  </span>
                                  <span class="radio-data" v-else>
                                    {{ interfaceCapabilities[cpeInterface.id].selected.power.range[0] }} -
                                    {{ interfaceCapabilities[cpeInterface.id].selected.power.range[1] }}
                                    {{ $t('aps.dbm') }}
                                  </span>
                                  {{ $t('aps.country') }}:
                                  <span class="radio-data">
                                    {{ interfaceCapabilities[cpeInterface.id].selected.country }},
                                  </span>
                                  {{ $t('aps.rateControl') }}:
                                  <span class="radio-data">
                                    {{ interfaceCapabilities[cpeInterface.id].selected.require_mode }},
                                  </span>
                                  {{ $t('aps.backgroundScaning') }}:
                                  <span
                                    class="radio-data"
                                    v-if="interfaceCapabilities[cpeInterface.id].selected.scanningconfig.enabled"
                                  >
                                    {{ $t('template.tableOn') }},
                                  </span>
                                  <span class="radio-data" v-else>{{ $t('template.tableOff') }},</span>
                                  {{ $t('aps.maxClients') }}:
                                  <span
                                    class="radio-data"
                                    v-if="interfaceCapabilities[cpeInterface.id].selected.maxclients"
                                  >
                                    {{ interfaceCapabilities[cpeInterface.id].selected.maxclients }}
                                  </span>
                                  <span class="radio-data" v-else>{{ $t('aps.unlim') }}</span>
                                </small>
                              </div>
                              <transition name="interface">
                                <section v-if="interfaceSettings[cpeInterface.id]">
                                  <span class="h5">{{ $t('aps.cpeEditRadioSettings') }}</span>
                                  <div
                                    class="text-danger select-channels-less-or-more-in-interface-settings"
                                    v-if="isCpeModelYuncoreXd6800">
                                    <div v-if="cpeInterface.id === 'radio0'">
                                      {{$t('aps.selectChannelsMoreThanForCorrectWork')}}&nbsp;100
                                    </div>
                                    <div v-if="cpeInterface.id === 'radio2'">
                                      {{$t('aps.selectChannelsLessThanForCorrectWork')}}&nbsp;100
                                    </div>
                                  </div>
                                  <div
                                    class="form-group mt-1"
                                    v-if="
                                      cpeModelData.caps.wifi &&
                                      cpeModelData.caps.wifi[cpeInterface.id].frequency === `Both`
                                    "
                                  >
                                    <label for="frequency">Frequency</label>
                                    <select
                                      id="frequency"
                                      class="form-control"
                                      :disabled="isDisable"
                                      :class="{ 'select-disabled': isDisable }"
                                      @change="
                                        handleFrequencyChange(
                                          interfaceCapabilities[cpeInterface.id].selected,
                                          interfaceCapabilities[cpeInterface.id].capabilities,
                                          cpeInterface.id
                                        )
                                      "
                                      v-model="interfaceCapabilities[cpeInterface.id].selected.frequency"
                                    >
                                      <option>
                                        2.4
                                      </option>
                                      <option>
                                        5
                                      </option>
                                    </select>
                                  </div>

                                  <div class="form-group mt-1">
                                    <label for="bandmode">{{ $t('aps.bandMode') }}</label>
                                    <select
                                      id="bandmode"
                                      class="form-control"
                                      :class="{ 'select-disabled': isDisable }"
                                      :disabled="isDisable"
                                      @change="
                                        handleModeChange(
                                          interfaceCapabilities[cpeInterface.id].selected,
                                          interfaceCapabilities[cpeInterface.id].capabilities,
                                          cpeInterface.id
                                        )
                                      "
                                      v-model="interfaceCapabilities[cpeInterface.id].selected.bandmode"
                                    >
                                      <option
                                        v-for="(key, value) in interfaceCapabilities[cpeInterface.id].capabilities
                                          .hwmodelist"
                                        :selected="
                                          interfaceCapabilities[cpeInterface.id].selected.bandmode === '11' + value
                                        "
                                        :value="'11' + value"
                                        v-if="
                                          isModeAllowed(
                                            interfaceCapabilities[cpeInterface.id].selected.frequency,
                                            value,
                                            interfaceCapabilities[cpeInterface.id].capabilities.hwmodelist
                                          )
                                        "
                                      >
                                        {{ '11' + value }}
                                      </option>
                                    </select>
                                  </div>

                                  <div class="form-group" v-if="calculatedCapabilities[cpeInterface.id]">
                                    <label for="bandwidth">{{ $t('aps.bandwidth') }}</label>
                                    <select
                                      id="bandwidth"
                                      class="form-control"
                                      v-model="interfaceCapabilities[cpeInterface.id].selected.bandwidth"
                                      :class="{ 'select-disabled': isDisable }"
                                      :disabled="isDisable"
                                      @change="clearChanList(cpeInterface.id)"
                                    >
                                      <option
                                        v-for="bandwidth in calculatedCapabilities[cpeInterface.id].bandwidth"
                                        :key="bandwidth"
                                        :selected="
                                          interfaceCapabilities[cpeInterface.id].selected.bandwidth == bandwidth
                                        "
                                        :value="bandwidth"
                                      >
                                        <span
                                          v-if="
                                            bandwidth === 'HT40' &&
                                            interfaceCapabilities[cpeInterface.id].selected.frequency === '2.4'
                                          "
                                        >
                                          HT40+
                                        </span>
                                        <span v-else>{{ bandwidth }}</span>
                                      </option>
                                    </select>
                                  </div>

<!--                                  &lt;!&ndash;Channels&ndash;&gt;-->

<!--                                  <label for="channel">-->
<!--                                    {{ cpeModelData.cisco ? $t('aps.channel') : $t('aps.channels') }}-->
<!--                                  </label>-->
<!--                                  <div-->
<!--                                    class="form-group"-->
<!--                                    v-if="-->
<!--                                      calculatedCapabilities[cpeInterface.id] &&-->
<!--                                      interfaceCapabilities[cpeInterface.id].selected.frequency === '2.4'-->
<!--                                    "-->
<!--                                  >-->
<!--                                    <div class="channel-list">-->
<!--                                      <div-->
<!--                                        class="channel-list-item interface-channel-trapezoid"-->
<!--                                        v-for="channel in calculatedCapabilities[cpeInterface.id].channels"-->
<!--                                        :class="{-->
<!--                                          disabled: isDisable,-->
<!--                                          active: interfaceCapabilities[cpeInterface.id].selected.channels.includes(-->
<!--                                            channel-->
<!--                                          )-->
<!--                                        }"-->
<!--                                        v-if="channel != 'auto'"-->
<!--                                      >-->
<!--                                        <input-->
<!--                                          type="checkbox"-->
<!--                                          :disabled="isDisable"-->
<!--                                          class="interface-channel-checkbox"-->
<!--                                          :value="channel"-->
<!--                                          :id="`${cpeInterface.id}-${channel}`"-->
<!--                                          @change="-->
<!--                                            handlerChannels(-->
<!--                                              cpeModelData.cisco,-->
<!--                                              interfaceCapabilities[cpeInterface.id].selected.channels,-->
<!--                                              channel,-->
<!--                                              interfaceCapabilities[cpeInterface.id].selected.channels.includes(-->
<!--                                                channel-->
<!--                                              ),-->
<!--                                              cpeInterface.id-->
<!--                                            )-->
<!--                                          "-->
<!--                                          v-model="interfaceCapabilities[cpeInterface.id].selected.channels"-->
<!--                                        />-->
<!--                                        <label-->
<!--                                          class=""-->
<!--                                          :class="{ 'label-disabled': isDisable }"-->
<!--                                          :for="`${cpeInterface.id}-${channel}`"-->
<!--                                        >-->
<!--                                          {{ channel }}-->
<!--                                        </label>-->
<!--                                      </div>-->
<!--                                    </div>-->
<!--                                  </div>-->

<!--                                  <div-->
<!--                                    class="form-group"-->
<!--                                    v-if="-->
<!--                                      calculatedCapabilities[cpeInterface.id] &&-->
<!--                                      interfaceCapabilities[cpeInterface.id].selected.frequency === '5' &&-->
<!--                                      (interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'HT20' ||-->
<!--                                        interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'VHT20' ||-->
<!--                                        interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'auto')-->
<!--                                    "-->
<!--                                  >-->
<!--                                    <div class="channel-list">-->
<!--                                      <div-->
<!--                                        class="channel-list-item interface-channel-trapezoid"-->
<!--                                        v-for="channel in calculatedCapabilities[cpeInterface.id].channels"-->
<!--                                        :class="{-->
<!--                                          disabled: isDisable,-->
<!--                                          active: interfaceCapabilities[cpeInterface.id].selected.channels.includes(-->
<!--                                            channel-->
<!--                                          )-->
<!--                                        }"-->
<!--                                        v-if="channel != 'auto'"-->
<!--                                      >-->
<!--                                        <input-->
<!--                                          type="checkbox"-->
<!--                                          :id="`${cpeInterface.id}-${channel}`"-->
<!--                                          :disabled="isDisable"-->
<!--                                          class="interface-channel-checkbox"-->
<!--                                          :value="channel"-->
<!--                                          @change="-->
<!--                                            handlerChannels(-->
<!--                                              cpeModelData.cisco,-->
<!--                                              interfaceCapabilities[cpeInterface.id].selected.channels,-->
<!--                                              channel,-->
<!--                                              interfaceCapabilities[cpeInterface.id].selected.channels.includes(-->
<!--                                                channel-->
<!--                                              ),-->
<!--                                              cpeInterface.id-->
<!--                                            )-->
<!--                                          "-->
<!--                                          v-model="interfaceCapabilities[cpeInterface.id].selected.channels"-->
<!--                                        />-->
<!--                                        <label-->
<!--                                          class=""-->
<!--                                          :class="{ 'label-disabled': isDisable }"-->
<!--                                          :for="`${cpeInterface.id}-${channel}`"-->
<!--                                        >-->
<!--                                          {{ channel }}-->
<!--                                        </label>-->
<!--                                      </div>-->
<!--                                    </div>-->
<!--                                  </div>-->

<!--                                  <div-->
<!--                                    class="form-group"-->
<!--                                    v-if="-->
<!--                                      calculatedCapabilities[cpeInterface.id] &&-->
<!--                                      interfaceCapabilities[cpeInterface.id].selected.frequency === '5' &&-->
<!--                                      (interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'HT40' ||-->
<!--                                        interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'VHT40')-->
<!--                                    "-->
<!--                                  >-->
<!--                                    <div class="channel-list">-->
<!--                                      <div-->
<!--                                        class="channel-list-item interface-channel-trapezoid__ht40"-->
<!--                                        v-for="channel in calculatedCapabilities[cpeInterface.id].channels"-->
<!--                                        :class="{-->
<!--                                          disabled: isDisable,-->
<!--                                          active: interfaceCapabilities[cpeInterface.id].selected.channels.includes(-->
<!--                                            channel-->
<!--                                          )-->
<!--                                        }"-->
<!--                                        v-if="channel != 'auto'"-->
<!--                                      >-->
<!--                                        <input-->
<!--                                          type="checkbox"-->
<!--                                          :id="`${cpeInterface.id}-${channel}`"-->
<!--                                          :disabled="isDisable"-->
<!--                                          class="interface-channel-checkbox"-->
<!--                                          :value="channel"-->
<!--                                          @change="-->
<!--                                            handlerChannels(-->
<!--                                              cpeModelData.cisco,-->
<!--                                              interfaceCapabilities[cpeInterface.id].selected.channels,-->
<!--                                              channel,-->
<!--                                              interfaceCapabilities[cpeInterface.id].selected.channels.includes(-->
<!--                                                channel-->
<!--                                              ),-->
<!--                                              cpeInterface.id-->
<!--                                            )-->
<!--                                          "-->
<!--                                          v-model="interfaceCapabilities[cpeInterface.id].selected.channels"-->
<!--                                        />-->
<!--                                        <label-->
<!--                                          class=""-->
<!--                                          :class="{ 'label-disabled': isDisable }"-->
<!--                                          :for="`${cpeInterface.id}-${channel}`"-->
<!--                                        >-->
<!--                                          {{ channel }} {{ channel + 4 }}-->
<!--                                        </label>-->
<!--                                      </div>-->
<!--                                    </div>-->
<!--                                  </div>-->

<!--                                  <div-->
<!--                                    class="form-group"-->
<!--                                    v-if="-->
<!--                                      calculatedCapabilities[cpeInterface.id] &&-->
<!--                                      interfaceCapabilities[cpeInterface.id].selected.frequency === '5' &&-->
<!--                                      interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'VHT80'-->
<!--                                    "-->
<!--                                  >-->
<!--                                    <div class="channel-list">-->
<!--                                      <div-->
<!--                                        class="channel-list-item interface-channel-trapezoid__ht80"-->
<!--                                        v-for="channel in calculatedCapabilities[cpeInterface.id].channels"-->
<!--                                        :class="{-->
<!--                                          active: interfaceCapabilities[cpeInterface.id].selected.channels.includes(-->
<!--                                            channel-->
<!--                                          )-->
<!--                                        }"-->
<!--                                        v-if="channel != 'auto'"-->
<!--                                      >-->
<!--                                        <input-->
<!--                                          type="checkbox"-->
<!--                                          :id="`${cpeInterface.id}-${channel}`"-->
<!--                                          :disabled="isDisable"-->
<!--                                          class="interface-channel-checkbox"-->
<!--                                          :value="channel"-->
<!--                                          @change="-->
<!--                                            handlerChannels(-->
<!--                                              cpeModelData.cisco,-->
<!--                                              interfaceCapabilities[cpeInterface.id].selected.channels,-->
<!--                                              channel,-->
<!--                                              interfaceCapabilities[cpeInterface.id].selected.channels.includes(-->
<!--                                                channel-->
<!--                                              ),-->
<!--                                              cpeInterface.id-->
<!--                                            )-->
<!--                                          "-->
<!--                                          v-model="interfaceCapabilities[cpeInterface.id].selected.channels"-->
<!--                                        />-->
<!--                                        <label class="" :for="`${cpeInterface.id}-${channel}`">-->
<!--                                          {{ channel }} {{ channel + 4 }} {{ channel + 8 }} {{ channel + 12 }}-->
<!--                                        </label>-->
<!--                                      </div>-->
<!--                                    </div>-->
<!--                                  </div>-->
<!--                                  &lt;!&ndash;End channels&ndash;&gt;-->

                                                                    <!--Channels-->

                                  <label for="channel">
                                    {{ cpeModelData.cisco ? $t('aps.channel') : $t('aps.channels') }}
                                  </label>
                                  <div
                                    class="form-group"
                                    v-if="
                                      calculatedCapabilities[cpeInterface.id] &&
                                      interfaceCapabilities[cpeInterface.id].selected.frequency === '2.4'
                                    "
                                  >
                                    <div class="channel-list">
                                      <div
                                        class="channel-list-item interface-channel-trapezoid"
                                        v-for="channel in calculatedCapabilities[cpeInterface.id].channels"
                                        :class="{
                                          disabled: isDisable,
                                          active: interfaceCapabilities[cpeInterface.id].selected.channels.includes(
                                            channel
                                          )
                                        }"
                                        v-if="channel != 'auto'"
                                      >
                                        <input
                                          type="checkbox"
                                          :disabled="isDisable"
                                          class="interface-channel-checkbox"
                                          :value="channel"
                                          :id="`${cpeInterface.id}-${channel}`"
                                          @change="
                                            handlerChannels(
                                              cpeModelData.cisco,
                                              interfaceCapabilities[cpeInterface.id].selected.channels,
                                              channel,
                                              interfaceCapabilities[cpeInterface.id].selected.channels.includes(
                                                channel
                                              ),
                                              cpeInterface.id
                                            )
                                          "
                                          v-model="interfaceCapabilities[cpeInterface.id].selected.channels"
                                        />
                                        <label
                                          class=""
                                          :class="{ 'label-disabled': isDisable }"
                                          :for="`${cpeInterface.id}-${channel}`"
                                        >
                                          {{ channel }}
                                        </label>
                                      </div>
                                    </div>
                                  </div>

                                  <div
                                    class="form-group"
                                    v-if="
                                      calculatedCapabilities[cpeInterface.id] &&
                                      interfaceCapabilities[cpeInterface.id].selected.frequency === '5' &&
                                      (interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'HT20' ||
                                      interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'HE20' ||
                                        interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'VHT20' ||
                                        interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'auto')
                                    "
                                  >
                                    <div class="channel-list">
                                      <div
                                        class="channel-list-item interface-channel-trapezoid"
                                        v-for="channel in calculatedCapabilities[cpeInterface.id].channels"
                                        :class="{
                                          disabled: isDisable,
                                          active: interfaceCapabilities[cpeInterface.id].selected.channels.includes(
                                            channel
                                          )
                                        }"
                                        v-if="channel != 'auto'"
                                      >
                                        <input
                                          type="checkbox"
                                          :id="`${cpeInterface.id}-${channel}`"
                                          :disabled="isDisable"
                                          class="interface-channel-checkbox"
                                          :value="channel"
                                          @change="
                                            handlerChannels(
                                              cpeModelData.cisco,
                                              interfaceCapabilities[cpeInterface.id].selected.channels,
                                              channel,
                                              interfaceCapabilities[cpeInterface.id].selected.channels.includes(
                                                channel
                                              ),
                                              cpeInterface.id
                                            )
                                          "
                                          v-model="interfaceCapabilities[cpeInterface.id].selected.channels"
                                        />
                                        <label
                                          class=""
                                          :class="{ 'label-disabled': isDisable }"
                                          :for="`${cpeInterface.id}-${channel}`"
                                        >
                                          {{ channel }}
                                        </label>
                                      </div>
                                    </div>
                                  </div>

                                  <div
                                    class="form-group"
                                    v-if="
                                      calculatedCapabilities[cpeInterface.id] &&
                                      interfaceCapabilities[cpeInterface.id].selected.frequency === '5' &&
                                      (interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'HT40' ||
                                       interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'HE40' ||
                                        interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'VHT40')
                                    "
                                  >
                                    <div class="channel-list">
                                      <div
                                        class="channel-list-item interface-channel-trapezoid__ht40"
                                        v-for="channel in calculatedCapabilities[cpeInterface.id].channels"
                                        :class="{
                                          disabled: isDisable,
                                          active: interfaceCapabilities[cpeInterface.id].selected.channels.includes(
                                            channel
                                          )
                                        }"
                                        v-if="channel != 'auto'"
                                      >
                                        <input
                                          type="checkbox"
                                          :id="`${cpeInterface.id}-${channel}`"
                                          :disabled="isDisable"
                                          class="interface-channel-checkbox"
                                          :value="channel"
                                          @change="
                                            handlerChannels(
                                              cpeModelData.cisco,
                                              interfaceCapabilities[cpeInterface.id].selected.channels,
                                              channel,
                                              interfaceCapabilities[cpeInterface.id].selected.channels.includes(
                                                channel
                                              ),
                                              cpeInterface.id
                                            )
                                          "
                                          v-model="interfaceCapabilities[cpeInterface.id].selected.channels"
                                        />
                                        <label
                                          class=""
                                          :class="{ 'label-disabled': isDisable }"
                                          :for="`${cpeInterface.id}-${channel}`"
                                        >
                                          {{ channel }} {{ channel + 4 }}
                                        </label>
                                      </div>
                                    </div>
                                  </div>

                                  <div
                                    class="form-group"
                                    v-if="
                                      calculatedCapabilities[cpeInterface.id] &&
                                      interfaceCapabilities[cpeInterface.id].selected.frequency === '5' &&
                                      (interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'VHT80' ||
                                      interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'HE80')
                                    "
                                  >
                                    <div class="channel-list">
                                      <div
                                        class="channel-list-item interface-channel-trapezoid__ht80"
                                        v-for="channel in calculatedCapabilities[cpeInterface.id].channels"
                                        :class="{
                                          active: interfaceCapabilities[cpeInterface.id].selected.channels.includes(
                                            channel
                                          )
                                        }"
                                        v-if="channel != 'auto'"
                                      >
                                        <input
                                          type="checkbox"
                                          :id="`${cpeInterface.id}-${channel}`"
                                          :disabled="isDisable"
                                          class="interface-channel-checkbox"
                                          :value="channel"
                                          @change="
                                            handlerChannels(
                                              cpeModelData.cisco,
                                              interfaceCapabilities[cpeInterface.id].selected.channels,
                                              channel,
                                              interfaceCapabilities[cpeInterface.id].selected.channels.includes(
                                                channel
                                              ),
                                              cpeInterface.id
                                            )
                                          "
                                          v-model="interfaceCapabilities[cpeInterface.id].selected.channels"
                                        />
                                        <label class="" :for="`${cpeInterface.id}-${channel}`">
                                          {{ channel }} {{ channel + 4 }} {{ channel + 8 }} {{ channel + 12 }}
                                        </label>
                                      </div>
                                    </div>
                                  </div>


                                  <div
                                    class="form-group"
                                    v-if="
                                      calculatedCapabilities[cpeInterface.id] &&
                                      interfaceCapabilities[cpeInterface.id].selected.frequency === '5' &&
                                      (
                                  interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'VHT80+80' ||
                                  interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'VHT160' ||
                                  interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'HE80+80' ||
                                  interfaceCapabilities[cpeInterface.id].selected.bandwidth === 'HE160')
                                    "
                                  >
                                    <div class="channel-list">
                                      <div
                                        class="channel-list-item interface-channel-trapezoid__ht80"
                                        v-for="channel in calculatedCapabilities[cpeInterface.id].channels"
                                        :class="{
                                          active: interfaceCapabilities[cpeInterface.id].selected.channels.includes(
                                            channel
                                          )
                                        }"
                                        v-if="channel != 'auto'"
                                      >
                                        <input
                                          type="checkbox"
                                          :id="`${cpeInterface.id}-${channel}`"
                                          :disabled="isDisable"
                                          class="interface-channel-checkbox"
                                          :value="channel"
                                          @change="
                                            handlerChannels(
                                              cpeModelData.cisco,
                                              interfaceCapabilities[cpeInterface.id].selected.channels,
                                              channel,
                                              interfaceCapabilities[cpeInterface.id].selected.channels.includes(
                                                channel
                                              ),
                                              cpeInterface.id
                                            )
                                          "
                                          v-model="interfaceCapabilities[cpeInterface.id].selected.channels"
                                        />
                                        <label class="" :for="`${cpeInterface.id}-${channel}`">
                                                                                {{ channel }}
                                      {{ channel + 4 }} {{ channel + 8 }} {{ channel + 12 }} {{ channel + 16 }} {{ channel + 20 }} {{ channel + 24 }} {{ channel + 28 }}
                                        </label>
                                      </div>
                                    </div>
                                  </div>



                                  <!--End channels-->

                                  <div
                                    class="form-group mb-2"
                                    v-if="
                                      interfaceCapabilities[cpeInterface.id].selected.power &&
                                      interfaceCapabilities[cpeInterface.id].selected.power.range &&
                                      Array.isArray(interfaceCapabilities[cpeInterface.id].selected.power.range)
                                    "
                                  >
                                    <span class="m-0 mt-2">{{ $t('aps.txPowerAdjust') }}</span>
                                    <Switch-component
                                      v-model="interfaceCapabilities[cpeInterface.id].selected.power.auto"
                                      :disabled="isDisable"
                                      :label="$t('general.Auto')"
                                      class="float-right"
                                      :id="`power-auto-${cpeInterface.id}`"
                                      :key="`power-auto-${cpeInterface.id}`"
                                    />

<!--                                    <vue-slider-->
<!--                                      ref="slider"-->
<!--                                      v-if="!isDisable && !interfaceCapabilities[cpeInterface.id].selected.power.auto"-->
<!--                                      :disabled="cpeModelData.cisco"-->
<!--                                      v-bind="TXPower"-->
<!--                                      :max="calculatedCapabilities[cpeInterface.id].txPower"-->
<!--                                      :min="calculatedCapabilities[cpeInterface.id].minTxPower || 0"-->
<!--                                      tooltip-dir="bottom"-->
<!--                                      tooltip="always"-->
<!--                                      formatter="{value} dbm"-->
<!--                                      v-model="interfaceCapabilities[cpeInterface.id].selected.power.range"-->
<!--                                    ></vue-slider>-->
<!--                                    <vue-slider-->
<!--                                      ref="slider"-->
<!--                                      v-else-->
<!--                                      :disabled="true"-->
<!--                                      v-bind="TXPower"-->
<!--                                      :max="calculatedCapabilities[cpeInterface.id].txPower"-->
<!--                                      :min="calculatedCapabilities[cpeInterface.id].minTxPower || 0"-->
<!--                                      tooltip-dir="bottom"-->
<!--                                      tooltip="always"-->
<!--                                      formatter="{value} dbm"-->
<!--                                      v-model="interfaceCapabilities[cpeInterface.id].selected.power.range"-->
<!--                                    ></vue-slider>-->
<!--                                       // изменения для задачи WNE-3314 (необходимо сделать минимальным значением txPower 1 dbm)-->
                                    <vue-slider
                                      ref="slider"
                                      v-if="!isDisable && !interfaceCapabilities[cpeInterface.id].selected.power.auto"
                                      :disabled="cpeModelData.cisco"
                                      v-bind="TXPower"
                                      :max="calculatedCapabilities[cpeInterface.id].txPower"
                                      :min="calculatedCapabilities[cpeInterface.id].minTxPower || 1"
                                      tooltip-dir="bottom"
                                      tooltip="always"
                                      formatter="{value} dbm"
                                      v-model="interfaceCapabilities[cpeInterface.id].selected.power.range"
                                    ></vue-slider>
                                    <vue-slider
                                      ref="slider"
                                      v-else
                                      :disabled="true"
                                      v-bind="TXPower"
                                      :max="calculatedCapabilities[cpeInterface.id].txPower"
                                      :min="calculatedCapabilities[cpeInterface.id].minTxPower || 1"
                                      tooltip-dir="bottom"
                                      tooltip="always"
                                      formatter="{value} dbm"
                                      v-model="interfaceCapabilities[cpeInterface.id].selected.power.range"
                                    ></vue-slider>
                                  </div>
<!--                                  конец изменений по WNE-3314-->

<!--&lt;!&ndash; // todo убрать этот старый input (который теперь заменен select'ом) если не потребуется его восстановить (11.12.2023)&ndash;&gt;-->

<!--                                  <div class="form-group">-->
<!--                                    <label for="country">{{ $t('aps.country') }}</label>-->
<!--                                    <input-->
<!--                                      id="country"-->
<!--                                      @input="changeCountryHandler(cpeInterface.id)"-->
<!--                                      class="form-control"-->
<!--                                      :disabled="isDisable"-->
<!--                                      v-model="interfaceCapabilities[cpeInterface.id].selected.country"-->
<!--                                    />-->
<!--                                  </div>-->

                              <div class="form-group" v-if="!cpeModelData.cisco" >
                                <WSelect
                                  @input="changeCountryHandler(cpeInterface.id)"
                                  v-model="interfaceCapabilities[cpeInterface.id].selected.country"
                                  :options="countriesCodesOptionsForSelectorInInterfaceRadioSettings"
                                  :label="$t('aps.country')"
                                  :disabled="isDisable"
                                  id="country"
                                  customClass="form-control"
                                  />
                              </div>

                                  <div v-if="!cpeModelData.cisco">
                                    <hr class="hr-settings mb-2" />
                                    <span class="h5">{{ $t('aps.advancedSettings') }}</span>

                                    <div
                                      class="form-group mt-2 mb-2"
                                      v-if="rateControl[interfaceCapabilities[cpeInterface.id].selected.bandmode]"
                                    >
                                      <label for="rate-control">
                                        <b>{{ $t('aps.rateControl') }}</b>
                                      </label>
                                      <vue-slider
                                        ref="slider"
                                        id="rate-control"
                                        :disabled="
                                          isDisable ||
                                          (interfaceCapabilities[cpeInterface.id].selected.bandmode != '11n' &&
                                            interfaceCapabilities[cpeInterface.id].selected.bandmode != '11ac')
                                        "
                                        v-bind="rateControl[interfaceCapabilities[cpeInterface.id].selected.bandmode]"
                                        tooltip-dir="bottom"
                                        tooltip="hover"
                                        formatter="{value}"
                                        v-model="interfaceCapabilities[cpeInterface.id].selected.require_mode"
                                      ></vue-slider>
                                    </div>

                                    <div class="form-group mb-0 mt-2">
                                      <Switch-component
                                        v-model="interfaceCapabilities[cpeInterface.id].selected.scanningconfig.enabled"
                                        :disabled="isDisable"
                                        class=""
                                        :label="$t('aps.backgroundScaning')"
                                        id="background-scanning"
                                      />
                                    </div>

                                    <div class="form-group mt-1 mb-0">
                                      <Switch-component
                                        v-model="advancedSettings[cpeInterface.id].maxClientsEnabled"
                                        :disabled="isDisable"
                                        class=""
                                        :label="$t('aps.maxClients')"
                                        id="max-clients"
                                      />
                                    </div>
                                    <div
                                      class="form-group mb-2"
                                      v-if="advancedSettings[cpeInterface.id].maxClientsEnabled"
                                    >
                                      <label for="maxclients">{{ $t('aps.limitperWlan') }}</label>
                                      <input
                                        id="maxclients"
                                        class="form-control"
                                        :disabled="!advancedSettings[cpeInterface.id].maxClientsEnabled"
                                        name="maxclients"
                                        v-validate="'required|numeric'"
                                        :class="{ input: true, 'is-danger': errors.has('description') }"
                                        v-model="interfaceCapabilities[cpeInterface.id].selected.maxclients"
                                      />
                                      <span v-show="errors.has('maxclients')" class="help is-danger">
                                        {{ errors.first('maxclients') }}
                                      </span>
                                    </div>
                                  </div>
                                </section>
                              </transition>

                              <hr class="hr-settings mt-2" />
                              <div class="mt-1">
                                <label>
                                  <strong>{{ $t('template.wizardWLANs') }}</strong>
                                </label>
                                <a
                                  href="#"
                                  class="ml-q pull-right"
                                  @click.prevent="isWlanDescription = !isWlanDescription"
                                >
                                  <span v-if="!isWlanDescription">{{ $t('aps.showDescription') }}</span>
                                  <span v-else>{{ $t('aps.hideDescription') }}</span>
                                </a>
                              </div>
<!--                              <multiselect-->
<!--                                v-model="interfaceSelectedWlans[cpeInterface.id]"-->
<!--                                :multiple="true"-->
<!--                                :options="wlansList || []"-->
<!--                                :max="8"-->
<!--                                label="ssid"-->
<!--                                :disabled="isDisable"-->
<!--                                :custom-label="ssidWithDescription"-->
<!--                                :class="{ 'multiselect-disabled': isDisable }"-->
<!--                                track-by="ssid"-->
<!--                                :close-on-select="false"-->
<!--                                :hide-selected="true"-->
<!--                                :placeholder="$t('template.wizardWLANsPlaceHolder')"-->
<!--                                :selectLabel="$t('general.multiSelectPressToSelect')"-->
<!--                                :deselectLabel="$t('general.multiSelectDeselectLabel')"-->
<!--                                :selectedLabel="$t('general.multiSelectSelected')"-->
<!--                              ></multiselect>-->
                              <Multiselect
                                v-model="interfaceSelectedWlans[cpeInterface.id]"
                                :multiple="true"
                                :options="wlansListForWlansSearch[cpeInterface.id] || []"
                                :max="8"
                                label="ssid"
                                :disabled="isDisable"
                                :id="[cpeInterface.id]"
                                trackBy="ssid"
                                :closeOnSelect="false"
                                :customLabel="ssidWithDescription"
                                :hideSelected="true"
                                :internalSearch="false"
                                :placeholder="$t('aps.wlansSelectorPlaceHolder')"
                                :selectLabel="$t('aps.wlansSelectorTip')"

                                :class="{ 'multiselect-disabled': isDisable }"
                                :loading="loadingDataForWlanList[cpeInterface.id]"
                                @search-change="asyncSearchWlanWhenSearchChange"
                                @open="asyncSearchWlanWhenOpenSelectorList"
                              >
                                <template v-slot:noOptions>
                                  {{ $t('general.noOptionsForSearch') }}
                                </template>
                                <template v-slot:noResult>
                                  {{ $t('general.noResultForSearch') }}
                                </template>
                              </Multiselect>
                              <div v-if="!isWLCProduct">
                                <span class="small text-muted">{{$t('aps.wlansSelectorHint')}}</span>
                              </div>
                            </div>
                          </section>

                          <div v-if="!cpeModelData" class="mb-2">
                            <div>
                              <label class="">
                                <strong>{{ $t('template.wizardWLANs') }}</strong>
                              </label>
                              <a
                                href="#"
                                class="ml-q pull-right"
                                @click.prevent="isWlanDescription = !isWlanDescription"
                              >
                                <span v-if="!isWlanDescription">{{ $t('aps.showDescription') }}</span>
                                <span v-else>{{ $t('aps.hideDescription') }}</span>
                              </a>
                            </div>
<!--                            <multiselect-->
<!--                              v-model="interfaceSelectedWlans.common"-->
<!--                              :multiple="true"-->
<!--                              :options="wlansList || []"-->
<!--                              :max="8"-->
<!--                              label="ssid"-->
<!--                              :custom-label="ssidWithDescription"-->
<!--                              track-by="ssid"-->
<!--                              :disabled="isDisable"-->
<!--                              :class="{ 'multiselect-disabled': isDisable }"-->
<!--                              :close-on-select="false"-->
<!--                              :hide-selected="true"-->
<!--                              :placeholder="$t('template.wizardWLANsPlaceHolder')"-->
<!--                              :selectLabel="$t('general.multiSelectPressToSelect')"-->
<!--                              :deselectLabel="$t('general.multiSelectDeselectLabel')"-->
<!--                              :selectedLabel="$t('general.multiSelectSelected')"-->
<!--                            ></multiselect>-->

                            <Multiselect
                              class="mb-1"
                              v-model="interfaceSelectedWlans.common"
                              :multiple="true"
                              :options="wlansListForWlansSearchForCommonEdit || []"
                              :max="8"
                              label="ssid"
                              :customLabel="ssidWithDescription"
                              :internalSearch="false"
                              trackBy="ssid"
                              :closeOnSelect="false"
                              :hideSelected="true"
                              :placeholder="$t('aps.wlansSelectorPlaceHolder')"
                              :loading="loadingDataForWlanListForCommonEdit"
                              @search-change="asyncSearchWlanWhenSearchChangeForCommonEdit"
                              @open="asyncSearchWlanWhenOpenSelectorListForCommonEdit"
                            >
                              <template v-slot:noOptions>
                                {{ $t('general.noOptionsForSearch') }}
                              </template>
                              <template v-slot:noResult>
                                {{ $t('general.noResultForSearch') }}
                              </template>
                            </Multiselect>
                            <div v-if="!isWLCProduct">
                              <span class="small text-muted">{{$t('aps.wlansSelectorHint')}}</span>
                            </div>



                          </div>

                          <!-- Wired component -->
                          <wiredConfig
                            v-if="cpeModelData"
                            :cpeModelData="cpeModelData"
                            :objWiredConfig="updatedTemplate.template.cpe_config_template.wired"
                            :isRootUser="isRootUser"
                            :isDisable="isDisable"
                            :redirectsList="redirectsList"
                            :isOperator="isOperator"
                            :hostsList="hostsList"
                            :wlansList="wlansList"
                          ></wiredConfig>

                          <div v-if="!cpeModelData || (cpeModelData && !cpeModelData.cisco)">
                            <span class="h6 mb-1 setting-title">{{ $t('template.advancedSettings') }}</span>

                            <statLogLbsConfig
                              v-if="updatedTemplate"
                              :updatedCpeConfig="updatedTemplate.template.cpe_config_template"
                              :currentCpeConfig="templateEditCurrent.template.cpe_config_template"
                              :isEditShow="statLogLbsmodal"
                              :isRootUser="isRootUser"
                              :lbsFilterList="configFilterList"
                              :isDisable="isDisable"
                              :isOperator="isOperator"
                            ></statLogLbsConfig>

                            <accessControl
                              v-if="updatedTemplate"
                              :updatedCpeConfig="updatedTemplate.template.cpe_config_template"
                              :currentCpeConfig="templateEditCurrent.template.cpe_config_template"
                              :firewallL3List="firewallL3List"
                              :isEditShow="isFirewallShow"
                              :isRootUser="isRootUser"
                              :firewallsList="firewallsList"
                              :isDisable="isDisable"
                              :isOperator="isOperator"
                            ></accessControl>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="actions-buttons" :class="{ 'actions-buttons__operator': isDisable }">
                  <div>
                    <WButton success outline
                      customClass="mr-1"
                      v-if="!isDisable"
                      @click="updateTemplate"
                      :disabled="errors.any()"
                    >
                      {{ $t('aps.save') }}
                    </WButton>
                    <WButton danger outline
                      v-if="!isDisable"
                      @click="openDeletingDialog(updatedTemplate.id)"
                    >
                      {{ $t('general.delete') }}
                    </WButton>
                  </div>
                  <div>
                    <WButton secondary outline
                      @click="disableEditMode"
                    >
                      <span v-if="isDisable">{{ $t('aps.close') }}</span>
                      <span v-else>{{ $t('aps.cancel') }}</span>
                    </WButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </div>
    <div class="col-lg-12">
      <router-view></router-view>
    </div>
    <modal
      title="Delete CPE"
      v-model="deletingTemplateModal"
      @ok="deleteCpe"
      class="modal-danger"
      @cancel="deletingTemplateModal = false"
      effect="fade/zoom"
    >
      <h4 slot="modal-title" class="modal-title">{{$t('aps.deleteTemplate')}}</h4>
      {{$t('general.confirmDeleting')}}
      <strong>{{ templateName(this.templateForDelete) }}</strong>
      ?
      <div slot="modal-footer" class="modal-footer">
        <WButton secondary
          @click="deletingTemplateModal = false"
        >
          {{ $t('general.cancel') }}
        </WButton>
        <WButton danger outline
          @click="deleteCpe"
        >
          {{ $t('general.yesDelete') }}
        </WButton>
      </div>
    </modal>
  </div>
</template>

<script>
import Vue from 'vue';
import { createNamespacedHelpers } from 'vuex';
import { Multiselect } from 'vue-multiselect';
import vueSlider from 'vue-slider-component';
import { countriesCodesOptionsMixin, intervalUpdateModeMixin } from '@/mixins';
import Papa from 'papaparse';
import Modal from '../../components/Modal.vue';
import templatesService from '../../services/templatesService';
import wlanService from '../../services/wlanService';
import redirectsService from '../../services/redirectsService';
import helpers from '../../helpers';
import commonService from '../../services/commonService';
import Filters from '../../filters';
import capabilities from '../../services/capabilities';
import wiredConfig from '../../components/Aps/wiredConfig.vue';
import statLogLbsConfig from '../../components/Aps/statLogLbsConfig.vue';
import accessControl from '../../components/Aps/accessControl.vue';
import { getCsvTip } from '../../helpers/tooltip';
import SwitchComponent from '../../components/Universal/Switch-component.vue';
import locationService from "../../services/locationService";
import SelectComponentV2 from '../../components/Universal/select/select-componentV2.vue';
import SelectComponentV3ForUseInLocationSelectors from '../../components/Universal/select/select-componentV3ForUseInLocationSelectors.vue';
import { disabledChannelsForCountries, countriesList } from '../../helpers/disabledChannelsForCountries';

const { mapGetters: brandingMapGetters } = createNamespacedHelpers('branding');
const { mapGetters: WLCProductMapGetters } = createNamespacedHelpers('WLCProduct');

export default {
  name: 'Templates',
  mixins: [countriesCodesOptionsMixin, intervalUpdateModeMixin],
  components: {
    Multiselect,
    Modal,
    vueSlider,
    wiredConfig,
    statLogLbsConfig,
    accessControl,
    SwitchComponent,
    SelectComponentV2,
    SelectComponentV3ForUseInLocationSelectors
  },
  data() {
    return {
      showLocationFilter: true,
      locationInFiltersData: [],
      updatedTemplate: {},
      newUuid: '',
      fileTypeError: false,
      invalidUuid: false,
      isWlanDescription: false,
      deletingTemplateModal: false,
      cpeModelData: false,
      interfaceCapabilities: {},
      calculatedCapabilities: {},
      interfaceSelectedWlans: {
        // изменяется (добавляются ключи) в недрах templateEditCurrent в зависимости от выбраной модели ТД
        common: []
      },
      configFilterList: {
        blacklist: [],
        whitelist: []
      },
      firewallL3List: {
        ip_list: [],
        mac_list: []
      },
      isFirewallShow: false,
      newMacAddress: '',
      interfaceSettings: {
        // изменяется (добавляются ключи) в недрах templateEditCurrent в зависимости от выбраной модели ТД
      },
      loadingDataForWlanList: {
        // изменяется (добавляются ключи) в недрах templateEditCurrent в зависимости от выбраной модели ТД
      },
      wlansListForWlansSearch: {
        // изменяется (добавляются ключи) в недрах templateEditCurrent в зависимости от выбраной модели ТД
      },
      timeoutsForListForWlansSearch: {
        // изменяется (добавляются ключи) в недрах templateEditCurrent в зависимости от выбраной модели ТД
      },
      timeoutsForListForWlansSearchForCommonEdit: undefined,
      wlansListForWlansSearchForCommonEdit: [],
      loadingDataForWlanListForCommonEdit: false,
      templateForDelete: '',
      statLogLbsmodal: false,
      TXPower: {
        bgStyle: {},
        processStyle: {
          backgroundImage: '-webkit-linear-gradient(left, #3498db, #f05b72)'
        }
      },
      rateControl: {
        '11n': {
          data: ['off', 'ht'],
          piecewise: true,
          bgStyle: {
            backgroundColor: '#cccccc'
          },
          processStyle: {
            backgroundColor: '#3498db'
          },
          piecewiseStyle: {
            backgroundColor: '#ccc',
            visibility: 'visible',
            width: '12px',
            height: '12px'
          },
          piecewiseActiveStyle: {
            backgroundColor: '#3498db'
          },
          labelActiveStyle: {
            color: '#3498db'
          },
          piecewiseLabel: true
        },
        '11ac': {
          data: ['off', 'ht', 'vht'],
          piecewise: true,
          bgStyle: {
            backgroundColor: '#cccccc'
          },
          processStyle: {
            backgroundColor: '#3498db'
          },
          piecewiseStyle: {
            backgroundColor: '#ccc',
            visibility: 'visible',
            width: '12px',
            height: '12px'
          },
          piecewiseActiveStyle: {
            backgroundColor: '#3498db'
          },
          labelActiveStyle: {
            color: '#3498db'
          },
          piecewiseLabel: true
        }
      },
      advancedSettings: {
        // изменяется (добавляются ключи) в недрах templateEditCurrent в зависимости от выбраной модели ТД
      },
      filters: {
        query: '',
        autoFilter: '',
        location: 'All locations',
        with_childs: false,
      }
    };
  },
  computed: {
    ...brandingMapGetters(['isBeelineBranding']),
    ...WLCProductMapGetters(['isWLCProduct']),
    disabledChannelsForCountriesComputed() {
      return disabledChannelsForCountries;
    },
    countriesListComputed() {
      return countriesList;
    },
    isSelectedDefaultLocationForRequests() {
      if (this.$store.getters.getDefaultLocationForRequests(this)) {
        return true;
      } else {
        return false;
      }
    },
    isCompactWlansWithoutClientsLoading() {
      return this.$store.state.loadingWlans;
    },
    userBaseLocation() {
      return this.$store.state.userData.base_location;
    },
    isCpeModelYuncoreXd6800() {
      // проверяем yuncore,xd6800 ли точка для шаблона
      return this.checkCPEModel('yuncore,xd6800');
    },
    isRootUser() {
      return this.$store.state.baseLocation === this.$store.state.userData.base_location;
    },
    isOperator() {
      return this.$store.state.userData.role == 'operator';
    },
    editVisible() {
      return this.$store.state.editTemplateId;
    },
    firewallsList() {
      return this.$store.state.firewallsList;
    },
    isFiltersActive() {
      return (
        this.filters.location !== 'All locations' ||
        this.filters.query !== '' || this.filters.autoFilter !== ''
      );
    },
    templateSelectedId() {
      return this.$store.state.editTemplateId;
    },
    isDisable() {
      return this.$store.state.userData.role == 'operator';
    },
    isNotWizard() {
      return this.$route.name === 'Templates';
    },
    isCpeTemplatesLoading() {
      return this.$store.state.loadingCpeModels;
    },
    redirectsList() {
      return this.$store.state.redirectsList;
    },
    cpeTemplatesList() {
      const { cpeTemplatesList } = this.$store.state;
      let modelDescription;
      if (cpeTemplatesList) {
        cpeTemplatesList.forEach((template) => {
          modelDescription = commonService.modelObjbyId(template.model);
          if (modelDescription) {
            template.model_description = modelDescription.description;
          } else {
            template.model_description = '';
          }
        });
        return cpeTemplatesList;
      }
      return this.$store.state.cpeTemplatesList;
    },
    cpeModelsList() {
      return this.$store.state.cpeModelsList;
    },
    wlansList() {
      return this.$store.state.wlansList;
    },
    locationsList() {
      // return this.$store.state.locationsList;
      // return helpers.sortLocationsList(this.$store.state.locationsList);
      return this.$store.getters.locationsListSorted;
    },
    templateEditCurrent() {
      let sameCpe = false;
      this.cpeModelData = false;

      const { cpeTemplatesList } = this.$store.state;
      let currentTemplate;
      cpeTemplatesList.forEach((template) => {
        if (template.id == this.$store.state.editTemplateId) {
          // сохраняет id
          currentTemplate = JSON.parse(JSON.stringify(template));
          if (this.updatedTemplate && this.updatedTemplate.id === this.$store.state.editTemplateId) {
            sameCpe = true;
          }
        }
      });
      if (!sameCpe) {
        this.updatedTemplate = JSON.parse(JSON.stringify(currentTemplate));
      }

      // Находим modfel capabilities
      const modelId = this.updatedTemplate.model;
      const { cpeModelsList } = this.$store.state;
      for (const model of cpeModelsList) {
        if (model.id === modelId) {
          this.cpeModelData = model;
          if (this.cpeModelData.name.toLowerCase().includes('cisco')) {
            this.cpeModelData.cisco = true;
          }
          break;
        } else {
          this.cpeModelData = null;
        }
      }

      if (!sameCpe && this.cpeModelData) {
        this.updatedTemplate.template.cpe_config_template.wifi = helpers.transformObjectToArray(
          this.updatedTemplate.template.cpe_config_template.wifi
        );
        this.updatedTemplate.template.cpe_config_template.wired = helpers.transformObjectToArray(
          this.updatedTemplate.template.cpe_config_template.wired
        );
      }
      if (this.cpeModelData) {
        this.updatedTemplate.template.cpe_config_template.wifi.forEach((radioInterface) => {
          if (!Object.prototype.hasOwnProperty.call(this.advancedSettings, radioInterface.id)) {
            this.$set(this.advancedSettings, radioInterface.id, {
              maxClientsEnabled: false,
              rateControlEnabled: false
            })
          }
          if (!Object.prototype.hasOwnProperty.call(this.interfaceSelectedWlans, radioInterface.id)) {
            this.$set(this.interfaceSelectedWlans, radioInterface.id, []);
          }

          if (!Object.prototype.hasOwnProperty.call(this.wlansListForWlansSearch, radioInterface.id)) {
            this.$set(this.wlansListForWlansSearch, radioInterface.id, []);
          }
          if (!Object.prototype.hasOwnProperty.call(this.interfaceSettings, radioInterface.id)) {
            this.$set(this.interfaceSettings, radioInterface.id, false);
          }

          if (!Object.prototype.hasOwnProperty.call(this.loadingDataForWlanList, radioInterface.id)) {
            this.$set(this.loadingDataForWlanList, radioInterface.id, false);
          }
          if (!Object.prototype.hasOwnProperty.call(this.timeoutsForListForWlansSearch, radioInterface.id)) {
            this.$set(this.timeoutsForListForWlansSearch, radioInterface.id, undefined);
          }


          for (const wiredInterface in this.cpeModelData.caps.wired) {
            if (
              this.updatedTemplate &&
              !helpers.transformArrayToObject(this.updatedTemplate.template.cpe_config_template.wired)[wiredInterface]
            ) {
              this.updatedTemplate.template.cpe_config_template.wired.push({
                id: wiredInterface,
                vlans: []
              });
            }
            const wiredPorts = this.cpeModelData.caps.wired[wiredInterface].ports;

            const filteredPorts = wiredPorts.sort((curr, prev) => {
              if (curr.index > prev.index) {
                return 1;
              }
              if (curr.index < prev.index) {
                return -1;
              }
              return 0;
            });
            this.cpeModelData.caps.wired[wiredInterface].ports = filteredPorts;
          }

          if (!radioInterface.power.range) {
            const sortedTxpwrlist = this.cpeModelData.caps.wifi[radioInterface.id].txpwrlist.sort(helpers.compareDbm);
            if (!sortedTxpwrlist) {
              return;
            }

            const maxTxPower = sortedTxpwrlist[sortedTxpwrlist.length - 1].dbm;
            const minTxPower = sortedTxpwrlist[0].dbm;

            if (this.cpeModelData && maxTxPower && minTxPower) {
              radioInterface.power.range = [minTxPower, maxTxPower];
            } else {
              radioInterface.power.range = [0, 10];
            }
          }

          // Возвращаем объекты wlan'ов
          const newWlansArray = this.wlansList.filter((wlan) => {
            if (radioInterface.wlans) {
              return radioInterface.wlans.includes(wlan.id);
            }
            return false;
          });
          if (!sameCpe) {
            this.interfaceSelectedWlans[radioInterface.id] = newWlansArray;
          }
          this.interfaceCapabilities[radioInterface.id] = {
            selected: radioInterface,
            capabilities: this.cpeModelData.caps.wifi[radioInterface.id]
          };

          // console.log(JSON.parse(JSON.stringify(this.interfaceCapabilities)))
          // изменения для задачи WNE-3314 (необходимо сделать минимальным значением txPower 1 dbm)
          if (typeof this.interfaceCapabilities[radioInterface.id].selected === 'object' &&
            this.interfaceCapabilities[radioInterface.id].selected !== null &&
            Object.prototype.hasOwnProperty.call(this.interfaceCapabilities[radioInterface.id].selected, 'power') &&
            typeof this.interfaceCapabilities[radioInterface.id].selected.power === 'object' &&
            this.interfaceCapabilities[radioInterface.id].selected.power !== null &&
            Object.prototype.hasOwnProperty.call(this.interfaceCapabilities[radioInterface.id].selected.power, 'range') &&
            Array.isArray(this.interfaceCapabilities[radioInterface.id].selected.power.range) &&
            this.interfaceCapabilities[radioInterface.id].selected.power.range.length &&
            this.interfaceCapabilities[radioInterface.id].selected.power.range[0] === 0) {
            // если в настроках интерфеса было установлено txPower min 0, то ставим 1,
            // так как минимальное значение в слайдере теперь 1
            this.interfaceCapabilities[radioInterface.id].selected.power.range[0] = 1;
          }
          // console.log(JSON.parse(JSON.stringify(this.interfaceCapabilities)))
          // конец изменений  для WNE-3314



          if (this.updatedTemplate.template.cpe_config_template.log_config.log_port === 0) {
            this.updatedTemplate.template.cpe_config_template.log_config.log_port = '';
          }

          //
          // this.interfaceCapabilities[radioInterface.id].selected.frequency = this.cpeModelData.caps.wifi[radioInterface.id].frequency;
          // if (
          //   this.interfaceCapabilities[radioInterface.id].selected.frequency === "Both"
          // ) {
          //   this.interfaceCapabilities[radioInterface.id].selected.frequency = "5";
          // }
          if (this.interfaceCapabilities[radioInterface.id].selected.maxclients) {
            this.advancedSettings[radioInterface.id].maxClientsEnabled = true;
          } else if (this.interfaceCapabilities[radioInterface.id].selected.maxclients === 0) {
            this.advancedSettings[radioInterface.id].maxClientsEnabled = false;
          }

          this.reverseCalculateCapabilities(radioInterface);
          this.calculateCapabilities(
            radioInterface,
            this.interfaceCapabilities[radioInterface.id].capabilities,
            radioInterface.id
          );
        });
      } else {
        const newWlansArray = this.wlansList.filter((wlan) => {
          if (this.updatedTemplate.template.wlans) {
            return this.updatedTemplate.template.wlans.includes(wlan.id);
          }
          return false;
        });
        this.interfaceSelectedWlans.common = newWlansArray;
      }

      // заполняем временные white/blacklist
      this.configFilterList.blacklist = this.updatedTemplate.template.cpe_config_template.lbs_config.blacklist;
      this.configFilterList.whitelist = this.updatedTemplate.template.cpe_config_template.lbs_config.whitelist;

      if (!this.updatedTemplate.template.cpe_config_template.log_config.log_port) {
        this.updatedTemplate.template.cpe_config_template.log_config.log_port = '';
      }

      if (this.updatedTemplate.template.cpe_config_template.firewall.l3_filter) {
        if (
          !this.updatedTemplate.template.cpe_config_template.firewall.l3_filter.mac_list ||
          !Array.isArray(this.updatedTemplate.template.cpe_config_template.firewall.l3_filter.mac_list)
        ) {
          this.updatedTemplate.template.cpe_config_template.firewall.l3_filter.mac_list = [];
        }
        this.firewallL3List.mac_list = this.updatedTemplate.template.cpe_config_template.firewall.l3_filter.mac_list.sort();

        if (!Array.isArray(this.updatedTemplate.template.cpe_config_template.firewall.l3_filter.ip_list)) {
          this.updatedTemplate.template.cpe_config_template.firewall.l3_filter.ip_list = [];
        }
        this.firewallL3List.ip_list = this.updatedTemplate.template.cpe_config_template.firewall.l3_filter.ip_list.sort();
      }

      // убираю всякие null
      if (!this.updatedTemplate.template.wlans) {
        this.updatedTemplate.template.wlans = [];
      }
      if (!this.updatedTemplate.template.tags) {
        this.updatedTemplate.template.tags = [];
      }
      if (!this.updatedTemplate.template.cpe_config_template.lbs_config.blacklist) {
        this.updatedTemplate.template.cpe_config_template.lbs_config.blacklist = [];
      }
      if (!this.updatedTemplate.template.cpe_config_template.lbs_config.whitelist) {
        this.updatedTemplate.template.cpe_config_template.lbs_config.whitelist = [];
      }

      return currentTemplate;
    }
  },
  methods: {
    changeLocationForTemplateCpeHandler() {
      // console.log(JSON.parse(JSON.stringify(this.interfaceSelectedWlans)));
      for (const interfaceName of Object.keys(this.interfaceSelectedWlans)) {
        this.interfaceSelectedWlans[interfaceName] = [];
      }
    },
    changeLocationForTemplateHandler() {
      this.$nextTick(() => {
        this.updatedTemplate.template.location = this.updatedTemplate.base_location;
        this.changeLocationForTemplateCpeHandler();
      })
    },
    changeCountryHandler(interfaceId) {
      // console.log('here')
      // для задачи WNE-3589
      // если для выбраной страны недоступны какие-то каналы, то чистим от них список выбранных
      this.$nextTick(() => {
        const selectedCountry = this.interfaceCapabilities[interfaceId].selected.country;
        // console.log(selectedCountry)
        this.$nextTick(() => {
          const isDisabledChannelsForSelectedCountry = Object.keys(this.countriesListComputed).includes(selectedCountry);
          let channelsDisabledForSelectedCountry = [];
          // console.log('isDisableChannelsForSelectedCountry', isDisableChannelsForSelectedCountry);
          if (isDisabledChannelsForSelectedCountry) {
            channelsDisabledForSelectedCountry = JSON.parse(JSON.stringify(this.disabledChannelsForCountriesComputed[this.countriesListComputed[selectedCountry]]));
          }
          this.$nextTick(() => {
            if (isDisabledChannelsForSelectedCountry && Array.isArray(this.interfaceCapabilities[interfaceId].selected.channels)) {
              this.interfaceCapabilities[interfaceId].selected.channels =
                this.interfaceCapabilities[interfaceId].selected.channels.filter((channel) => !channelsDisabledForSelectedCountry.includes(channel));
            }
          });
        });

      });
    },
    selectLocation() {
      // templatesService.getCpeTemplates(this);
       templatesService.getCpeTemplates(this, true);
    },
    checkCPEModel(modelName) {
      // ждем на вход назавние модели - напирмер "yuncore,xd6800"
      // если точка выбраная для шаблона этой модели - возвращаем true, иначе false
      try {
        if (this.cpeModelData?.name === modelName) {
          return true;
        }
        return false;
      } catch (e) {
        console.log(e)
        return false;
      }
    },
    getCsvTip,
    toggleEdit(e) {
      if (e.target.dataset.action === 'delete') {
        return;
      }
      const selectedNode = e.target.closest('[data-index]');
      const index = parseInt(selectedNode.dataset.index);
      const { id } = selectedNode.dataset;

      Object.keys(this.interfaceSettings).forEach((interfaceName)=>{
        this.interfaceSettings[interfaceName] = false;
      });

      this.newUuid = '';
      this.newMacAddress = '';
      this.$store.commit('setTemplateEditId', id);
    },
    toggleShowLocationFilter() {
      this.showLocationFilter = false;
      this.$nextTick(() => {
        this.showLocationFilter = true;
      })
    },
    // resetAllFilters() {
    //   this.locationInFiltersData = [];
    //   this.toggleShowLocationFilter();
    //   this.filters = {
    //     query: '',
    //     with_childs: false,
    //     location: 'All locations',
    //     autoFilter: ''
    //   };
    //   templatesService.getCpeTemplates(this, true);
    // },
    resetAllFilters() {
      // this.locationInFiltersData = [];
      // this.toggleShowLocationFilter();
      this.filters = {
        query: '',
        // with_childs: false,
        // location: 'All locations',
        autoFilter: ''
      };
      if (!this.isSelectedDefaultLocationForRequests) {
        this.filters.location = 'All locations';
        this.filters.with_childs = false;
        this.locationInFiltersData = [];
      } else {
        this.filters.location = this.$store.getters.getDefaultLocationForRequests(this)
        this.filters.with_childs = this.$store.getters.getDefaultWithChildsForRequests(this)
        this.locationInFiltersData = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject;
      }
      this.toggleShowLocationFilter();
      templatesService.getCpeTemplates(this, true);
    },
    selectLocationInFilters(location) {
      // console.log(location)
      if (!location) {
        this.filters.location = 'All locations'
      } else {
        this.filters.location = location.id;
        // this.addLocationInPreviouslySelectedLocationsInFilter(location)
      }
      templatesService.getCpeTemplates(this, true);

    },
    async requestLocations(searchQuery) {
      // return locationService.requestLocations(searchQuery);
      // return locationService.requestLocationsWithSort(searchQuery);
      return locationService.requestLocationsWithSortAndFilteringByChildsForLocationSelectInPages(this, searchQuery)
    },
    showId(e) {
      const selectedNode = e.target.closest('[data-id]');
      this.interfaceSettings[selectedNode.dataset.id] = !this.interfaceSettings[selectedNode.dataset.id];
    },
    checkAuto() {
      if (!this.updatedTemplate) {
        return;
      }

      if (!this.updatedTemplate.is_auto) {
        this.updatedTemplate.is_always = false;
      }
    },
    showLogStatLbs() {
      this.statLogLbsmodal = !this.statLogLbsmodal;
    },
    disableEditMode() {
      this.$store.commit('setTemplateEditId', null);
      this.updatedTemplate = null;
      this.deletingTemplateModal = false;
    },
    modelObj(id) {
      return commonService.modelObjbyId(id);
    },
    ssidWithDescription({ ssid, description }) {
      if (this.isWlanDescription && description) {
        return `${ssid}: ${commonService.cutName(description)}`;
      }
      return `${ssid}`;
    },
    locationName(id) {
      const showLocationFromId = commonService.showLocationFromId(id);
      return showLocationFromId;
    },
    templateName(id) {
      return commonService.templateNameById(id);
    },
    handleFrequencyChange(interfaceSelectedData, interfaceCapabilities, interfaceName) {
      if (interfaceSelectedData.frequency === '2.4') {
        if (interfaceCapabilities.hwmodelist.b) {
          interfaceSelectedData.bandmode = '11b';
        } else if (interfaceCapabilities.hwmodelist.g) {
          interfaceSelectedData.bandmode = '11g';
        } else if (interfaceCapabilities.hwmodelist.n) {
          interfaceSelectedData.bandmode = '11n';
        }
      } else if (interfaceSelectedData.frequency === '5') {
        if (interfaceCapabilities.hwmodelist.a) {
          interfaceSelectedData.bandmode = '11a';
        } else if (interfaceCapabilities.hwmodelist.n) {
          interfaceSelectedData.bandmode = '11n';
        } else if (interfaceCapabilities.hwmodelist.ac) {
          interfaceSelectedData.bandmode = '11ac';
        }
      }
      this.calculateCapabilities(interfaceSelectedData, interfaceCapabilities, interfaceName);
      this.clearChanList(interfaceName);
    },
    openTemplateWizard() {
      this.$store.commit('resetNewTemplateData');
      this.$router.push({ name: 'templates-wizard' });
    },
    openDeletingDialog(id) {
      this.templateForDelete = id;
      this.deletingTemplateModal = true;
    },
    previewUuids(event) {
      const that = this;

      function showCsv() {
        const input = event.target;
        if (input.files && input.files[0] && input.files[0].type === 'text/csv') {
          Papa.parse(input.files[0], {
            complete(results) {
              const { data } = results;
              const uuids = data.reduce((acc, val) => acc.concat(val), []);
              const correctUuids = uuids.filter((id) => id.length === 36);

              const newUuidArray = new Set([...that.updatedTemplate.cpes, ...correctUuids]);
              that.updatedTemplate.cpes = Array.from(newUuidArray);

              that.invalidUuid = false;
              that.fileTypeError = false;
              input.value = '';
            }
          });
        } else {
          that.invalidUuid = false;
          that.fileTypeError = true;
        }
      }
      showCsv();
    },
    formatUuids(array) {
      let string = array.join(',  ');
      string = `<i>${string}</i>`;
      return string;
    },
    setFilter(filterName, value) {
      this.disableEditMode();
      if (this.filters[filterName] !== value) {
        this.filters[filterName] = value;
        return;
      }
      this.filters[filterName] = '';
    },
    addUUIID() {
      this.$validator
        .validateAll({
          uuid: this.newUuid
        })
        .then((result) => {
          if (result) {
            if (this.updatedTemplate.cpes.indexOf(this.newUuid) == -1) {
              this.updatedTemplate.cpes.push(this.newUuid);
              this.newUuid = '';
            } else {
              this.newUuid = '';
            }
          }
        });
    },
    removeUuid(e) {
      if (this.isDisable) {
        return;
      }
      const selectedNode = e.target.closest('[data-index]');
      const { index } = selectedNode.dataset;
      this.updatedTemplate.cpes.splice(index, 1);
    },
    updateTemplate() {
      if (this.newUuid) {
        this.updatedTemplate.cpes.push(this.newUuid);
      }
      this.$validator.validateAll().then((result) => {
        if (result) {
          templatesService.updateCpeTemplate(this);
          //                        this.disableEditMode();
        }
      });
    },
    // filterItems(items) {
    //   let filteredTemplates = items;
    //
    //   filteredTemplates = Filters.templateSearchQuery(filteredTemplates, this.filters.query);
    //   filteredTemplates = Filters.locationFilter(filteredTemplates, this.filters.location);
    //   filteredTemplates = Filters.templateAutoFilter(filteredTemplates, this.filters.autoFilter);
    //
    //   const sortedFilteredTemplates = filteredTemplates.sort((curr, prev) => {
    //     if (curr.model > prev.model) {
    //       return -1;
    //     }
    //     if (curr.model < prev.model) {
    //       return 1;
    //     }
    //     return 0;
    //   });
    //   return sortedFilteredTemplates;
    // },

    filterItems(items) {
      let filteredTemplates = items;

      filteredTemplates = Filters.templateSearchQuery(filteredTemplates, this.filters.query);
    //   filteredTemplates = Filters.locationFilter(filteredTemplates, this.filters.location);
      filteredTemplates = Filters.templateAutoFilter(filteredTemplates, this.filters.autoFilter);

      const sortedFilteredTemplates = filteredTemplates.sort((curr, prev) => {
        if (curr.model > prev.model) {
          return -1;
        }
        if (curr.model < prev.model) {
          return 1;
        }
        return 0;
      });
      return sortedFilteredTemplates;
    },

    addMacAddress() {
      this.$validator.validateAll({ mac: this.newMacAddress }).then((result) => {
        if (result) {
          if (this.updatedTemplate.template.cpe_config_template.lbs_config.filtermode === 'WhiteList') {
            if (this.configFilterList.whitelist.indexOf(this.newMacAddress) == -1) {
              this.configFilterList.whitelist.push(this.newMacAddress);
            } else {
              return;
            }
          }
          if (this.updatedTemplate.template.cpe_config_template.lbs_config.filtermode === 'BlackList') {
            if (this.configFilterList.blacklist.indexOf(this.newMacAddress) == -1) {
              this.configFilterList.blacklist.push(this.newMacAddress);
            } else {
              return;
            }
          }
          this.newMacAddress = '';
          this.$validator.reset();
        }
      });
    },
    removeMac(e) {
      if (this.isDisable) {
        return;
      }
      const selectedNode = e.target.closest('[data-index]');
      const { index } = selectedNode.dataset;
      const { list } = selectedNode.dataset;
      if (list === 'whitelist') {
        this.configFilterList.whitelist.splice(index, 1);
      }
      if (list === 'blacklist') {
        this.configFilterList.blacklist.splice(index, 1);
      }
    },
    refreshTemplatesList() {
      // templatesService.getCpeTemplates(this);
      templatesService.getCpeTemplates(this, true);
    },
    deleteCpe() {
      templatesService.deleteTemplate(this);
    },
    addVLAN(id) {
      if (this.isDisable) {
        return;
      }
      const newVLAN = {};
      Vue.set(newVLAN, 'vlan', '');
      Vue.set(newVLAN, 'ports', []);
      Vue.set(newVLAN, 'tunnel', '');
      Vue.set(newVLAN, 'fake_wlan', '');
      Vue.set(newVLAN, 'acct', false);
      this.updatedTemplate.template.cpe_config_template.wired.forEach((wired) => {
        if (wired.id === id) {
          wired.vlans.push(newVLAN);
        }
      });
    },
    removeVLAN(vlans, index) {
      if (this.isDisable) {
        return;
      }
      if (vlans[index]) {
        vlans.splice(index, 1);
      }
    },
    changePorts(ports, index) {
      if (this.isDisable) {
        return;
      }
      const vlanPorts = ports;
      const portInedx = index;

      if (vlanPorts.includes(portInedx)) {
        vlanPorts.splice(vlanPorts.indexOf(portInedx), 1);
      } else {
        vlanPorts.push(portInedx);
      }
    },
    listOfVLAN(vlans) {
      const vlansObj = vlans;
      const vlansArray = [];
      vlansObj.forEach((item) => {
        if (typeof item.vlan === 'number') {
          vlansArray.push(item.vlan);
        }
      });
      return vlansArray || [];
    },
    checkNativeVlan(wiredInterface, array, vlan) {
      if (!array.includes(vlan)) {
        wiredInterface.primary_vlan = 0;
      }
    },

    // // Validation
    // calculateCapabilities(interfaceSelectedData, interfaceCapabilities, interfaceName) {
    //   let config = null;
    //   config = interfaceSelectedData;
    //
    //   if (!interfaceName) return;
    //   this.calculatedCapabilities[interfaceName] = capabilities.getCurrentCapabilities(config, interfaceCapabilities);
    //
    //   if (this.calculatedCapabilities[interfaceName].bandwidth.indexOf(config.bandwidth) === -1) {
    //     config.bandwidth = this.calculatedCapabilities[interfaceName].bandwidth[0];
    //   }
    //   //
    //   // validate channels for HT40 & 2.4
    //   if (
    //     this.interfaceCapabilities[interfaceName].selected.bandwidth === 'HT40' &&
    //     this.interfaceCapabilities[interfaceName].selected.frequency === '2.4'
    //   ) {
    //     this.interfaceCapabilities[interfaceName].selected.channels = capabilities.getCurrentBandwidthChannels(
    //       this.interfaceCapabilities[interfaceName],
    //       this.calculatedCapabilities[interfaceName],
    //       'HT40'
    //     );
    //   }
    //   // end validate
    //
    //   // validate channels for HT40 & 5
    //   if (
    //     (this.interfaceCapabilities[interfaceName].selected.bandwidth === 'VHT40' ||
    //       this.interfaceCapabilities[interfaceName].selected.bandwidth === 'HT40') &&
    //     this.interfaceCapabilities[interfaceName].selected.frequency === '5'
    //   ) {
    //     this.interfaceCapabilities[interfaceName].selected.channels = capabilities.getCurrentBandwidthChannels(
    //       this.interfaceCapabilities[interfaceName],
    //       this.calculatedCapabilities[interfaceName],
    //       'VHT40/HT40'
    //     );
    //   }
    //   // end
    //   // validate channels for HT80 & 5
    //   if (
    //     (this.interfaceCapabilities[interfaceName].selected.bandwidth === 'VHT80' ||
    //       this.interfaceCapabilities[interfaceName].selected.bandwidth === 'HT80') &&
    //     this.interfaceCapabilities[interfaceName].selected.frequency === '5'
    //   ) {
    //     this.interfaceCapabilities[interfaceName].selected.channels = capabilities.getCurrentBandwidthChannels(
    //       this.interfaceCapabilities[interfaceName],
    //       this.calculatedCapabilities[interfaceName],
    //       'VHT80/HT80'
    //     );
    //   }
    //   //                 end
    // },

        // Validation
    calculateCapabilities(interfaceSelectedData, interfaceCapabilities, interfaceName) {
      let config = null;
      config = interfaceSelectedData;

      if (!interfaceName) return;
      this.calculatedCapabilities[interfaceName] = capabilities.getCurrentCapabilities(config, interfaceCapabilities);

      if (this.calculatedCapabilities[interfaceName].bandwidth.indexOf(config.bandwidth) === -1) {
        config.bandwidth = this.calculatedCapabilities[interfaceName].bandwidth[0];
      }
      //
      // validate channels for HT40 & 2.4
      if (
        (this.interfaceCapabilities[interfaceName].selected.bandwidth === 'HT40' ||
          this.interfaceCapabilities[interfaceName].selected.bandwidth === 'HE40') &&
        this.interfaceCapabilities[interfaceName].selected.frequency === '2.4'
      ) {
        this.interfaceCapabilities[interfaceName].selected.channels = capabilities.getCurrentBandwidthChannels(
          this.interfaceCapabilities[interfaceName],
          this.calculatedCapabilities[interfaceName],
          'HT40/HE40'
        );
      }
      // end validate

      // validate channels for HT40 & 5
      if (
        (this.interfaceCapabilities[interfaceName].selected.bandwidth === 'VHT40' ||
          this.interfaceCapabilities[interfaceName].selected.bandwidth === 'HT40' ||
        this.interfaceCapabilities[interfaceName].selected.bandwidth === 'HE40') &&
        this.interfaceCapabilities[interfaceName].selected.frequency === '5'
      ) {
        this.interfaceCapabilities[interfaceName].selected.channels = capabilities.getCurrentBandwidthChannels(
          this.interfaceCapabilities[interfaceName],
          this.calculatedCapabilities[interfaceName],
          'VHT40/HT40/HE40'
        );
      }
      // end
      // validate channels for HT80 & 5
      if (
        (this.interfaceCapabilities[interfaceName].selected.bandwidth === 'VHT80' ||
          this.interfaceCapabilities[interfaceName].selected.bandwidth === 'HT80' ||
        this.interfaceCapabilities[interfaceName].selected.bandwidth === 'HE80') &&
        this.interfaceCapabilities[interfaceName].selected.frequency === '5'
      ) {
        this.interfaceCapabilities[interfaceName].selected.channels = capabilities.getCurrentBandwidthChannels(
          this.interfaceCapabilities[interfaceName],
          this.calculatedCapabilities[interfaceName],
          'VHT80/HT80/HE80'
        );
      }
      if (
        (this.interfaceCapabilities[interfaceName].selected.bandwidth === 'VHT80+80' ||
          this.interfaceCapabilities[interfaceName].selected.bandwidth === 'VHT160' ||
          this.interfaceCapabilities[interfaceName].selected.bandwidth === 'HE80+80' ||
          this.interfaceCapabilities[interfaceName].selected.bandwidth === 'HE160') &&
        this.interfaceCapabilities[interfaceName].selected.frequency === '5'
      ) {
        this.interfaceCapabilities[interfaceName].selected.channels = capabilities.getCurrentBandwidthChannels(
          this.interfaceCapabilities[interfaceName],
          this.calculatedCapabilities[interfaceName],
          'VHT80+80/VHT160/HE80+80/HE160'
        );
      }


      //                 end
    },


    // reverseCalculateCapabilities(radioInterface) {
    //   const config = radioInterface;
    //   if (config.bandmode === '11g' && config.frequency === '2.4') {
    //     if (config.bandwidth !== 'auto') {
    //       config.bandmode = '11n';
    //     }
    //   }
    //   if (config.bandmode === '11a' && config.frequency === '5') {
    //     if (config.bandwidth === 'auto') {
    //       config.bandmode = '11a';
    //     } else if (config.bandwidth.indexOf('HT') === 0) {
    //       config.bandmode = '11n';
    //     } else if (config.bandwidth.indexOf('VHT') === 0) {
    //       config.bandmode = '11ac';
    //     }
    //   }
    // },

    reverseCalculateCapabilities(radioInterface, state) {
      const config = radioInterface;

      if (config.bandmode === '11g' && config.frequency === '2.4') {
        if (config.bandwidth !== 'auto') {
          if (config.bandwidth.indexOf('HE') === 0) {
            config.bandmode = '11ax';
          } else {
            config.bandmode = '11n';
          }

        }
      }
      if (config.bandmode === '11a' && config.frequency === '5') {
        if (config.bandwidth === 'auto') {
          config.bandmode = '11a';
        } else if (config.bandwidth.indexOf('HT') === 0) {
          config.bandmode = '11n';
        } else if (config.bandwidth.indexOf('VHT') === 0) {
          config.bandmode = '11ac';
        } else if (config.bandwidth.indexOf('HE') === 0) {
          config.bandmode = '11ax';
        }
      }
    },

    // isModeAllowed(frequency, mode, hwmodelist) {
    //   if (frequency === '2.4') {
    //     const modes24 = {
    //       b: true,
    //       g: true,
    //       n: hwmodelist.n
    //     };
    //     return modes24[mode];
    //   }
    //   const modes5 = {
    //     a: true,
    //     n: hwmodelist.n,
    //     ac: hwmodelist.ac
    //   };
    //   return modes5[mode];
    // },

    isModeAllowed(frequency, mode, hwmodelist) {
      // console.log('frequency', frequency)
      // console.log('mode', mode)
      // console.log('hwmodelist', hwmodelist)
      if (frequency === '2.4') {
        const modes24 = {
          b: hwmodelist.b,
          g: hwmodelist.g,
          n: hwmodelist.n,
          // 'ac': hwmodelist.ac
          ax: hwmodelist.ax
        };
        return modes24[mode];
      }
      const modes5 = {
        a: hwmodelist.a,
        n: hwmodelist.n,
        ac: hwmodelist.ac,
        ax: hwmodelist.ax
      };
      return modes5[mode];
    },

    handleModeChange(interfaceSelectedData, interfaceCapabilities, interfaceName) {
      this.calculateCapabilities(interfaceSelectedData, interfaceCapabilities, interfaceName);
    },
    clearChanList(id) {
      this.interfaceCapabilities[id].selected.channels = [];
    },
    activeChannel(channelList, currChannel) {
      let result;
      channelList.forEach((channel) => {
        if (channel === currChannel) {
          result = true;
        }
      });
      return result;
    },
    handlerChannels(isCisco, channels, channel, isActive, id) {
      if (!isCisco || !isActive) {
        return;
      }
      this.interfaceCapabilities[id].selected.channels = [channel];
    },
    async asyncSearchWlanWhenOpenSelectorList(id) {
      return this.asyncSearchWlan('', id, 0);
    },
    async asyncSearchWlanWhenSearchChange(query, id) {
      return this.asyncSearchWlan(query, id);
    },
    async asyncSearchWlan(query, id, timeout = 1000) {
      if (this.timeoutsForListForWlansSearch[id] !== undefined) {
        clearTimeout(this.timeoutsForListForWlansSearch[id])
      }
      this.loadingDataForWlanList[id] = true;

      let locationForSearch = undefined;
      if (this.updatedTemplate.template.location) {
        locationForSearch = this.updatedTemplate.template.location
      }

      this.timeoutsForListForWlansSearch[id] = setTimeout(async () => {
        // if (query !== '') {
        // console.log(query);
        // console.log(id);
        // this.loadingDataForWlanList[id] = true;
        try {
          // const response = await wlanService.requestWlansWithSearch(query);
          // const response = await wlanService.requestAllCompactWlansWithoutClientsAndWithSearch(query);
          const response = await wlanService.requestAllCompactWlansWithoutClientsAndWithSearch(query, locationForSearch, locationForSearch ? false : true);
          this.wlansListForWlansSearch[id] = response.itemslist;
        } catch (e) {
          console.log(e)
          this.wlansListForWlansSearch[id] = [];
        } finally {
          this.loadingDataForWlanList[id] = false;
        }
        // console.log(this.wlansListForWlansSearch[id]);
        // }
      }, timeout);
    },
    async asyncSearchWlanWhenOpenSelectorListForCommonEdit(id) {
      return this.asyncSearchWlanForCommonEdit('', 0);
    },
    async asyncSearchWlanWhenSearchChangeForCommonEdit(query) {
      return this.asyncSearchWlanForCommonEdit(query);
    },
    async asyncSearchWlanForCommonEdit(query, timeout = 1000) {
      if (this.timeoutsForListForWlansSearchForCommonEdit !== undefined) {
        clearTimeout(this.timeoutsForListForWlansSearchForCommonEdit)
      }
      this.loadingDataForWlanListForCommonEdit = true;

      let locationForSearch = undefined;
      if (this.updatedTemplate.template.location) {
        locationForSearch = this.updatedTemplate.template.location
      }

      this.timeoutsForListForWlansSearchForCommonEdit = setTimeout(async () => {

        // console.log(query);
        // console.log(id);
        // this.loadingDataForWlanListForCommonEdit = true;
        try {
          // const response = await wlanService.requestWlansWithSearch(query);
          // const response = await wlanService.requestAllCompactWlansWithoutClientsAndWithSearch(query);
          const response = await wlanService.requestAllCompactWlansWithoutClientsAndWithSearch(query, locationForSearch, locationForSearch ? false : true);
          this.wlansListForWlansSearchForCommonEdit = response.itemslist;
        } catch (e) {
          console.log(e)
          this.wlansListForWlansSearchForCommonEdit = [];
        } finally {
          this.loadingDataForWlanListForCommonEdit = false;

          // console.log(this.wlansListForWlansSearch[id]);
        }
      }, timeout);
    },
  },
  // created() {
  //   templatesService.getCpeModels(this);
  //   templatesService.getCpeTemplates(this);
  //   wlanService.getAllWlans(this);
  //   wlanService.getHosts(this);
  //   redirectsService.getRedirectsRule(this);
  //   this.disableEditMode();
  //
  //   if (!String.prototype.format) {
  //     String.prototype.format = function () {
  //       const args = arguments;
  //       return this.replace(/{(\d+)}/g, (match, number) => (typeof args[number] !== 'undefined' ? args[number] : match));
  //     };
  //   }
  // },
  created() {

    let inHeaderLocationSelectedApplying = true;
    if (this.$store.state.inHeaderLocationSelectModeEnabled &&
      inHeaderLocationSelectedApplying) {
      let withChildsForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.withChilds;
      let locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationID;
      if (!locationForFiltering || locationForFiltering === '') {
        // locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.userBaseLocation
        locationForFiltering = 'All locations';
      }

      if (locationForFiltering === 'All locations') {
        this.locationInFiltersData = [];
      } else {
        this.locationInFiltersData = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject
      }

      this.filters.location = locationForFiltering;
      this.filters.with_childs = withChildsForFiltering;
    }

    templatesService.getCpeModels(this);
    templatesService.getCpeTemplates(this, true);
    // wlanService.getAllWlans(this);
    // wlanService.getAllCompactWlansWithDataRemainingWithoutClients(this)
    wlanService.getAllCompactWlansFromaAllLocationsWithDataRemainingWithoutClients(this);
    wlanService.getHosts(this);
    redirectsService.getRedirectsRule(this);
    this.disableEditMode();

    if (!String.prototype.format) {
      String.prototype.format = function () {
        const args = arguments;
        return this.replace(/{(\d+)}/g, (match, number) => (typeof args[number] !== 'undefined' ? args[number] : match));
      };
    }
  },
  mounted() {
    // подписка на обновление данных в интервале (если режим обновления активирован - vuex module intervalUpdateMode)
    this.subscribeOnIntervalUpdate(() => {
      templatesService.getCpeModels(this);
      templatesService.getCpeTemplates(this, true);
      wlanService.getAllCompactWlansFromaAllLocationsWithDataRemainingWithoutClients(this);
      wlanService.getHosts(this);
      redirectsService.getRedirectsRule(this);
    });
  }

};
</script>
<style lang="scss">
    .input-group-for-location-select-in-templates--filters {

    position: relative;
    display: flex;

    align-items: stretch;
    width: 100%;
    max-width: 100%;

  }
      .select-location-in-templates--filters .select__list-container {
    width: 400px;
  }
.templates {
  display: flex;
}

.templates-list,
.template-edit {
  transition: width 0.2s;
}

.template-edit .card-header {
  border: 0px;
}

.templates-list {
  width: 100%;
}

.template-edit {
  display: inline-block;
  width: 0%;
}

.edit-visible .templates-list {
  transition: width, margin-right 0.2s;
  margin-right: 10px;
  width: 60%;
}

.edit-visible .template-edit {
  transition: width 0.2s;
  width: 40%;
  min-width: 530px;
}

.loader-backdrop-templates:after {
  position: absolute;
  top: 30px;
}
</style>
<style scoped>
  .select-channels-less-or-more-in-interface-settings {
    text-align: center;
    font-size: 1.2em !important;
  }

  .interface-channel-trapezoid__ht160 {
    font-size: 1em;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    width: 25%;
    box-sizing: border-box;
    min-width: 210px;
    max-width: 300px;
    margin: 6px -1px;
    height: 0;
    border: 10px solid rgba(0, 0, 0, 0);
    border-top: 0 solid;
    border-bottom: 30px solid #bcc5d0;
    text-align: center;
    line-height: 30px;
    color: #fff;
    cursor: pointer;
    transition: border-bottom-color 0.5s;
  }

  .interface-channel-trapezoid__ht160:hover {
    border-bottom-color: #8cd4a5;
  }

  .active.interface-channel-trapezoid__ht160 {
    border-bottom-color: #4ebd75;
  }

  .interface-channel-trapezoid__ht160.disabled {
    cursor: not-allowed;
  }
</style>
