/* eslint-disable camelcase */
/* eslint-disable consistent-return */

import store from '@/store';
import { AXIOS_INSTANCE, GET_RADUIS_GROUPS_URL } from '../config';

/**
 * Функция-фетчер для получения radius groups.
 * Используется:
 * для запроса вариантов выбора Radius profile в секции MAB
 *
 * @returns {Promise<Object>} - Обещание с данными ответа.
 * @throws {Error} - Ошибка при выполнении запроса.
 */
const getRadiusGroupsFetcher = async () => {
  const payload = {
    action: 'R'
  };

  try {
    const response = await AXIOS_INSTANCE.post(GET_RADUIS_GROUPS_URL, payload);

    const { data } = response;

    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * фукнция-обработчик фетчера getRadiusGroupsFetcher.
 * выполняет побочные действия и обрабатывает тело запроса.
 *
 * @param {Object} [options] - Дополнительные опции запроса.
 * @param {Function} [options.onLoading] - Callback при начале выполнения запроса.
 * @param {Function} [options.onSuccess] - Callback при успешном выполнении запроса.
 * @param {Function} [options.onError] - Callback при ошибке выполнения запроса.
 * @returns {Promise<Object>} - Обещание с данными ответа.
 * @throws {Error} - Ошибка при выполнении запроса.
 */

const getRadiusGroups = async (options) => {
  const {
    onLoading,
    onSuccess,
    onError
  } = options;

  try {
    if (onLoading) {
      onLoading();
    }

    store.commit('setLoading');

    const response = await getRadiusGroupsFetcher();
    const { data } = response;

    const radiusGroups = data.itemslist;

    store.commit('setSuccess');
    onSuccess(radiusGroups);
    return Promise.resolve(radiusGroups);
  } catch (error) {
    if (onError) {
      onError(error);
    }
    store.commit('setError', error);
  }
};

export default getRadiusGroups;
