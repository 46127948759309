<template>
  <div class="wlan-form-tab-input-group ghz-statuses">
    <h3 v-if="config.label" class="wlan-form-tab-input-group-heading">
      {{ config.label }}
    </h3>
    <div class="wlan-form-tab-input-group">
      <WCheckbox
        :label="status_24ghzConfig.label"
        :name="status_24ghzConfig.name"
        :id="status_24ghzConfig.name"
        :checked="formState[status_24ghzConfig.name]"
        @change="handleCheckbox(status_24ghzConfig.name, $event)"
        :disabled="status_24ghz_isDisabled"
        v-validate="status_24ghzConfig.validateRules"
        data-vv-validate-on="change"
        :data-vv-as="status_24ghzConfig.label"
        :errorText="errors.first(status_24ghzConfig.name)"
      />
      <WCheckbox
        :label="status_5ghzConfig.label"
        :name="status_5ghzConfig.name"
        :id="status_5ghzConfig.name"
        :checked="formState[status_5ghzConfig.name]"
        @change="handleCheckbox(status_5ghzConfig.name, $event)"
        :disabled="status_5ghz_isDisabled"
        v-validate="status_5ghzConfig.validateRules"
        data-vv-validate-on="change"
        :data-vv-as="status_5ghzConfig.label"
        :errorText="errors.first(status_5ghzConfig.name)"
      />
        <div class="checkbox-container">
          <WCheckbox
            :label="status_6ghzConfig.label"
            :name="status_6ghzConfig.name"
            :id="status_6ghzConfig.name"
            :checked="formState[status_6ghzConfig.name]"
            @change="handleCheckbox(status_6ghzConfig.name, $event)"
            :disabled="status_6ghz_isDisabled"
            v-validate="status_6ghzConfig.validateRules"
            data-vv-validate-on="change"
            :data-vv-as="status_6ghzConfig.label"
            :errorText="errors.first(status_6ghzConfig.name)"
          />
          <v-popover
            v-if="isStatus_6ghz_tooltipVisible"
            trigger="hover"
            placement="top-center"
            popoverClass="wlan-form-popover"
            :popperOptions="{
              modifiers: {
                preventOverflow: {
                  boundariesElement: 'window'
                }
              }
            }">
            <i class="fa fa-warning wlan-form-danger-icon" />
            <template slot="popover">
              <span>{{ $t('wlan.inputError.status_6ghz_enabled') }}</span>
            </template>
          </v-popover>
        </div>
    </div>
  </div>
</template>

<script>
/**
 *
 * компонент секции таба general - GhzStatuses.
 * Содержит разметку, правила рендеринга (в т.ч. рекурсивные), специфических настроек отображения
 * и параметров валидации отдельных секций(групп) инпутов в соответствии с конфигом
 * @component
 *
 */

/* eslint-disable camelcase */

export default {
  name: 'GhzStatusesSection',
  inject: ['$validator'],
  components: {
  },
  props: {
    config: {
      type: Object,
      required: true
    },
    formState: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
    };
  },
  computed: {
    currentSecurityType() {
      return this.formState['security.layer2.security_type'];
    },
    currentStatus_24ghz_enabled() {
      return this.formState['general.status_24ghz_enabled'];
    },
    currentStatus_5ghz_enabled() {
      return this.formState['general.status_5ghz_enabled'];
    },
    currentStatus_6ghz_enabled() {
      return this.formState['general.status_6ghz_enabled'];
    },
    currentParam_80211ax_enabled() {
      return this.formState['advanced.param_80211ax_enabled'];
    },
    // https://wimarksystems.atlassian.net/wiki/spaces/Wimark/pages/223609094/FT23.9.1+front#%D0%A2%D1%80%D0%B5%D0%B1%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D1%8F-%D0%BA-%D1%81%D1%82%D1%80%D0%B0%D0%BD%D0%B8%D1%86%D0%B5-%D0%91%D0%9B%D0%92%D0%A1
    // Если выбран Тип безопасности WPA2 или WPA2 + WPA3 и параметр Статус 5ГГц выключен, то параметр Статус 2.4 ГГц нельзя выключить.
    // Если выбран Тип безопасности WPA3 и параметры Статус 5ГГц и Статус 6ГГц выключены, то параметр Статус 2.4 ГГц нельзя выключить.
    status_24ghz_isDisabled() {
      if ((this.currentSecurityType === 'wpa2' || this.currentSecurityType === 'wpa2+wpa3') && !this.currentStatus_5ghz_enabled) {
        return true;
      }

      if (this.currentSecurityType === 'wpa3' && !this.currentStatus_5ghz_enabled && !this.currentStatus_6ghz_enabled) {
        return true;
      }

      return false;
    },
    // https://wimarksystems.atlassian.net/wiki/spaces/Wimark/pages/223609094/FT23.9.1+front#%D0%A2%D1%80%D0%B5%D0%B1%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D1%8F-%D0%BA-%D1%81%D1%82%D1%80%D0%B0%D0%BD%D0%B8%D1%86%D0%B5-%D0%91%D0%9B%D0%92%D0%A1
    // Если выбран Тип безопасности WPA2 или WPA2+WPA3 и параметр Статус 2.4 ГГц выключен,то параметр Статус 5 ГГц нельзя выключить.
    // Если выбран Тип безопасности WPA3 и параметры Статус 2.4 ГГц и Статус 6 ГГц выключены, то параметр Статус 5 ГГц нельзя выключить.
    status_5ghz_isDisabled() {
      if ((this.currentSecurityType === 'wpa2' || this.currentSecurityType === 'wpa2+wpa3') && !this.currentStatus_24ghz_enabled) {
        return true;
      }

      if (this.currentSecurityType === 'wpa3' && !this.currentStatus_24ghz_enabled && !this.currentStatus_6ghz_enabled) {
        return true;
      }

      return false;
    },
    // https://wimarksystems.atlassian.net/wiki/spaces/Wimark/pages/223609094/FT23.9.1+front#%D0%A2%D1%80%D0%B5%D0%B1%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D1%8F-%D0%BA-%D1%81%D1%82%D1%80%D0%B0%D0%BD%D0%B8%D1%86%D0%B5-%D0%91%D0%9B%D0%92%D0%A1
    // Чтобы сделать параметр Статус 6 ГГц активным, необходимо выбрать Тип безопасности WPA2+WPA3 или WPA3 и включить поддержку протокола 802.11ax. Иначе, при попытке сделать параметр Статус 6 ГГц активным, необходимо показать сообщение:
    // Выберите Тип безопасности WPA2+ WPA3 или WPA3 и включите 802.11ax(русская локаль)
    // Enable WPA2+ WPA3 or WPA3 and 802.11ax. (английская локаль)
    // При этом переключатель должен оставаться в выключенном положении.
    // Если выбран Тип безопасности WPA2 + WPA3 или WPA3 параметр Статус 2.4 ГГц или Статус 5 ГГц выключен, то выключить параметр Статус 6 ГГц нельзя.
    status_6ghz_isDisabled() {
      let result;
      switch (this.currentSecurityType) {
        case 'wpa3':
        case 'wpa2+wpa3':
          if (this.currentParam_80211ax_enabled && (!this.currentStatus_24ghz_enabled || !this.currentStatus_5ghz_enabled)) {
            result = true;
          }
          if (this.currentParam_80211ax_enabled && this.currentStatus_24ghz_enabled && this.currentStatus_5ghz_enabled) {
            result = false;
          }
          if (!this.currentParam_80211ax_enabled) {
            result = true;
          }
          break;
        default:
          result = true;
      }

      return result;
    },
    isStatus_6ghz_tooltipVisible() {
      let result;
      switch (this.currentSecurityType) {
        case 'wpa3':
        case 'wpa2+wpa3':
          if (this.currentParam_80211ax_enabled && (!this.currentStatus_24ghz_enabled || !this.currentStatus_5ghz_enabled)) {
            result = false;
          }
          if (this.currentParam_80211ax_enabled && this.currentStatus_24ghz_enabled && this.currentStatus_5ghz_enabled) {
            result = false;
          }
          if (!this.currentParam_80211ax_enabled) {
            result = true;
          }
          break;
        default:
          result = true;
      }

      return result;
    },
    status_24ghzConfig() {
      return this.config.inputs.find(item => item.name === 'general.status_24ghz_enabled');
    },
    status_5ghzConfig() {
      return this.config.inputs.find(item => item.name === 'general.status_5ghz_enabled');
    },
    status_6ghzConfig() {
      return this.config.inputs.find(item => item.name === 'general.status_6ghz_enabled');
    }
  },
  watch: {
    formState: {
      handler(newVal, oldVal) {
        const newSecurityType = newVal['security.layer2.security_type'];
        const oldSecurityType = oldVal['security.layer2.security_type'];

        const newStatus_24ghz = newVal['general.status_24ghz_enabled'];
        const oldStatus_24ghz = oldVal['general.status_24ghz_enabled'];

        const newStatus_5ghz = newVal['general.status_5ghz_enabled'];
        const oldStatus_5ghz = oldVal['general.status_5ghz_enabled'];

        const newStatus_6ghz = newVal['general.status_6ghz_enabled'];
        const oldStatus_6ghz = oldVal['general.status_6ghz_enabled'];

        const newParam80211ax = newVal['advanced.param_80211ax_enabled'];
        const oldParam80211ax = oldVal['advanced.param_80211ax_enabled'];

        const isNewSecurityType = newSecurityType !== oldSecurityType;
        const isNewStatus_24ghz = newStatus_24ghz !== oldStatus_24ghz;
        const isNewStatus_5ghz = newStatus_5ghz !== oldStatus_5ghz;
        const isNewStatus_6ghz = newStatus_6ghz !== oldStatus_6ghz;
        const isNewParam80211ax = newParam80211ax !== oldParam80211ax;

        if (isNewSecurityType || isNewStatus_5ghz || isNewStatus_6ghz) {
          this.forceHandle24ghzCheckbox({
            securityType: newSecurityType,
            status_24ghz: newStatus_24ghz,
            status_5ghz: newStatus_5ghz,
            status_6ghz: newStatus_6ghz
          });
        }

        if (isNewSecurityType || isNewStatus_24ghz || isNewStatus_6ghz) {
          this.forceHandle5ghzCheckbox({
            securityType: newSecurityType,
            status_24ghz: newStatus_24ghz,
            status_5ghz: newStatus_5ghz,
            status_6ghz: newStatus_6ghz
          });
        }

        if (isNewSecurityType || isNewStatus_24ghz || isNewStatus_5ghz || isNewParam80211ax) {
          this.forceHandle6ghzCheckbox({
            securityType: newSecurityType,
            status_24ghz: newStatus_24ghz,
            status_5ghz: newStatus_5ghz,
            status_6ghz: newStatus_6ghz,
            param80211ax: newParam80211ax
          });
        }
      }
    }
  },
  methods: {
    handleCheckbox(inputName, event) {
      const isChecked = event.target.checked;
      this.$emit('update:inputValue', { inputName, value: isChecked });
    },
    // https://wimarksystems.atlassian.net/wiki/spaces/Wimark/pages/223609094/FT23.9.1+front#%D0%A2%D1%80%D0%B5%D0%B1%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D1%8F-%D0%BA-%D1%81%D1%82%D1%80%D0%B0%D0%BD%D0%B8%D1%86%D0%B5-%D0%91%D0%9B%D0%92%D0%A1
    // Если выбран Тип безопасности WPA2 или WPA2 + WPA3 и параметр Статус 5ГГц выключен, то параметр Статус 2.4 ГГц нельзя выключить.
    // Если выбран Тип безопасности WPA3 и параметры Статус 5ГГц и Статус 6ГГц выключены, то параметр Статус 2.4 ГГц нельзя выключить.
    forceHandle24ghzCheckbox({
      securityType,
      status_24ghz,
      status_5ghz,
      status_6ghz
    }) {
      if ((securityType === 'wpa2' || securityType === 'wpa2+wpa3') && !status_5ghz) {
        this.$emit('update:inputValue', {
          inputName: 'general.status_24ghz_enabled',
          value: true
        });

        return;
      }

      if (securityType === 'wpa3' && !status_5ghz && !status_6ghz) {
        this.$emit('update:inputValue', {
          inputName: 'general.status_24ghz_enabled',
          value: true
        });
      }
    },
    // https://wimarksystems.atlassian.net/wiki/spaces/Wimark/pages/223609094/FT23.9.1+front#%D0%A2%D1%80%D0%B5%D0%B1%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D1%8F-%D0%BA-%D1%81%D1%82%D1%80%D0%B0%D0%BD%D0%B8%D1%86%D0%B5-%D0%91%D0%9B%D0%92%D0%A1
    // Если выбран Тип безопасности WPA2 или WPA2+WPA3 и параметр Статус 2.4 ГГц выключен,то параметр Статус 5 ГГц нельзя выключить.
    // Если выбран Тип безопасности WPA3 и параметры Статус 2.4 ГГц и Статус 6 ГГц выключены, то параметр Статус 5 ГГц нельзя выключить.
    forceHandle5ghzCheckbox({
      securityType,
      status_24ghz,
      status_5ghz,
      status_6ghz
    }) {
      if ((securityType === 'wpa2' || securityType === 'wpa2+wpa3') && !status_24ghz) {
        this.$emit('update:inputValue', {
          inputName: 'general.status_5ghz_enabled',
          value: true
        });

        return;
      }

      if (securityType === 'wpa3' && !status_24ghz && !status_6ghz) {
        this.$emit('update:inputValue', {
          inputName: 'general.status_5ghz_enabled',
          value: true
        });
      }
    },
    // https://wimarksystems.atlassian.net/wiki/spaces/Wimark/pages/223609094/FT23.9.1+front#%D0%A2%D1%80%D0%B5%D0%B1%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D1%8F-%D0%BA-%D1%81%D1%82%D1%80%D0%B0%D0%BD%D0%B8%D1%86%D0%B5-%D0%91%D0%9B%D0%92%D0%A1
    // Чтобы сделать параметр Статус 6 ГГц активным, необходимо выбрать Тип безопасности WPA2+WPA3 или WPA3 и включить поддержку протокола 802.11ax. Иначе, при попытке сделать параметр Статус 6 ГГц активным, необходимо показать сообщение:
    // Выберите Тип безопасности WPA2+ WPA3 или WPA3 и включите 802.11ax(русская локаль)
    // Enable WPA2+ WPA3 or WPA3 and 802.11ax. (английская локаль)
    // При этом переключатель должен оставаться в выключенном положении.
    // Если выбран Тип безопасности WPA2 + WPA3 или WPA3 параметр Статус 2.4 ГГц или Статус 5 ГГц выключен, то выключить параметр Статус 6 ГГц нельзя.
    forceHandle6ghzCheckbox({
      securityType,
      status_24ghz,
      status_5ghz,
      status_6ghz,
      param80211ax
    }) {
      switch (securityType) {
        case 'wpa3':
        case 'wpa2+wpa3':
          if (param80211ax && (!status_24ghz || !status_5ghz)) {
            this.$emit('update:inputValue', {
              inputName: 'general.status_6ghz_enabled',
              value: true
            });
          }
          if (!param80211ax) {
            this.$emit('update:inputValue', {
              inputName: 'general.status_6ghz_enabled',
              value: false
            });
          }
          break;
        default:
          this.$emit('update:inputValue', {
            inputName: 'general.status_6ghz_enabled',
            value: false
          });
      }
    }
  },
  mounted() {
  }
};
</script>

<style lang="css" scoped>
/* .wlan-form-tab-input-group.ghz-statuses{

} */

.checkbox-container {
  display: flex;
  align-items: center;
  gap: 4px;
}
</style>
