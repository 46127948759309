<template>
  <div
    v-if="isParams80211rSectionVisible"
    class="wlan-form-tab-input-group params-80211r"
  >
    <h3 v-if="config.label" class="wlan-form-tab-input-group-heading">
      {{ config.label }}
    </h3>

    <!-- ! информационные блоки -->
    <div v-if="isWpa2WarningCaptionVisible"
      class="wlan-form-warning-caption alert alert-warning"
    >
      <p class="caption-heading">{{ $t('wlan.tabText.warningCaption.80211r.wpa2.1') }}</p>
    </div>

    <div v-if="isWpa2Wpa3WarningCaptionVisible"
      class="wlan-form-warning-caption alert alert-warning"
    >
      <p class="caption-heading">{{ $t('wlan.tabText.warningCaption.80211r.wpa2wpa3.1') }}</p>
    </div>

    <div v-if="isWpa3WarningCaptionVisible"
      class="wlan-form-warning-caption alert alert-warning"
    >
      <p class="caption-heading">{{ $t('wlan.tabText.warningCaption.80211r.wpa3.1') }}</p>
    </div>

    <div v-for="input in config.inputs"
      class="wlan-form-tab-input-group"
      :key="input.id"
    >
      <template v-if="input.name === 'security.layer2.fast_roaming_enabled'">
        <WCheckbox
          data-vv-scope="fast_roaming_enabled"
          :label="input.label"
          :name="input.name"
          :id="input.name"
          :checked="formState[input.name]"
          @change="handleCheckbox(input.name, $event)"
          :disabled="input.isDisabled"
          v-validate="input.validateRules"
          data-vv-validate-on="change"
          :data-vv-as="input.label"
          :errorText="errors.first(input.name)"
        />
      </template>
      <template v-else-if="input.name === 'security.layer2.ft_over_ds_enabled'">
        <WCheckbox
          v-if="currentFast_roaming_enabled"
          data-vv-scope="ft_over_ds_enabled"
          :label="input.label"
          :name="input.name"
          :id="input.name"
          :checked="formState[input.name]"
          @change="handleCheckbox(input.name, $event)"
          :disabled="input.isDisabled"
          v-validate="input.validateRules"
          data-vv-validate-on="change"
          :data-vv-as="input.label"
          :errorText="errors.first(input.name)"
        />
      </template>
      <template v-else-if="input.name === 'security.layer2.param_80211r_reassociation_timeout'">
        <WInput
          v-if="currentFast_roaming_enabled"
          data-vv-scope="param_80211r_reassociation_timeout"
          :key="input.id"
          :type="input.inputType"
          :label="input.label"
          :value="formState[input.name]"
          @input="handleInput(input, $event)"
          :id="input.name"
          :name="input.name"
          :placeholder="input.placeholder"
          :disabled="input.isDisabled"
          v-validate="input.validateRules"
          data-vv-validate-on="input"
          :data-vv-as="input.label"
          :errorText="errors.first(input.name)"
          autocomplete="one-time-code"
        />
      </template>
      <template v-else-if="input.inputType === 'checkbox'">
        <WCheckbox
          :label="input.label"
          :name="input.name"
          :id="input.name"
          :checked="formState[input.name]"
          @change="handleCheckbox(input.name, $event)"
          :disabled="input.isDisabled"
          v-validate="input.validateRules"
          data-vv-validate-on="change"
          :data-vv-as="input.label"
          :errorText="errors.first(input.name)"
        />
      </template>
      <template v-else>
        <WInput
          :key="input.id"
          :type="input.inputType"
          :label="input.label"
          :value="formState[input.name]"
          @input="handleInput(input, $event)"
          :id="input.name"
          :name="input.name"
          :placeholder="input.placeholder"
          :disabled="input.isDisabled"
          v-validate="input.validateRules"
          data-vv-validate-on="input"
          :data-vv-as="input.label"
          :errorText="errors.first(input.name)"
          autocomplete="one-time-code"
        />
      </template>
    </div>
  </div>
</template>

<script>
/**
 *
 * компонент секции таба security - 80211r.
 * Содержит разметку, правила рендеринга (в т.ч. рекурсивные), специфических настроек отображения
 * и параметров валидации отдельных секций(групп) инпутов в соответствии с конфигом
 * @component
 *
 */

/* eslint-disable camelcase */

export default {
  name: 'Params80211rSection',
  inject: ['$validator'],
  components: {
  },
  props: {
    config: {
      type: Object,
      required: true
    },
    formState: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
    };
  },
  computed: {
    currentSecurityType() {
      return this.formState['security.layer2.security_type'];
    },
    currentEncryptionType() {
      // ccmp
      // ccmp256
      // gcmp256
      return this.formState['security.layer2.encryption_type'];
    },
    // https://wimarksystems.atlassian.net/wiki/spaces/Wimark/pages/223609094/FT23.9.1+front#%5BinlineExtension%5D%D0%92%D0%BA%D0%BB%D0%B0%D0%B4%D0%BA%D0%B0-%D0%91%D0%B5%D0%B7%D0%BE%D0%BF%D0%B0%D1%81%D0%BD%D0%BE%D1%81%D1%82%D1%8C-(%D1%80%D1%83%D1%81%D1%81%D0%BA%D0%B0%D1%8F-%D0%BB%D0%BE%D0%BA%D0%B0%D0%BB%D1%8C)-%5C-Security-(%D0%B0%D0%BD%D0%B3%D0%BB%D0%B8%D0%B9%D1%81%D0%BA%D0%B0%D1%8F-%D0%BB%D0%BE%D0%BA%D0%B0%D0%BB%D1%8C)-%E2%86%92-%D0%A3%D1%80%D0%BE%D0%B2%D0%B5%D0%BD%D1%8C-2-(%D1%80%D1%83%D1%81%D1%81%D0%BA%D0%B0%D1%8F-%D0%BB%D0%BE%D0%BA%D0%B0%D0%BB%D1%8C)-%5C-Layer-2-(%D0%B0%D0%BD%D0%B3%D0%BB%D0%B8%D0%B9%D1%81%D0%BA%D0%B0%D1%8F-%D0%BB%D0%BE%D0%BA%D0%B0%D0%BB%D1%8C)
    // Секция должна быть недоступна(скрыта), если ранее пользователь выбрал Тип Безопасности - Открытая.
    // При включении переключателя CCMP256 или GCMP-256 в секции Шифрование, секция должна быть скрыта.
    isParams80211rSectionVisible() {
      return this.currentSecurityType !== 'open' && this.currentEncryptionType !== 'gcmp256' && this.currentEncryptionType !== 'ccmp256';
    },
    currentFast_roaming_enabled() {
      return this.formState['security.layer2.fast_roaming_enabled'];
    },
    isCheckboxesErrors() {
      const checkboxErrors = [
        this.errors.first('security.layer2.psk_enabled'),
        this.errors.first('security.layer2.psk_256_enabled'),
        this.errors.first('security.layer2.param_8021x_enabled'),
        this.errors.first('security.layer2.param_8021x_256_enabled'),
        this.errors.first('security.layer2.sae_enabled'),
        this.errors.first('security.layer2.ft_sae_enabled'),
        this.errors.first('security.layer2.ft_8021x_enabled'),
        this.errors.first('security.layer2.ft_psk_enabled'),
        this.errors.first('security.layer2.suite_b_192_enabled'),
        this.errors.first('security.layer2.owe_enabled')
      ];

      const condition = Boolean(checkboxErrors.filter(errText => errText).length);
      return condition;
    },
    isWpa2WarningCaptionVisible() {
      const isSelected = this.formState['security.layer2.ft_psk_enabled']
        || this.formState['security.layer2.ft_8021x_enabled'];

      return this.currentFast_roaming_enabled && this.currentSecurityType === 'wpa2' && !isSelected;
    },
    isWpa2Wpa3WarningCaptionVisible() {
      const isSelected = this.formState['security.layer2.ft_psk_enabled']
        || this.formState['security.layer2.ft_sae_enabled']
        || this.formState['security.layer2.ft_8021x_enabled'];

      return this.currentFast_roaming_enabled && this.currentSecurityType === 'wpa2+wpa3' && !isSelected;
    },
    isWpa3WarningCaptionVisible() {
      const isSelected = this.formState['security.layer2.ft_sae_enabled']
        || this.formState['security.layer2.param_8021x_enabled'];

      return this.currentFast_roaming_enabled && this.currentSecurityType === 'wpa3' && !isSelected;
    }
  },
  watch: {
    // при изменении security_type, сброс значений инпутов в дефолтное значение
    currentSecurityType(newVal, oldVal) {
      if (newVal === oldVal) {
        return;
      }

      // reset all fields
      this.handleResetFast_roaming_enabled();
      // эти не вызываем, потому что они вызываются при изменении currentFast_roaming_enabled
      // this.handleResetFt_over_ds_enabled();
      // this.handleResetParam_80211r_reassociation_timeout();
    },
    // при изменении encryption_type, сброс значений инпутов в дефолтное значение
    currentEncryptionType(newVal, oldVal) {
      if (newVal === oldVal) {
        return;
      }

      // reset all fields
      this.handleResetFast_roaming_enabled();
      // эти не вызываем, потому что они вызываются при изменении currentFast_roaming_enabled
      // this.handleResetFt_over_ds_enabled();
      // this.handleResetParam_80211r_reassociation_timeout();
    },
    currentFast_roaming_enabled(newVal, oldVal) {
      if (newVal === oldVal) {
        return;
      }

      this.handleResetFt_over_ds_enabled();
      this.handleResetParam_80211r_reassociation_timeout();
    }
  },
  methods: {
    handleCheckbox(inputName, event) {
      const isChecked = event.target.checked;
      this.$emit('update:inputValue', { inputName, value: isChecked });
    },
    handleInput(inputObj, value) {
      const { name, inputType } = inputObj;

      let correctFormatValue = value;

      // из-за особенностей архитектуры и невозможности использовать здесь v-modal, форматировать number приходится так
      if (inputType === 'number') {
        correctFormatValue = value === '' ? '' : Number(correctFormatValue);
      }

      this.$emit('update:inputValue', {
        inputName: name,
        value: correctFormatValue
      });
    },
    handleResetFast_roaming_enabled() {
      this.$emit('update:inputValue', {
        inputName: 'security.layer2.fast_roaming_enabled',
        value: false
      });

      // сброс состояния валидации с удаленных полей и ошибок в частности
      // https://vee-validate.logaretm.com/v2/examples/scopes.html
      // https://vee-validate.logaretm.com/v2/api/validator.html#methods
      const matcher1 = {
        scope: 'fast_roaming_enabled',
        vmId: this.$validator.id
      };

      this.$validator.reset(matcher1);
    },
    handleResetFt_over_ds_enabled() {
      this.$emit('update:inputValue', {
        inputName: 'security.layer2.ft_over_ds_enabled',
        value: false
      });

      // сброс состояния валидации с удаленных полей и ошибок в частности
      // https://vee-validate.logaretm.com/v2/examples/scopes.html
      // https://vee-validate.logaretm.com/v2/api/validator.html#methods
      const matcher1 = {
        scope: 'ft_over_ds_enabled',
        vmId: this.$validator.id
      };

      this.$validator.reset(matcher1);
    },
    handleResetParam_80211r_reassociation_timeout() {
      this.$emit('update:inputValue', {
        inputName: 'security.layer2.param_80211r_reassociation_timeout',
        value: 20
      });

      // сброс состояния валидации с удаленных полей и ошибок в частности
      // https://vee-validate.logaretm.com/v2/examples/scopes.html
      // https://vee-validate.logaretm.com/v2/api/validator.html#methods
      const matcher2 = {
        scope: 'param_80211r_reassociation_timeout',
        vmId: this.$validator.id
      };

      this.$validator.reset(matcher2);
    }
  },
  mounted() {
  }
};
</script>

<style lang="css" scoped>
.wlan-form-tab-input-group.params-80211r {
  grid-area: p80211r;
}

.caption-heading {
  margin: 8px 0;
}
</style>
