/* eslint-disable camelcase */
/* eslint-disable consistent-return */

import { AXIOS_INSTANCE, GET_WLAN_PROFILE_BY_WLAN_ID_URL } from '../config';
import {
  wlanProfileDataTypesConverterForFrontend
} from '../utils';

/**
 * @typedef {import('../types.js').WlanProfile_FRONTEND} WlanProfile_FRONTEND
 * @typedef {import('../types.js').WlanProfile_BACKEND} WlanProfile_BACKEND
 */

// doc https://wimarksystems.atlassian.net/wiki/spaces/Wimark/pages/464420893
// swagger https://gitlab.wimark.com/backend/grpc-contracts/-/blob/master/gen/go/gcontrollerconfig_service/service/v1/gcontrollerconfig.swagger.json?ref_type=heads

/**
 * @typedef {Object} ResponseData_BACKEND
 * @property {WlanProfile_BACKEND} wlan_profile - искомый объект wlan profile.
 */

/**
 * Функция-фетчер для получения wlan profile по его wlan_id.
 *
 * @param {number} wlanId - wlan_id искомого wlan profile.
 * @returns {Promise<ResponseData_BACKEND>} - Обещание с данными ответа.
 * @throws {Error} - Ошибка при выполнении запроса.
 */
const getWlanByWlanIdFetcher = async (wlanId) => {
  const url = `${GET_WLAN_PROFILE_BY_WLAN_ID_URL}${wlanId}`;

  try {
    const response = await AXIOS_INSTANCE.get(url);

    const { data } = response;

    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * @typedef {Object} ResponseData_FRONTEND
 * @property {WlanProfile_FRONTEND} wlan_profile - искомый объект wlan profile.
 */

/**
 * проверяет, уникален ли wlan_id в рамках wlan profiles.
 * выполняет побочные действия и обрабатывает тело запроса.
 *
 * @param {number} wlanId - wlan_id искомого wlan profile.
 * @param {Object} [options] - Дополнительные опции запроса.
 * @param {Function} [options.onLoading] - Callback при начале выполнения запроса.
 * @param {Function} [options.onSuccess] - Callback при успешном выполнении запроса.
 * @param {Function} [options.onError] - Callback при ошибке выполнения запроса.
 * @returns {Promise<ResponseData_FRONTEND>} - Обещание с данными ответа.
 * @throws {Error} - Ошибка при выполнении запроса.
 */

const checkIsUniqueWlanId = async (wlanId, options = {}) => {
  const {
    onLoading,
    onSuccess,
    onError
  } = options;

  try {
    if (onLoading) {
      onLoading();
    }

    const response = await getWlanByWlanIdFetcher(wlanId);
    const {
      wlan_profile
    } = response;

    const data = wlanProfileDataTypesConverterForFrontend(wlan_profile);

    onSuccess(data);
    return Promise.resolve(data);
  } catch (error) {
    if (onError) {
      onError(error);
    }
  }
};

export default checkIsUniqueWlanId;
