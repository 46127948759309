<template>
  <div class="wlan-form-tab-input-group security-type"
    ref="currentEl"
  >
    <h3 v-if="config.label" class="wlan-form-tab-input-group-heading">
      {{ config.label }}
    </h3>
    <div class="wlan-form-tab-input-group">
      <template v-for="input in config.inputs">
        <template v-if="input.inputType === 'radio'">
          <WRadio
            :key="input.id"
            :label="input.label"
            :name="input.name"
            :id="input.id"
            :value="input.radioValue"
            @input="handleRadio(input, $event)"
            :checked="formState[input.name] === input.radioValue"
          />
        </template>
        <template v-else>
          <WInput
            :key="input.id"
            :type="input.inputType"
            :label="input.label"
            :value="formState[input.name]"
            @input="handleInput(input, $event)"
            :id="input.name"
            :name="input.name"
            :placeholder="input.placeholder"
            :disabled="input.isDisabled"
            v-validate="input.validateRules"
            data-vv-validate-on="input"
            :data-vv-as="input.label"
            :errorText="errors.first(input.name)"
            autocomplete="one-time-code"
          />
        </template>
      </template>
    </div>

  </div>
</template>

<script>
/**
 *
 * компонент секции таба security - security_type.
 * Содержит разметку, правила рендеринга (в т.ч. рекурсивные), специфических настроек отображения
 * и параметров валидации отдельных секций(групп) инпутов в соответствии с конфигом
 * @component
 *
 */

/* eslint-disable camelcase */

export default {
  name: 'SecurityTypeSection',
  inject: ['$validator'],
  components: {
  },
  props: {
    config: {
      type: Object,
      required: true
    },
    formState: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
    };
  },
  computed: {
  },
  watch: {
  },
  methods: {
    handleRadio(inputObj, value) {
      const { name } = inputObj;

      this.$emit('update:inputValue', { inputName: name, value });
    },
    handleInput(inputObj, value) {
      const { name, inputType } = inputObj;

      let correctFormatValue = value;

      // из-за особенностей архитектуры и невозможности использовать здесь v-modal, форматировать number приходится так
      if (inputType === 'number') {
        correctFormatValue = value === '' ? '' : Number(correctFormatValue);
      }

      this.$emit('update:inputValue', {
        inputName: name,
        value: correctFormatValue
      });
    },
    // хак - ставим грид структуру страницы general - layer2 через обращение к родителю
    // из-за текущей структуры компонентов и дата флоу иначе не получается определить название таба далее первого уровня
    setGridStylesToParentContainer() {
      const thisEl = this.$refs.currentEl;

      const parentWlanFormTabContentEl = thisEl.closest('.wlan-form-tab-content');
      parentWlanFormTabContentEl.classList.add('layer2');
    }
  },
  mounted() {
    this.setGridStylesToParentContainer();
  },
  created() {
  }
};
</script>

<style lang="css" scoped>
  .wlan-form-tab-input-group.security-type {
    grid-area: security;
  }
</style>
